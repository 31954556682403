import { EmployeeResource } from "./employee-resource";

export class EmployeeManager {
    id: number;
    manager: EmployeeResource;
    employee: EmployeeResource;
    info: string;
    emailConfirm: boolean;
    complete: boolean;
    state: any; notSync: any; isLocked: any;

    constructor (id: number, manager: EmployeeResource, employee: EmployeeResource) {
        this.id = id;
        this.manager = manager;
        this.employee = employee;
    }
}
