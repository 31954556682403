import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage } from '../../generic/generic.manage';
import { AssetCategory } from '../../../model/api/assets/asset-category';

import { AssetCategoryHttpService } from '../../../services/http/assets/asset-category.http.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AssetTypeHttpService } from '../../../services/http/assets/asset-type.http.service';

@Component({
    selector: 'app-asset-category-manage',
    templateUrl: 'asset-category.manage.html',
    providers: [ AssetCategoryHttpService ]
})
export class AssetCategoryManageComponent extends GenericManage<AssetCategory, number> {

     @ViewChild('assetCategoryDetailModal') modal: ModalDirective;

     public filter: string = '';
     isCollapsed: boolean = true;

    constructor(public assetCategoryHttpService: AssetCategoryHttpService, public assetTypeHttpService: AssetTypeHttpService) {
        super();
    }

    public detailInitialize() {
        this.modal.show();
    }

    public detailTerminate() {
        this.modal.hide();
    }

 public exportToExcel(){

         let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.assetCategoryHttpService.get(1, 100000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<AssetCategory>) => {

                let options = {
                    sheetid: 'groups',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // alasql(`SELECT id as [Id], 
                //             code as [Cod], 
                //             name as [Description] 
                //             INTO XLSX("groups.xlsx", ?) FROM ?`, [ options, data.items ]);
            });

    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }
}
