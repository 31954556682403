
import { Component } from '@angular/core';
import { Area } from '../../../model/api/administration/area';
import { GenericAgGridList } from '../../generic/generic.ag-grid.list';

@Component({
    selector: 'app-area-list',
    templateUrl: '../../../forms/generic/generic.ag-grid.list.html'
})
export class AreaListComponent extends GenericAgGridList<Area, number> {

    constructor() {
      super('code', 'asc', '');

        this.columnDefs = [
            { field: 'code', headerName: 'Cod', sortable: true, filter: true, checkboxSelection: true, resizable: true },
            { field: 'name', headerName: 'Denumire', sortable: true, filter: true, checkboxSelection: false,resizable: true },
            { field: 'dateEvent', headerName: 'Data', sortable: true, filter: true, checkboxSelection: false,resizable: true },
        ];

        // this.gridOptions! = {
        //   headerHeight: 45,
        //   rowHeight: 30,
        //   pagination: true,
        //   //cacheBlockSize: 15,
        //   //paginationPageSize: this.pageSize,
        //   //paginationAutoPageSize: true,
        //   suppressPaginationPanel: true
        //   //rowModelType: 'clientSide',

        // }

        // console.log(this.gridOptions);
    }
}
