import { ColumnDefinition } from '../model/common/column-definition';
import { FieldByColumnDefinitionPipe } from '../forms/common/pipes/field-by-column-definition';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver-es';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private fieldByColumnDefinitionPipe: FieldByColumnDefinitionPipe) { }

  public export$(name: string, sourceColumns: ColumnDefinition[], sourceRows: any[]): Observable<void> {
    return this.exportMultiple$(name, [{sourceColumns, sourceRows}])
  }

  public exportMultiple$(name: string, sourceArray: { sourceColumns: ColumnDefinition[], sourceRows: any[], sheetName?: string }[]): Observable<void> {
    return new Observable<void>(() => {
      let tables = new Array<any>();
      for (let i = 0; i < sourceArray.length; i++) {
        const sourceTable = sourceArray[i];
        let tableData = this.generateTableData(sourceTable);
        let sheetName = sourceTable.sheetName ?? `Sheet${(i + 1)}`;
        tables.push({tableData, sheetName});
      }
      this.exportToExcel(name, tables);
    })
  }

  private exportToExcel(name: string, tables: any[]) {
    const workbook: XLSX.WorkBook = { Sheets: { }, SheetNames: [] };
    for (const {tableData, sheetName} of tables) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tableData, {skipHeader: true, cellDates: true, dateNF: 'yyyy-mm-dd hh:mm:ss'});
      workbook.Sheets[sheetName] = worksheet;
      workbook.SheetNames.push(sheetName);
    }
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelBlob, `${name}.xlsx`);
  }

  private generateTableData({sourceColumns, sourceRows}: {sourceColumns: ColumnDefinition[], sourceRows: any[]}): any[] {
    const targetRows: any[] = [];

    const headerRow: any = {};
    for (let i = 0; i < sourceColumns.length; i++) {
      const sourceColumn = sourceColumns[i];
      headerRow[i] = sourceColumn.headerCode;
    }
    targetRows.push(headerRow);

    for (const sourceRow of sourceRows) {
      const targetRow: any = {};

      for (let i = 0; i < sourceColumns.length; i++) {
        const sourceColumn = sourceColumns[i];
        targetRow[i] = this.generateCellValue(sourceColumn, sourceRow);
      }
      targetRows.push(targetRow);
    }

    return targetRows;
  }
/**
 * Get source filed value, transform it and then return it
 * @param sourceColumn 
 * @param sourceRow 
 * @returns 
 */
  private generateCellValue(sourceColumn: ColumnDefinition, sourceRow: any) {
    switch (sourceColumn.pipe) {
      case 'date': {
        let value = this.getSourceFieldValue(sourceRow, sourceColumn);
        return value?.length > 0 ? new Date(value) : null;
      }
      case 'number': {
        let value = this.fieldByColumnDefinitionPipe.transform(sourceRow, sourceColumn);
        return value !== null && value !== undefined ? parseFloat(value) : null;
      }
      default:
        return this.fieldByColumnDefinitionPipe.transform(sourceRow, sourceColumn);
    }
  }

  private getSourceFieldValue(sourceRow: any, sourceColumn: ColumnDefinition) {
    let result: any = sourceRow;
    const paths: Array<string> = sourceColumn.property.split('.');

    paths.forEach((path: string) => {
        result = result === null ? null : result[path];
    });

    return result;
  }
}
