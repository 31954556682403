import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppData } from '../../../app-data';
import { AppUtils } from '../../../common/app.utils';
import { DictionaryItem } from '../../../model/api/administration/dictionary-item';
import { AssetEmployeeEdit } from '../../../model/api/assets/asset-employee-edit';
import { AssetEmployeeSave } from '../../../model/api/assets/asset-employee-save';
import { Brand } from '../../../model/api/assets/brand';
import { Model } from '../../../model/api/assets/model';
import { CodeNameEntity } from '../../../model/api/common/code-name-entity';
import { ConfigValue } from '../../../model/api/common/config-value';
import { EmployeeValidateStatus } from '../../../model/api/common/employee-validate-status';
import { ColumnDefinition } from '../../../model/common/column-definition';
import { PagedResult } from '../../../model/common/paged-result';
import { Param } from '../../../model/common/param';
import { TableDefinition } from '../../../model/common/table-definition';
import { AuthenticationService } from '../../../services/authentication.service';
import { DictionaryItemHttpService } from '../../../services/http/administration/dictionary-item.http.service';
import { EmployeeHttpService } from '../../../services/http/administration/employee.http.service';
import { AssetHttpService } from '../../../services/http/assets/asset.http.service';
import { BrandHttpService } from '../../../services/http/assets/brand.http.service';
import { ModelHttpService } from '../../../services/http/assets/model.http.service';
import { ColumnDefinitionHttpService } from '../../../services/http/common/column-definition.http.service';
import { ConfigValuesHttpService } from '../../../services/http/common/config-values.service';
import { DashboardHttpService } from '../../../services/http/common/dashboard.http.service';
import { TableDefinitionHttpService } from '../../../services/http/common/table-definition.http.service';
import { NotificationService } from '../../../services/notification.service';
import { DictionaryItemDetailComponent } from '../../administrations/dictionary-item/dictionary-item.detail';
import { DictionaryItemListComponent } from '../../administrations/dictionary-item/dictionary-item.list';
import { BrandList } from '../brands/brand.list';
import { ModelList } from '../models/model.list';
import { AssetEmployeeValidateList } from './asset-employee-validate.list';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FileHttpService } from '../../../services/file.service';
import { saveAs as fileSaveAs } from 'file-saver-es';
import { AppConfig } from '../../../config';
import { Res } from '../../../resources/resources';

@Component({
    selector: 'asset-employee-detail-ui',
    templateUrl: 'asset-employee.detail.ui.html',
    styleUrls: ['asset-employee.detail.ui.scss'],
    providers: [ AssetHttpService ]
})
export class AssetEmployeeDetailUIComponent implements AfterViewInit  {

    @ViewChild('assetInvFullDetailList') public assetInvFullDetailList: AssetEmployeeValidateList;

    @ViewChild('dictionaryItemDetail') public dictionaryItemDetail: DictionaryItemDetailComponent;
    @ViewChild('dictionaryItemList') public dictionaryItemList: DictionaryItemListComponent;

    @ViewChild('dictionaryItemDetailModal') public dictionaryItemDetailModal: ModalDirective;
    @ViewChild('dictionaryItemListModal') public dictionaryItemListModal: ModalDirective;

    @ViewChild('brandList') public brandList: BrandList;
    @ViewChild('brandListModal') public brandListModal: ModalDirective;

    @ViewChild('modelList') public modelList: ModelList;
    @ViewChild('modelListModal') public modelListModal: ModalDirective;

    @ViewChild('confirmationModal') public confirmationModal: ModalDirective;

    public refreshBtnInfo = Res.RefreshBtnInfo;
    public assetEmployee: AssetEmployeeSave = new AssetEmployeeSave();
    public dictionaryItem: CodeNameEntity = null;
    public selectedAsset: AssetEmployeeEdit = null;

    public info: string = '';
    public info2019: string = '';
    public name: string = '';
    public serialNumber: string = '';
    public sapCode: string = '';
    public brandName: string = '';
    public modelName: string = '';
    public brand: Brand = null;
    public model: Model = null;
    public assetId = 0;
    // guid: string = '';
    isSaved = false;
    decodedToken: any;
    public token = '';
    roleName = '';
    pageBrandSize = 5;
    pageModelSize = 5;
    brandReadOnly = true;
    modelReadOnly = true;
    public initialSortColumn = '';
    public initialSortDirection = 'asc';
    public initialPageSize = 50;

    public itemsInventory = new Array<EmployeeValidateStatus>();

    public confirmationMessage: string = '';
    public operationType: OperationType = OperationType.NotSet;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public assetHttpService: AssetHttpService,
        public dictionaryItemHttpService: DictionaryItemHttpService,
        public brandHttpService: BrandHttpService,
        public modelHttpService: ModelHttpService,
        public notificationService: NotificationService,
        public employeeHttpService: EmployeeHttpService,
        public dashboardHttpService: DashboardHttpService,
        public tableDefinitionHttpService: TableDefinitionHttpService,
        public columnDefinitionHttpService: ColumnDefinitionHttpService,
        public configValuesHttpService: ConfigValuesHttpService,
        public authenticationService: AuthenticationService,
        private jwtService: JwtHelperService,
        private fileService: FileHttpService,
        public http: HttpClient) {

            this.itemsInventory.push(new EmployeeValidateStatus());

            this.token = localStorage.getItem('id_token');

            if (this.token === '' || this.token == null) {
                this.router.navigate(['/login']);
            }

            // this.route.params.subscribe((params: Params) => {
            //     if (params['id']) {
            //         this.guid = params['id'];
            //     }
            // });
    }

    ngAfterViewInit() {
        this.initialSortColumn = this.assetInvFullDetailList.sortColumn;
        this.initialSortDirection = this.assetInvFullDetailList.sortDirection;
        this.initialPageSize = this.assetInvFullDetailList.pageSize;
        this.decodedToken = this.jwtService.decodeToken(this.token);
        const index = this.decodedToken.role[1];
        if (index.length > 1) {
          this.decodedToken.role = this.decodedToken.role.slice(0 , -1);
        }

        setTimeout(() => {
            this.loadTableConfigurationData(this.decodedToken.role);
            // this.refreshAssets();
            this.loadData();

       }, 2000);
    }

    public get allowSaving(): boolean {
        if (((this.name !== undefined && this.name !== '' && this.name.trim().length > 2) &&
        (this.serialNumber !== undefined && this.serialNumber !== '' && this.serialNumber.trim().length > 2))) {
            return true;
        } else {
            return false;
        }
    }

    public onAssetInvFullDetailSelectionChanged(assets: Array<AssetEmployeeSave>) {
        // this.selectedAsset = ((assets != null) && (assets.length === 1)) ? assets[0] : null;
        this.brandReadOnly = true;
        this.modelReadOnly = true;
        this.isSaved = true;
        this.selectedAsset = this.assetInvFullDetailList.selectedItem;

        if (this.selectedAsset != null) {
            this.assetId = this.selectedAsset.id;
            this.info = this.selectedAsset.info;
            this.info2019 = this.selectedAsset.info2019;
            this.name = this.selectedAsset.name;
            this.serialNumber = this.selectedAsset.serialNumberFinal;
            this.sapCode = this.selectedAsset.sapCode;
            this.brandName = this.selectedAsset.brand != null ? this.selectedAsset.brand.name : '';
            this.modelName = this.selectedAsset.model != null ? this.selectedAsset.model.name : '';
            this.brand = this.selectedAsset.brand;
            this.model = this.selectedAsset.model;
            this.dictionaryItem = this.selectedAsset.dictionaryItem;
        } else {
            this.selectedAsset = new AssetEmployeeEdit();
            this.info = '';
            this.info2019 = '';
            this.name = '';
            this.serialNumber = '';
            this.sapCode = '';
            this.brandName = '';
            this.modelName = '';
            this.assetId = 0;
            this.brand = null;
            this.model = null;
            this.dictionaryItem = null;
        }
    }

    onAssetEmployeeValidateListAfterViewInit() {
    }

    public refreshAssets() {
        // const params: Array<Param> = new Array<Param>();

        // params.push(new Param('userId', this.guid.toString()));
        this.assetInvFullDetailList.refresh(null);
    }



     /*begin dictionary Item*/
     public selectDictionaryItem() {

        this.dictionaryItemList.selectedItems = new Array<DictionaryItem>();

        this.dictionaryItem = null;

        this.dictionaryItemList.refresh(null);
        this.dictionaryItemListModal.show();
    }

    public setSelectedDictionaryItem() {
        let items: Array<DictionaryItem> = this.dictionaryItemList.selectedItems;
        this.dictionaryItem = ((items != null) && (items.length === 1)) ? items[0] : null;
        this.name = ((items != null) && (items.length === 1)) ? items[0].name : '';

        // if (this.dictionaryItem != null) {
        //     if (this.brand != null && this.brand.dictionaryItem != null && this.brand.dictionaryItem.id !== this.dictionaryItem.id ) {
        //         this.brand = null;
        //         this.brandName = '';
        //         this.model = null;
        //         this.modelName = '';
        //     }
        // } else {
        //     this.brand = null;
        //     this.brandName = '';
        //     this.model = null;
        //     this.modelName = '';
        // }


        this.dictionaryItemListModal.hide();
    }
    /*end asset category*/


     /*begin BRAND */
     public selectBrand() {

        //  const params = new Array<Param>();
        //  params.push(new Param('pageSize', this.pageSize.toString()));
        // params.push(new Param('dictionaryItemIds', this.dictionaryItem != null ? this.dictionaryItem.id.toString() : null));
        this.brandList.selectedItems = new Array<Brand>();
        this.brand = null;
        this.brandList.refresh(null);
        this.brandListModal.show();
    }

    public setSelectedBrand() {
        const items: Array<Brand> = this.brandList.selectedItems;
        this.brand = ((items != null) && (items.length === 1)) ? items[0] : null;
        this.brandName = ((items != null) && (items.length === 1)) ? items[0].name : '';
        // if (this.brand != null) {
        //     if (this.model != null && this.model.brand != null && this.model.brand.id !== this.brand.id ) {
        //         this.model = null;
        //         this.modelName = '';
        //     }
        // } else {
        //     this.model = null;
        //     this.modelName = '';
        // }

        if (this.brand != null && this.brand.code === '00') {
            this.brandReadOnly = false;
            this.brand = null;
            this.brandName = '';
        } else {
            this.brandReadOnly = true;
        }

        this.brandListModal.hide();
    }

    /*end BRAND */

     /*begin MODEL */
     public selectModel() {
        this.modelList.selectedItems = new Array<Model>();
        this.model = null;


        const params = new Array<Param>();
        params.push(new Param('brandIds', this.brand != null ? this.brand.id.toString() : null));

        this.modelList.refresh(params);
        this.modelListModal.show();
    }

    public setSelectedModel() {
        let items: Array<Model> = this.modelList.selectedItems;
        this.model = ((items != null) && (items.length === 1)) ? items[0] : null;
        this.modelName = ((items != null) && (items.length === 1)) ? items[0].name : '';

        if (this.model != null && this.model.code === '00') {
            this.modelReadOnly = false;
            this.model = null;
            this.modelName= '';
        } else {
            this.modelReadOnly = true;
        }


        this.modelListModal.hide();
    }

    /*end MODEL */

    public saveAsset() {
        this.brandReadOnly = true;
        this.modelReadOnly = true;
        this.isSaved = true;
        this.assetEmployee.id = this.assetId;
        this.assetEmployee.info = this.info;
        this.assetEmployee.info2019 = this.info2019;
        this.assetEmployee.name = this.name;
        this.assetEmployee.serialNumber = this.serialNumber;
        this.assetEmployee.brandName = this.brandName;
        this.assetEmployee.modelName = this.modelName;
        this.assetEmployee.validated = true;
        this.assetEmployee.dictionaryItemId = this.dictionaryItem != null ? this.dictionaryItem.id : 0;
        this.assetEmployee.brandId = this.brand != null ? this.brand.id : 0;
        this.assetEmployee.modelId = this.model != null ? this.model.id : 0;
        this.assetEmployee.sapCode = this.sapCode;

        if (this.assetEmployee.id > 0) {
             this.assetHttpService.addNewAssetEmployee(this.assetEmployee)
             .subscribe((assetId: number) => {
                if (assetId > 0) {
                    this.notificationService.showSuccess('Datele au fost modificate cu succces!', 'Modificare echipament');
                    this.refreshAssets();
                    this.info = '';
                    this.info2019 = '';
                    this.name = '';
                    this.serialNumber = '';
                    this.sapCode = '';
                    this.brandName = '';
                    this.modelName = '';
                    this.assetId = 0;
                    this.selectedAsset = new AssetEmployeeEdit();
                    this.dictionaryItem = null;
                    this.brand = null;
                    this.model = null;
                    this.isSaved = false;
                    this.brandReadOnly = true;
                    this.modelReadOnly = true;
                    this.loadData();
                }
            }, error => {
                this.notificationService.showError('Eroare!', 'Modificare echipament');
            });
            // .subscribe(() => {
                // this.assetHttpService.getDetailById(this.assetEmployee.id)
                //     .subscribe((assetId: number) => {
                //         if (assetId > 0){
                //             this.toastr.success('Datele au fost modificate cu succes!');
                //             this.refreshAssets();
                //             this.isSaved = true;
                //         }
                //     });
            // });
        } else {
            this.assetHttpService.addNewAssetEmployee(this.assetEmployee)
            .subscribe((assetId: number) => {
                if (assetId > 0){
                    this.notificationService.showSuccess('Datele au fost salvate!', 'Adaugare echipament');
                    this.refreshAssets();
                    this.info = '';
                    this.info2019 = '';
                    this.name = '';
                    this.serialNumber = '';
                    this.sapCode = '';
                    this.brandName = '';
                    this.modelName = '';
                    this.assetId = 0;
                    this.dictionaryItem = null;
                    this.brand = null;
                    this.model = null;
                    this.loadData();
                    this.isSaved = false;
                    this.brandReadOnly = true;
                    this.modelReadOnly = true;
                    this.router.navigate(['/wfh/validate']);
                }
            }, error => {
                this.notificationService.showError('Eroare!', 'Adaugare echipament');
            });
        }
    }

    public deleteAssetOp() {
        this.assetHttpService.deleteAssetValidation(this.assetInvFullDetailList.selectedItem.id).subscribe( (assetId) => {
            if (assetId > 0){
                this.notificationService.showSuccess('Datele au fost salvate!', 'Stergere echipament');
                this.refreshAssets();
                this.info = '';
                this.info2019 = '';
                this.name = '';
                this.serialNumber = '';
                this.sapCode = '';
                this.brandName = '';
                this.modelName = '';
                this.assetId = 0;
                this.dictionaryItem = null;
                this.brand = null;
                this.model = null;
                this.loadData();
            }
        }, error => {
            this.notificationService.showError('Eroare!', 'Stergere echipament');
        });
    }

    public onDeleteAssetOp() {
        this.operationType = OperationType.Delete;
        this.confirmationMessage = 'Stergeti inregistrarea curenta?';
        this.confirmationModal.show();
    }

    public onConfirmationCanceled() {
        this.operationType = OperationType.NotSet;
        this.confirmationModal.hide();
    }

    public onConfirmationApproved() {

        switch (this.operationType) {
            case OperationType.Delete:
                this.deleteAssetOp();
                break;
            default:
                break;
        }

        this.operationType = OperationType.NotSet;
        this.confirmationModal.hide();
    }

    public resetAllFilters(){
       //set first page + refetch data
       this.assetInvFullDetailList.currentPage = 1;
       this.assetInvFullDetailList.pageSize = this.initialPageSize;
       //this.assetInvFullDetailList.resetRows(this.initialPageSize);
       
       //reset sorting
       this.assetInvFullDetailList.sortColumn = this.initialSortColumn;
       this.assetInvFullDetailList.sortDirection = this.initialSortDirection;
       //this.assetInvFullDetailList.resetSort();
       
       //clear selections
       this.clearSelection();
       //reset filters
       this.refreshAssets();
    }
    
    public clearSelection() {
        this.itemsInventory = new Array<EmployeeValidateStatus>();
        this.assetInvFullDetailList.selectedItems = this.itemsInventory;
    }


    public clearFilters() {
        this.loadData();
        this.loadTableConfigurationData(this.decodedToken.role);
        // this.assetInvFullDetailList.refresh(null);
        this.isSaved = false;
        this.brandReadOnly = true;
        this.modelReadOnly = true;
        this.brandName = '';
        this.modelName= '';
        this.serialNumber = '';
        this.sapCode = '';
        this.info = '';
        this.dictionaryItem = null;
        this.model = null;
        this.brand = null;
        this.name= '';
    }

    public loadData() {
        this.dashboardHttpService.employeesStatus().subscribe( (res: any[]) => {
          this.itemsInventory = res;
      });
    }

    public loadTableConfigurationData(userRole: string) {
        this.tableDefinitionHttpService.get(1, 1000000, 'id', 'asc', null, null, 'allowAnonymous')
          .subscribe((tableDefinitions: PagedResult<TableDefinition>) => {

            const params: Array<Param> = new Array<Param>();
            params.push(new Param('roleName', userRole));
            this.columnDefinitionHttpService.get(1, 1000000, 'tableDefinitionId', 'asc', params, null, 'allowAnonymous')
              .subscribe((columnDefinitions: PagedResult<ColumnDefinition>) => {
                AppData.UpdateColumnDefinitions(tableDefinitions.items, columnDefinitions.items);
                this.refreshAssets();
                // this.configValuesHttpService.get(1, 1000000, 'id', 'asc', params)
                //   .subscribe((configValues: PagedResult<ConfigValue>) => {
                //     AppData.UpdateConfigValues(configValues.items);

                //   });
              });
          });
      }

      public get isValid() {
        if ((

            (this.name !== undefined && this.name !== '' && this.name.trim().length > 2) &&
            ((this.serialNumber !== undefined && this.serialNumber !== '' && this.serialNumber.trim().length > 3) || this.serialNumber === 'N/A' || (this.dictionaryItem != null && this.dictionaryItem.name === 'Phone')) &&
            ((this.sapCode !== undefined && this.sapCode !== '' && this.sapCode.trim().length > 3) || this.sapCode === 'N/A') &&
            (this.brand != null || (!this.brandReadOnly && this.brandName.trim().length > 1)) && 
            (this.model != null || (!this.modelReadOnly && this.modelName.trim().length > 1))
            )) {
            return true;
        } else {
            return false;
        }
    }


    validateName(event: number) {
        // console.log(JSON.stringify(this.dictionaryItem));
        this.dictionaryItem = null;
    }

    validateBrand(event: number) {
        // console.log(JSON.stringify(this.dictionaryItem));
        this.brand = null;
    }

    validateModel(event: number) {
        // console.log(JSON.stringify(this.dictionaryItem));
        this.model = null;
    }

    public get isAdmin(): boolean {
        return this.decodedToken != null && this.decodedToken.role != null && this.decodedToken.role === 'administrator';
    }

    public getSN(): void {
        const url = `${AppConfig.urlPrefix}documents/manual/serie`;
            window.open(url);
          }

    public getIMEI(): void {
    const url = `${AppConfig.urlPrefix}documents/manual/imei`;
        window.open(url);
        }

        public onPageBrandUpdate(number: number) {
            this.pageBrandSize = number;

            const params = new Array<Param>();
            params.push(new Param('pageSize', this.pageBrandSize.toString()));
            this.brandList.refresh(params);
        }

        public onPageModelUpdate(number: number) {
            this.pageModelSize = number;

            const params = new Array<Param>();
            params.push(new Param('pageSize', this.pageModelSize.toString()));
            this.modelList.refresh(params);
        }
}

enum OperationType {
    NotSet = 1,
    AssetValidation = 2,
    Delete = 3,
    ProcessAssetOp = 4
}