import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppData } from '../../../app-data';
import { ExportService } from '../../../services/export.service';
import { AssetDepTotal } from '../../../model/api/assets/asset-dep-total';
import { Customer, Representative } from '../../../model/api/assets/customer';
import { InvState } from '../../../model/api/inventory/inv-state';
import { ColumnDefinition } from '../../../model/common/column-definition';
import { Param } from '../../../model/common/param';
import { EmployeeHttpService } from '../../../services/http/administration/employee.http.service';
import { InvStateHttpService } from '../../../services/http/inventory/inv-state.http.service';
import { GenericPrimeNgDataTableList } from '../../generic/generic.prime-ng-data-table.list';
import { GenericPrimeNgFilterDataTableList } from '../../generic/generic.prime-ng-filter-data-table.list';
import { GenericTableList } from '../../generic/generic.table.list';

@Component({
    selector: 'app-asset-list',
    // templateUrl: '../../assets/assets/asset.list.html',
    templateUrl: '../../generic/generic.prime-ng-filter-data-table.list.html',
    styleUrls: ['./asset.list.scss'],
    // host: {
    //     '(document:click)': 'handleClick($event)'
    // },
    inputs: [ 'listTemplate' ],
})
// export class AssetListComponent extends GenericTableList<any, number> implements OnInit, AfterViewInit {
export class AssetListComponent extends GenericPrimeNgFilterDataTableList<any, number> {
    public columns: any = [];

    public query = '';
    public filteredList = [];
    public elementRef;
    public listTemplate: string = 'ASSETS';
    public loadType: string = '';
    public totals: AssetDepTotal = null;
    public firstIndex = 0;

    constructor(public myElement: ElementRef,
      public employeeHttpService: EmployeeHttpService,
      public invStateHttpService: InvStateHttpService,
      public exportService: ExportService
      ) {
        super('id', 'asc', null, exportService);

        this.columns = AppData.ColumnDefinitions[this.listTemplate];
        this.elementRef = myElement;
    }

      public refresh(filters: Array<Param>) {
        this.columns = AppData.ColumnDefinitions[this.listTemplate];
        super.refresh(filters);
    }
    
    public setCurrentPageData(pageData: any) {
        this.totals = pageData.totals;
        super.setCurrentPageData(pageData);
    }

    public resetSort() {
        this.dt1.sortOrder = 0;
        this.dt1.sortField = undefined;
        this.dt1.reset();
        // super.applySort('byColor');
        // super.pageChanged({page: 1})
    }   

    public resetRows(rows) {
        this.dt1.rows = rows;
    }
    
    public filter() {
        if (this.query !== ''){
            this.filteredList = this.tableItems.filter(function(el){
                return el.item.invNo.toString().toLowerCase().indexOf(this.query.toLowerCase()) > -1;
            }.bind(this));
        } else{
            this.filteredList = [];
        }
    }

    public select(item){
        this.query = item;
        this.filteredList = [];
        if(item != null){
            this.tableItems = this.tableItems.filter(function(el){
                return el.item.invNo.toString().toLowerCase().indexOf(item.toLowerCase()) > -1;
            })
        }
    }

    public filterCust(ev) {
        console.log(JSON.stringify(ev));
      }

      // public async loadEmployee(){
      //   this.invStateHttpService.get(1, 5, 'id', 'asc',[],null,'').subscribe((res: any)=> {
      //     // this.invStates = res;
      //     //console.log(res.items);
      //     this.invStates= [];
      //     this.invStates = res.items;
      //     //console.log(this.invStates);
      //     console.log('LOAD EMPLOYEE');
      //   })

      // }

}



// import { Component } from '@angular/core';
// import { GenericAgGridList } from '../../generic/generic.ag-grid.list';


// @Component({
//     selector: 'app-asset-list',
//     templateUrl: '../../../forms/generic/generic.ag-grid.list.html'
// })
// export class AssetListComponent extends GenericAgGridList<any, number> {


//     constructor() {
//         super('name', 'asc', 'inventory');

//         this.columnDefs = [
//             { field: 'invNo', headerName: 'Nr. inv.', width: 100 },
//             { field: 'name', headerName: 'Denumire', width: 100 },
//             { field: 'serialNumber', headerName: 'SN', width: 100 },
//             { field: 'purchaseDate', headerName: 'Data ach.', width: 100 },
//             { field: 'sN', headerName: 'SN', width: 100 },
//             { field: 'sN', headerName: 'SN', width: 100 },
//             { field: 'locationCodeIni', headerName: 'Column 1', width: 100 },
//             { field: 'locationCodeIni', headerName: 'Column 1', width: 100 },
//             { field: 'locationNameIni', headerName: 'Column 1', width: 100 },
//             { field: 'roomNameIni', headerName: 'Column 1', width: 100 },
//             { field: 'internalCodeIni', headerName: 'Column 1', width: 100 },
//             { field: 'firstNameIni', headerName: 'Column 1', width: 100 },
//             { field: 'valueInv item.firstNameIni', headerName: 'Column 1', width: 100 },
//             { field: 'sN', headerName: 'Column 1', width: 100 },
//             { field: 'qIntial', headerName: 'Column 1', width: 100 },
//             { field: 'uom', headerName: 'Column 1', width: 100 },
//             { field: 'sN', headerName: 'Column 1', width: 100 },
//             { field: 'locationCodeFin', headerName: 'Column 1', width: 100 },
//             { field: 'locationCodeFin', headerName: 'Column 1', width: 100 },
//             { field: 'locationNameFin', headerName: 'Column 1', width: 100 },
//             { field: 'roomNameFin', headerName: 'Column 1', width: 100 },
//             { field: 'internalCodeFin', headerName: 'Column 1', width: 100 },
//             { field: 'firstNameFin', headerName: 'Column 1', width: 100 }
//         ];
//     }
// }

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
  }

  const ELEMENT_DATA: PeriodicElement[] = [
    {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
    {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
    {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
    {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
    {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
    {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
    {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
    {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
    {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
    {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
    {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
    {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
    {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
    {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
    {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
    {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
    {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
    {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
    {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
    {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
  ];
