import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderType } from '../../../model/api/order/order-type';
import { GenericHttpService } from '../generic.http.service';
// import { AuthHttp } from 'angular2-jwt';

@Injectable()
export class OrderTypeHttpService extends GenericHttpService<OrderType, number> {
    constructor(public http: HttpClient) {
        super(http, "", "ordertypes");
    }
}