import { Component, OnInit, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AdmCenterListComponent } from '../../../forms/administrations/adm-centers/adm-center.list';
import { CountryListComponent } from '../../../forms/administrations/countries/country.list';
import { ExpAccountList } from '../../../forms/administrations/exp-account/exp-account.list';
import { RegionListComponent } from '../../../forms/administrations/regions/region.list';
import { AssetTypeListComponent } from '../../../forms/assets/asset-types/asset-type.list';
import { CompanyListComponent } from '../../../forms/assets/companies/company.list';
import { ProjectList } from '../../../forms/assets/projects/project.list';
import { AdmCenter } from '../../../model/api/administration/adm-center';
import { Country } from '../../../model/api/administration/country';
import { ExpAccount } from '../../../model/api/administration/exp-account';
import { Region } from '../../../model/api/administration/region';
import { AssetType } from '../../../model/api/assets/asset-type';
import { Company } from '../../../model/api/assets/company';
import { Project } from '../../../model/api/assets/project';
import { LocationStatus } from '../../../model/api/common/location-status';
import { AdmCenterHttpService } from '../../../services/http/administration/adm-center.http.service';
import { CountryHttpService } from '../../../services/http/administration/contry.http.service';
import { ExpAccountHttpService } from '../../../services/http/administration/exp-account.http.service';
import { RegionHttpService } from '../../../services/http/administration/region.http.service';
import { AssetTypeHttpService } from '../../../services/http/assets/asset-type.http.service';
import { CompanyHttpService } from '../../../services/http/assets/company.http.service';
import { ProjectHttpService } from '../../../services/http/assets/project.http.service';
import { DashboardHttpService } from '../../../services/http/common/dashboard.http.service';


@Component({
  templateUrl: 'dashboard-asset.component.html',
  styleUrls: ['./dashboard-asset.component.scss']
})
export class DashboardAssetComponent implements OnInit {

   @ViewChild('expAccountList') public expAccountList: ExpAccountList;
   @ViewChild('expAccountListModal') public expAccountListModal: ModalDirective;
   @ViewChild('countryList') public countryList: CountryListComponent;
   @ViewChild('countryListModal') public countryListModal: ModalDirective;
   @ViewChild('admCenterList') public admCenterList: AdmCenterListComponent;
   @ViewChild('admCenterListModal') public admCenterListModal: ModalDirective;
   @ViewChild('regionList') public regionList: RegionListComponent;
   @ViewChild('regionListModal') public regionListModal: ModalDirective;
   @ViewChild('assetTypeList') public assetTypeList: AssetTypeListComponent;
   @ViewChild('assetTypeListModal') public assetTypeListModal: ModalDirective;
   @ViewChild('companyList') public companyList: CompanyListComponent;
   @ViewChild('companyListModal') public companyListModal: ModalDirective;

   public selectedExpAccounts: Array<ExpAccount> = new Array<ExpAccount>();
   public expAccountId = 37;
   public selectedCountries: Array<Country> = new Array<Country>();
   public countryId = 0;
   public selectedAdmCenters: Array<AdmCenter> = new Array<AdmCenter>();
   public admCenterId = 0;
   public selectedRegions: Array<Region> = new Array<Region>();
   public regionId = 0;
   public selectedAssetTypes: Array<AssetType> = new Array<AssetType>();
   public assetTypeId = 0;
   public selectedCompanies: Array<Company> = new Array<Company>();
   public companyId = 0;

   public capitalizedOnDate: Date;
    public ordDepStartDate: Date;

  subsription: Subscription;
  _chartOption: EChartsOption;
  isDarkMode: boolean = false;
  _theme: string;
  seriesLabel = {
    show: true
  };

  constructor(
      private dashbBoardService: DashboardHttpService,
      public countryHttpService: CountryHttpService,
      public admCenterHttpService: AdmCenterHttpService,
      public regionHttpService: RegionHttpService,
      public assetTypeHttpService: AssetTypeHttpService,
      public companyHttpService: CompanyHttpService,
      public expAccountHttpService: ExpAccountHttpService) {
  }


  ngOnInit(): void {
    this.subsription = this.dashbBoardService.expAccountGroup(0, this.expAccountId).subscribe( data => {
      // console.log(JSON.stringify(data));
      this._initBasicLineChart(data);
    });
  }


  private _initBasicLineChart (chartData: any) {
    this._theme = this.isDarkMode ? 'dark' : '';
    // let total = chartData.values.reduce((partialSum, a) => partialSum + a, 0);
    // console.log(JSON.stringify(total));
      let maxValue = chartData.values.map(row => row['currentAPC']).reduce((max, val) => max > val ? max : val, chartData.values.map(row => row['currentAPC'])[0]);
      // let maxValue: any = 100000000;
      maxValue = (maxValue * 1.2);
      maxValue = parseFloat(maxValue).toFixed(2);
      // let maxValueDep = chartData.valueDeps.map(row => row['acquisition']).reduce((max, val) => max > val ? max : val, chartData.valueDeps.map(row => row['acquisition'])[0]);
      // let maxValueDep: any = 100000000;
      // maxValueDep = (maxValueDep * 1.1);
      // maxValueDep = parseFloat(maxValueDep).toFixed(2);

  const builderJson = {
    'maxValue': maxValue,
    // 'maxValueDep': maxValueDep,
    'charts': {
        'ApcfyStart': chartData.values.map(row => row['apcfyStart'])[0],
        'DepFYStart': chartData.values.map(row => row['depFYStart'])[0],
        'BkValFYStart': chartData.values.map(row => row['bkValFYStart'])[0],
        'Acquisition': chartData.values.map(row => row['acquisition'])[0],
        'DepForYear': chartData.values.map(row => row['depForYear'])[0],
        'Retirement': chartData.values.map(row => row['retirement'])[0],
        'DepRetirement': chartData.values.map(row => row['depRetirement'])[0],
        'CurrBkValue': chartData.values.map(row => row['currBkValue'])[0],
        'CurrentAPC': chartData.values.map(row => row['currentAPC'])[0],
        'AccumulDep': chartData.values.map(row => row['accumulDep'])[0]
    },
    // 'components': {
    //     // 'Acquisition': chartData.valueDeps.map(row => row['acquisition'])[0],
    //     // '05/01/2021': chartData.valueDeps.map(row => row['value'])[1],
    //     // '06/01/2021': chartData.valueDeps.map(row => row['value'])[2],
    //     // '07/01/2021': chartData.valueDeps.map(row => row['value'])[3],
    //     // '08/01/2021': chartData.valueDeps.map(row => row['value'])[4],
    //     // '09/01/2021': chartData.valueDeps.map(row => row['value'])[5],
    //     // '10/01/2021': chartData.valueDeps.map(row => row['value'])[6],
    //     // '11/01/2021': chartData.valueDeps.map(row => row['value'])[7],
    //     // '12/01/2021': chartData.valueDeps.map(row => row['value'])[8],
    //     // '01/01/2021': chartData.valueDeps.map(row => row['value'])[9],
    //     // '02/01/2021': chartData.valueDeps.map(row => row['value'])[10],
    //     // '03/01/2021': chartData.valueDeps.map(row => row['value'])[11],
    // },
    'ie': 0
  };

  // const downloadJson: any = {
  //   '04/01/2021': chartData.values.map(row => row['value'])[0],
  //   '05/01/2021': chartData.values.map(row => row['value'])[1],
  //   '06/01/2021': chartData.values.map(row => row['value'])[2],
  //   '07/01/2021': chartData.values.map(row => row['value'])[3],
  //   '08/01/2021': chartData.values.map(row => row['value'])[4],
  //   '09/01/2021': chartData.values.map(row => row['value'])[5],
  //   '10/01/2021': chartData.values.map(row => row['value'])[6],
  //   '11/01/2021': chartData.values.map(row => row['value'])[7],
  //   '12/01/2021': chartData.values.map(row => row['value'])[8],
  //   '01/01/2021': chartData.values.map(row => row['value'])[9],
  //   '02/01/2021': chartData.values.map(row => row['value'])[10],
  //   '03/01/2021': chartData.values.map(row => row['value'])[11],
  // };

  // const themeJson = {
  //   '04/01/2021': chartData.valueDeps.map(row => row['value'])[0],
  //   '05/01/2021': chartData.valueDeps.map(row => row['value'])[1],
  //   '06/01/2021': chartData.valueDeps.map(row => row['value'])[2],
  //   '07/01/2021': chartData.valueDeps.map(row => row['value'])[3],
  //   '08/01/2021': chartData.valueDeps.map(row => row['value'])[4],
  //   '09/01/2021': chartData.valueDeps.map(row => row['value'])[5],
  //   '10/01/2021': chartData.valueDeps.map(row => row['value'])[6],
  //   '11/01/2021': chartData.valueDeps.map(row => row['value'])[7],
  //   '12/01/2021': chartData.valueDeps.map(row => row['value'])[8],
  //   '01/01/2021': chartData.valueDeps.map(row => row['value'])[9],
  //   '02/01/2021': chartData.valueDeps.map(row => row['value'])[10],
  //   '03/01/2021': chartData.valueDeps.map(row => row['value'])[11],
  // };

  const waterMarkText = 'AMORTIZARE';

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = canvas.height = 100;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.globalAlpha = 0.08;
  ctx.font = '20px Microsoft Yahei';
  ctx.translate(50, 50);
  ctx.rotate(-Math.PI / 4);
  ctx.fillText(waterMarkText, 0, 0);


this._chartOption = {
    backgroundColor: {
        type: 'pattern',
        image: canvas,
        repeat: 'repeat'
    },
    tooltip: {},
    title: [{
        text: chartData.values.map(row => row['expAccount'])[0],
        // subtext: 'Total ' + builderJson.all,
        top: 20,
        left: '40%',
        textAlign: 'center'
    },
    // {
    //     text: 'Buget',
    //     // subtext: 'Total ' + Object.keys(downloadJson).reduce(function (all, key) {
    //     //     return all + downloadJson[key];
    //     // }, 0),
    //     left: '75%',
    //     textAlign: 'center'
    // }, {
    //     text: 'Amortizare',
    //     // subtext: 'Total ' + Object.keys(themeJson).reduce(function (all, key) {
    //     //     return all + themeJson[key];
    //     // }, 0),
    //     left: '75%',
    //     top: '50%',
    //     textAlign: 'center'
    // }
  ],
    grid: [{
        top: 50,
        width: '80%',
        bottom: '35%',
        left: 10,
        containLabel: true
    },
    // {
    //     top: '55%',
    //     width: '50%',
    //     bottom: 0,
    //     left: 10,
    //     containLabel: true
    // }
  ],
    xAxis: [{
        type: 'value',
        max: builderJson.maxValue,
        splitLine: {
            show: false
        }
    },
    // {
    //     type: 'value',
    //     max: builderJson.maxValueDep,
    //     gridIndex: 1,
    //     splitLine: {
    //         show: false
    //     }
    // }
  ],
    yAxis: [{
        type: 'category',
        data: Object.keys(builderJson.charts),
        axisLabel: {
            interval: 0,
            rotate: 0
        },
        splitLine: {
            show: false
        }
    },
    // {
    //     gridIndex: 1,
    //     type: 'category',
    //     data: Object.keys(builderJson.components),
    //     axisLabel: {
    //         interval: 0,
    //         rotate: 0
    //     },
    //     splitLine: {
    //         show: false
    //     }
    // }
  ],
    series: [{
        type: 'bar',
        stack: 'chart',
        z: 3,
        label: {
            fontWeight: 'bold',
            position: 'right',
            show: true
        },
        data: Object.keys(builderJson.charts).map(function (key) {
            return builderJson.charts[key];
        })
    }, {
        type: 'bar',
        stack: 'chart',
        silent: true,
        itemStyle: {
            color: '#eee'
        },
        data: Object.keys(builderJson.charts).map(function (key) {
            return builderJson.maxValue - builderJson.charts[key];
        })
    },
    // {
    //     type: 'bar',
    //     stack: 'component',
    //     xAxisIndex: 1,
    //     yAxisIndex: 1,
    //     z: 3,
    //     label: {
    //         position: 'right',
    //         show: true
    //     },
    //     data: Object.keys(builderJson.components).map(function (key) {
    //         return builderJson.components[key];
    //     })
    // }, {
    //     type: 'bar',
    //     stack: 'component',
    //     silent: true,
    //     xAxisIndex: 1,
    //     yAxisIndex: 1,
    //     itemStyle: {
    //         color: '#eee'
    //     },
    //     data: Object.keys(builderJson.components).map(function (key) {
    //         return builderJson.maxValueDep - builderJson.components[key];
    //     })
    // },
    // {
    //     type: 'pie',
    //     radius: [0, '30%'],
    //     center: ['75%', '25%'],
    //     data: Object.keys(downloadJson).map(function (key) {
    //         return {
    //             name: key.replace('.js', ''),
    //             value: downloadJson[key]
    //         };
    //     })
    // }, {
    //     type: 'pie',
    //     radius: [0, '30%'],
    //     center: ['75%', '75%'],
    //     data: Object.keys(themeJson).map(function (key) {
    //         return {
    //             name: key.replace('.js', ''),
    //             value: themeJson[key]
    //         };
    //     })
    // }
  ]
};
// this._chartOption = {
//   responsive: true,
//   maintainAspectRatio: false,
//   tooltip: {
//     trigger: 'axis',
//     axisPointer: {
//       // Use axis to trigger tooltip
//       type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
//     }
//   },
//   legend: {},
//   grid: {
//     left: '3%',
//     right: '4%',
//     bottom: '3%',
//     containLabel: true
//   },
//   xAxis: {
//     type: 'value'
//   },
//   yAxis: {
//     type: 'category',
//     data: chartData.values.map(row => row['expAccount'])
//   },
//   series: [
//     {
//       name: 'ApcfyStart',
//       type: 'bar',
//       stack: 'total',
//       label: {
//         show: true
//       },
//       emphasis: {
//         focus: 'series'
//       },
//       data: chartData.values.map(row => row['apcfyStart'])
//     },
//     {
//       name: 'DepFYStart',
//       type: 'bar',
//       stack: 'total',
//       label: {
//         show: true
//       },
//       emphasis: {
//         focus: 'series'
//       },
//       data: chartData.values.map(row => row['depFYStart'])
//     },
//     {
//       name: 'BkValFYStart',
//       type: 'bar',
//       stack: 'total',
//       label: {
//         show: true
//       },
//       emphasis: {
//         focus: 'series'
//       },
//       data: chartData.values.map(row => row['bkValFYStart'])
//     },
//     {
//       name: 'Acquisition',
//       type: 'bar',
//       stack: 'total',
//       label: {
//         show: true
//       },
//       emphasis: {
//         focus: 'series'
//       },
//       data: chartData.values.map(row => row['acquisition'])
//     },
//     {
//       name: 'DepForYear',
//       type: 'bar',
//       stack: 'total',
//       label: {
//         show: true
//       },
//       emphasis: {
//         focus: 'series'
//       },
//       data: chartData.values.map(row => row['depForYear'])
//     },
//     {
//       name: 'Retirement',
//       type: 'bar',
//       stack: 'total',
//       label: {
//         show: true
//       },
//       emphasis: {
//         focus: 'series'
//       },
//       data: chartData.values.map(row => row['retirement'])
//     },
//     {
//       name: 'DepRetirement',
//       type: 'bar',
//       stack: 'total',
//       label: {
//         show: true
//       },
//       emphasis: {
//         focus: 'series'
//       },
//       data: chartData.values.map(row => row['depRetirement'])
//     },
//     {
//       name: 'CurrBkValue',
//       type: 'bar',
//       stack: 'total',
//       label: {
//         show: true
//       },
//       emphasis: {
//         focus: 'series'
//       },
//       data: chartData.values.map(row => row['currBkValue'])
//     },
//     {
//       name: 'CurrentAPC',
//       type: 'bar',
//       stack: 'total',
//       label: {
//         show: true
//       },
//       emphasis: {
//         focus: 'series'
//       },
//       data: chartData.values.map(row => row['currentAPC'])
//     },
//     {
//       name: 'AccumulDep',
//       type: 'bar',
//       stack: 'total',
//       label: {
//         show: true
//       },
//       emphasis: {
//         focus: 'series'
//       },
//       data: chartData.values.map(row => row['accumulDep'])
//     },
//   ]
// };

// this._chartOption = {
//   title: {
//     text: 'Weather Statistics'
//   },
//   tooltip: {
//     trigger: 'axis',
//     axisPointer: {
//       type: 'shadow'
//     }
//   },
//   legend: {
//     data: chartData.values.map(row => row['expAccount'])
//   },
//   grid: {
//     left: 100
//   },
//   toolbox: {
//     show: true,
//     feature: {
//       saveAsImage: {}
//     }
//   },
//   xAxis: {
//     type: 'value',
//     name: 'Days',
//     axisLabel: {
//       formatter: '{value}'
//     }
//   },
//   yAxis: {
//     type: 'category',
//     inverse: true,
//     data: chartData.values.map(row => row['expAccount']),
//     axisLabel: {
//       formatter: function (value) {
//         return '{' + value + '| }\n{value|' + value + '}';
//       },
//       margin: 20,
//       rich: {
//         value: {
//           lineHeight: 30,
//           align: 'center'
//         },
//         Sunny: {
//           height: 40,
//           align: 'center',
//           // backgroundColor: {
//           //   // image: weatherIcons.Sunny
//           // }
//         },
//         Cloudy: {
//           height: 40,
//           align: 'center',
//           // backgroundColor: {
//           //   // image: weatherIcons.Cloudy
//           // }
//         },
//         Showers: {
//           height: 40,
//           align: 'center',
//           // backgroundColor: {
//           //   // image: weatherIcons.Showers
//           // }
//         }
//       }
//     }
//   },
//   series: [
//     {
//       name: 'ApcfyStart',
//       type: 'bar',
//       data: chartData.values.map(row => row['apcfyStart']),
//       label: this.seriesLabel,
//       markPoint: {
//         symbolSize: 1,
//         symbolOffset: [0, '50%'],
//         label: {
//           formatter: '{a|{a}\n}{b|{b} }{c|{c}}',
//           backgroundColor: 'rgb(242,242,242)',
//           borderColor: '#aaa',
//           borderWidth: 1,
//           borderRadius: 4,
//           padding: [4, 10],
//           lineHeight: 26,
//           // shadowBlur: 5,
//           // shadowColor: '#000',
//           // shadowOffsetX: 0,
//           // shadowOffsetY: 1,
//           position: 'right',
//           distance: 20,
//           rich: {
//             a: {
//               align: 'center',
//               color: '#fff',
//               fontSize: 18,
//               textShadowBlur: 2,
//               textShadowColor: '#000',
//               textShadowOffsetX: 0,
//               textShadowOffsetY: 1,
//               textBorderColor: '#333',
//               textBorderWidth: 2
//             },
//             b: {
//               color: '#333'
//             },
//             c: {
//               color: '#ff8811',
//               textBorderColor: '#000',
//               textBorderWidth: 1,
//               fontSize: 22
//             }
//           }
//         },
//         data: [
//           { type: 'max', name: 'max days: ' },
//           { type: 'min', name: 'min days: ' }
//         ]
//       }
//     },
//     {
//       name: 'DepFYStart',
//       type: 'bar',
//       label: this.seriesLabel,
//       data: chartData.values.map(row => row['depFYStart'])
//     },
//     {
//       name: 'BkValFYStart',
//       type: 'bar',
//       label: this.seriesLabel,
//       data: chartData.values.map(row => row['bkValFYStart'])
//     },
//     {
//       name: 'Acquisition',
//       type: 'bar',
//       label: this.seriesLabel,
//       data: chartData.values.map(row => row['acquisition'])
//     },
//     {
//       name: 'DepForYear',
//       type: 'bar',
//       label: this.seriesLabel,
//       data: chartData.values.map(row => row['depForYear'])
//     },
//     {
//       name: 'Retirement',
//       type: 'bar',
//       label: this.seriesLabel,
//       data: chartData.values.map(row => row['retirement'])
//     },
//     {
//       name: 'DepRetirement',
//       type: 'bar',
//       label: this.seriesLabel,
//       data: chartData.values.map(row => row['depRetirement'])
//     },
//     {
//       name: 'CurrBkValue',
//       type: 'bar',
//       label: this.seriesLabel,
//       data: chartData.values.map(row => row['currBkValue'])
//     },
//     {
//       name: 'CurrentAPC',
//       type: 'bar',
//       label: this.seriesLabel,
//       data: chartData.values.map(row => row['currentAPC'])
//     },
//     {
//       name: 'AccumulDep',
//       type: 'bar',
//       label: this.seriesLabel,
//       data: chartData.values.map(row => row['accumulDep'])
//     }
//   ]
// };
}

  public selectExpAccounts() {

    this.expAccountListModal.show();
    this.expAccountList.selectedItems = this.selectedExpAccounts;
    this.expAccountList.refresh(null);
}

public setSelectedExpAccounts() {
  this.selectedExpAccounts = this.expAccountList.selectedItems;
  this.expAccountListModal.hide();
  this.expAccountId = this.expAccountList != null && this.expAccountList.selectedItems[0] != null ? this.expAccountList.selectedItems[0].id : 37;
  this.subsription = this.dashbBoardService.expAccountGroup(0, this.expAccountId).subscribe( data => {
    // console.log(JSON.stringify(data));
    this._initBasicLineChart(data);
  });
  }

  public clearExpAccountFilters() {
    this.expAccountId = 37;
    this.selectedExpAccounts = new Array<ExpAccount>();
    this.subsription = this.dashbBoardService.expAccountGroup(0, this.expAccountId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

  public selectCountries() {

    this.countryListModal.show();
    this.countryList.selectedItems = this.selectedCountries;
    this.countryList.refresh(null);
}

public setSelectedCountries() {
  this.selectedCountries = this.countryList.selectedItems;
  this.countryListModal.hide();
  this.countryId = this.countryList != null && this.countryList.selectedItems[0] != null ? this.countryList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.countryGroup(0, this.countryId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearCountryFilters() {
    this.countryId = 0;
    this.selectedCountries = new Array<Country>();
    this.subsription = this.dashbBoardService.countryGroup(0, this.countryId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

public selectAdmCenters() {

    this.admCenterListModal.show();
    this.admCenterList.selectedItems = this.selectedAdmCenters;
    this.admCenterList.refresh(null);
}

public setSelectedAdmCenters() {
  this.selectedAdmCenters = this.admCenterList.selectedItems;
  this.admCenterListModal.hide();
  this.admCenterId = this.admCenterList != null && this.admCenterList.selectedItems[0] != null ? this.admCenterList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.admCenterGroup(0, this.admCenterId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearAdmCenterFilters() {
    this.admCenterId = 0;
    this.selectedAdmCenters = new Array<AdmCenter>();
    this.subsription = this.dashbBoardService.admCenterGroup(0, this.admCenterId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

public selectRegions() {

    this.regionListModal.show();
    this.regionList.selectedItems = this.selectedRegions;
    this.regionList.refresh(null);
}

public setSelectedRegions() {
  this.selectedRegions = this.regionList.selectedItems;
  this.regionListModal.hide();
  this.regionId = this.regionList != null && this.regionList.selectedItems[0] != null ? this.regionList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.regionGroup(0, this.regionId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearRegionFilters() {
    this.regionId = 0;
    this.selectedRegions = new Array<Region>();
    this.subsription = this.dashbBoardService.regionGroup(0, this.regionId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

public selectAssetTypes() {

    this.assetTypeListModal.show();
    this.assetTypeList.selectedItems = this.selectedAssetTypes;
    this.assetTypeList.refresh(null);
}

public setSelectedAssetTypes() {
  this.selectedAssetTypes = this.assetTypeList.selectedItems;
  this.assetTypeListModal.hide();
  this.assetTypeId = this.assetTypeList != null && this.assetTypeList.selectedItems[0] != null ? this.assetTypeList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.assetTypeGroup(0, this.assetTypeId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearAssetTypeFilters() {
    this.assetTypeId = 0;
    this.selectedAssetTypes = new Array<AssetType>();
    this.subsription = this.dashbBoardService.assetTypeGroup(0, this.assetTypeId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

public selectCompanies() {

    this.companyListModal.show();
    this.companyList.selectedItems = this.selectedCompanies;
    this.companyList.refresh(null);
}

public setSelectedCompanies() {
  this.selectedCompanies = this.companyList.selectedItems;
  this.companyListModal.hide();
  this.companyId = this.companyList != null && this.companyList.selectedItems[0] != null ? this.companyList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.companyGroup(0, this.companyId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearCompanyFilters() {
    this.companyId = 0;
    this.selectedCompanies = new Array<Company>();
    this.subsription = this.dashbBoardService.companyGroup(0, this.companyId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

public parseCapDate(dateString: any): Date {
  if (dateString) {
      this.capitalizedOnDate = new Date(dateString);
      return new Date(dateString);
  } else {
      return null;
  }
}
// public parseEndDate(dateString: string): Date {
//   if (dateString) {
//       this.transferEndDate = new Date(dateString);
//       return new Date(dateString);
//   } else {
//       return null;
//   }
// }

}
