import { CodeNameEntity } from "../common/code-name-entity";

export class Model {
    id: number;
    code: string;
    name: string;
    state: any; notSync: any; isLocked: any;
    brand: CodeNameEntity;

    constructor (id: number, code: string, name: string, brand: CodeNameEntity) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.brand = brand;
    }
}