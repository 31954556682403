import { Component, OnInit, ViewChild } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { ChartType } from 'chart.js';
import { DashboardHttpService } from '../../services/http/common/dashboard.http.service';
import { InventoryTotal } from '../../model/api/common/inventory-total';
import { CostCenterTotal } from '../../model/api/common/cost-center-total';
import { AssetTypeTotal } from '../../model/api/common/asset-type-total';
import { TypeTotal } from '../../model/api/common/type-total';
import { DivisionTotal } from '../../model/api/common/division-total';
import { DepartmentTotal } from '../../model/api/common/department-total';
import { DepartmentListComponent } from '../../forms/administrations/departments/department.list';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Department } from '../../model/api/administration/department';
import { DepartmentHttpService } from '../../services/http/administration/department.http.service';
import { Project } from '../../model/api/assets/project';
import { CostCenter } from '../../model/api/administration/cost-center';
import { Location } from '../../model/api/administration/location';
import { Division } from '../../model/api/administration/division';
import { DivisionListComponent } from '../../forms/administrations/divisions/division.list';
import { ProjectList } from '../../forms/assets/projects/project.list';
import { LocationListComponent } from '../../forms/administrations/locations/location.list';
import { Param } from '../../model/common/param';
import { AppUtils } from '../../common/app.utils';
import { CostCenterListComponent } from '../../forms/administrations/cost-centers/cost-center.list';
import { LocationHttpService } from '../../services/http/administration/location.http.service';
import { CostCenterHttpService } from '../../services/http/administration/cost-center.http.service';
import { ProjectHttpService } from '../../services/http/assets/project.http.service';
import { DivisionHttpService } from '../../services/http/administration/division.http.service';
import { DashboardReport } from '../../model/api/common/dashboard-report';
import { saveAs as fileSaveAs } from 'file-saver-es';
import { DashboardEntityListComponent } from './dashboard-entity.list';
import { DashboardFilter } from '../../model/api/common/dashboard.filter';
import { Type } from '../../model/api/administration/type';
import { TypeHttpService } from '../../services/http/administration/type.http.service';
import { TypeList } from '../../forms/administrations/types/type.list';
import { AssetTypeHttpService } from '../../services/http/assets/asset-type.http.service';
import { AssetTypeListComponent } from '../../forms/assets/asset-types/asset-type.list';
import { AssetType } from '../../model/api/assets/asset-type';
import { ProjectTotal } from '../../model/api/common/project-total';
import { BudgetManagerHttpService } from '../../services/http/administration/budget-manager.http.service';
import { BudgetManagerList } from '../../forms/administrations/budget-manager/budget-manager.list';
import { BudgetManager } from '../../model/api/assets/budget-manager';
import { BudgetManagerTotal } from '../../model/api/common/budget-manager-total';
import { AssetStatusDashboard, AssetStatusItems } from '../../model/api/common/asset-status-dashboard';
import { AssetListComponent } from '../../forms/assets/assets/asset.list';
import { SubTypeTotal } from '../../model/api/common/sub-type-total';
import { SubTypeHttpService } from '../../services/http/administration/sub-type.http.service';
import { Observable, Subject } from 'rxjs';
import { SubType } from '../../model/api/administration/sub-type';

@Component({
  templateUrl: 'dashboard-inventory-mark.component.html',
  styleUrls: ['./dashboard-inventory-mark.component.scss']
})
export class DashboardInventoryMarkComponent implements OnInit {

  radioModel: string = 'Month';

  // lineChart1
  public lineChart1Data: Array<any> = [
    {
      data: [65, 59, 84, 84, 51, 55, 40],
      label: 'Series A'
    }
  ];
  public lineChart1Labels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChart1Options: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'transparent',
          zeroLineColor: 'transparent'
        },
        ticks: {
          fontSize: 2,
          fontColor: 'transparent',
        }

      }],
      yAxes: [{
        display: false,
        ticks: {
          display: false,
          min: 40 - 5,
          max: 84 + 5,
        }
      }],
    },
    elements: {
      line: {
        borderWidth: 1
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false
    }
  };
  public lineChart1Colours: Array<any> = [
    {
      backgroundColor: getStyle('--primary'),
      borderColor: 'rgba(255,255,255,.55)'
    }
  ];
  public lineChart1Legend = false;
  public lineChart1Type: ChartType  = 'line';

  // lineChart2
  public lineChart2Data: Array<any> = [
    {
      data: [1, 18, 9, 17, 34, 22, 11],
      label: 'Series A'
    }
  ];
  public lineChart2Labels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChart2Options: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'transparent',
          zeroLineColor: 'transparent'
        },
        ticks: {
          fontSize: 2,
          fontColor: 'transparent',
        }

      }],
      yAxes: [{
        display: false,
        ticks: {
          display: false,
          min: 1 - 5,
          max: 34 + 5,
        }
      }],
    },
    elements: {
      line: {
        tension: 0.00001,
        borderWidth: 1
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false
    }
  };
  public lineChart2Colours: Array<any> = [
    { // grey
      backgroundColor: getStyle('--info'),
      borderColor: 'rgba(255,255,255,.55)'
    }
  ];
  public lineChart2Legend = false;
  public lineChart2Type: ChartType  = 'line';


  // lineChart3
  public lineChart3Data: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40],
      label: 'Series A'
    }
  ];
  public lineChart3Labels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChart3Options: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false
      }],
      yAxes: [{
        display: false
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false
    }
  };
  public lineChart3Colours: Array<any> = [
    {
      backgroundColor: 'rgba(255,255,255,.2)',
      borderColor: 'rgba(255,255,255,.55)',
    }
  ];
  public lineChart3Legend = false;
  public lineChart3Type: ChartType  = 'line';


  // barChart1
  public barChart1Data: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40, 78, 81, 80, 45, 34, 12, 40, 12, 40],
      label: 'Series A',
      barPercentage: 0.6,
    }
  ];
  public barChart1Labels: Array<any> = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
  public barChart1Options: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false,
      }],
      yAxes: [{
        display: false
      }]
    },
    legend: {
      display: false
    }
  };
  public barChart1Colours: Array<any> = [
    {
      backgroundColor: 'rgba(255,255,255,.3)',
      borderWidth: 0
    }
  ];
  public barChart1Legend = false;
  public barChart1Type: ChartType  = 'bar';

  // mainChart

  public mainChartElements = 27;
  public mainChartData1: Array<number> = [];
  public mainChartData2: Array<number> = [];
  public mainChartData3: Array<number> = [];

  public mainChartData: Array<any> = [
    {
      data: this.mainChartData1,
      label: 'Current'
    },
    {
      data: this.mainChartData2,
      label: 'Previous'
    },
    {
      data: this.mainChartData3,
      label: 'BEP'
    }
  ];
  /* tslint:disable:max-line-length */
  public mainChartLabels: Array<any> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Thursday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  /* tslint:enable:max-line-length */
  public mainChartOptions: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips,
      intersect: true,
      mode: 'index',
      position: 'nearest',
      callbacks: {
        labelColor: function(tooltipItem, chart) {
          return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor };
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function(value: any) {
            return value.charAt(0);
          }
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          stepSize: Math.ceil(250 / 5),
          max: 250
        }
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: false
    }
  };
  public mainChartColours: Array<any> = [
    { // brandInfo
      backgroundColor: hexToRgba(getStyle('--info'), 10),
      borderColor: getStyle('--info'),
      pointHoverBackgroundColor: '#fff'
    },
    { // brandSuccess
      backgroundColor: 'transparent',
      borderColor: getStyle('--success'),
      pointHoverBackgroundColor: '#fff'
    },
    { // brandDanger
      backgroundColor: 'transparent',
      borderColor: getStyle('--danger'),
      pointHoverBackgroundColor: '#fff',
      borderWidth: 1,
      borderDash: [8, 5]
    }
  ];
  public mainChartLegend = false;
  public mainChartType: ChartType  = 'line';

  // social box charts

  public brandBoxChartData1: Array<any> = [
    {
      data: [65, 59, 84, 84, 51, 55, 40],
      label: 'Facebook'
    }
  ];
  public brandBoxChartData2: Array<any> = [
    {
      data: [1, 13, 9, 17, 34, 41, 38],
      label: 'Twitter'
    }
  ];
  public brandBoxChartData3: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40],
      label: 'LinkedIn'
    }
  ];
  public brandBoxChartData4: Array<any> = [
    {
      data: [35, 23, 56, 22, 97, 23, 64],
      label: 'Google+'
    }
  ];

  public brandBoxChartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public brandBoxChartOptions: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false,
      }],
      yAxes: [{
        display: false,
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: false
    }
  };
  public brandBoxChartColours: Array<any> = [
    {
      backgroundColor: 'rgba(255,255,255,.1)',
      borderColor: 'rgba(255,255,255,.55)',
      pointHoverBackgroundColor: '#fff'
    }
  ];
  public brandBoxChartLegend = false;
  public brandBoxChartType: ChartType = 'line';

  public random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  items = [
    {id: 1, name: 'Python'},
    {id: 2, name: 'Node Js'},
    {id: 3, name: 'Java'},
    {id: 4, name: 'PHP', disabled: true},
    {id: 5, name: 'Django'},
    {id: 6, name: 'Angular'},
    {id: 7, name: 'Vue'},
    {id: 8, name: 'ReactJs'},
  ];

  // selectedBudgetManagerLists = [];
  // //selectedDepartments = [];
  // selectedDivisionLists = [];
  // selectedCostCenterLists = [];
  // selectedTypeLists = [];
  // selectedSubTypeLists = [];
  // selectedAssetTypeLists = [];
  // selectedProjectLists = [];

  constructor(
    public dashboardHttpService: DashboardHttpService,
    public locationHttpService: LocationHttpService,
    public divisionHttpService: DivisionHttpService,
    public costCenterHttpService: CostCenterHttpService,
    public typeHttpService: TypeHttpService,
    public subTypeHttpService: SubTypeHttpService,
    public assetTypeHttpService: AssetTypeHttpService,
    public projectHttpService: ProjectHttpService,
    public budgetManagerHttpService: BudgetManagerHttpService,
    public departmentHttpService: DepartmentHttpService) {
        this.itemsInventory.push(new InventoryTotal());
  }


  @ViewChild('budgetManagerList') public budgetManagerList: BudgetManagerList;
  @ViewChild('budgetManagerListModal') public budgetManagerListModal: ModalDirective;
  @ViewChild('departmentList') public departmentList: DepartmentListComponent;
  @ViewChild('departmentListModal') public departmentListModal: ModalDirective;
  @ViewChild('divisionList') public divisionList: DivisionListComponent;
  @ViewChild('divisionListModal') public divisionListModal: ModalDirective;
  @ViewChild('projectList') public projectList: ProjectList;
  @ViewChild('projectListModal') public projectListModal: ModalDirective;
  @ViewChild('locationList') public locationList: LocationListComponent;
  @ViewChild('locationListModal') public locationListModal: ModalDirective;
  @ViewChild('costCenterList') public costCenterList: CostCenterListComponent;
  @ViewChild('costCenterListModal') public costCenterListModal: ModalDirective;
  @ViewChild('typeList') public typeList: TypeList;
  @ViewChild('typeListModal') public typeListModal: ModalDirective;
  @ViewChild('assetTypeList') public assetTypeList: AssetTypeListComponent;
  @ViewChild('assetTypeListModal') public assetTypeListModal: ModalDirective;

  @ViewChild('budgetManagerReportModal') public budgetManagerReportModal: ModalDirective;
  @ViewChild('departmentReportModal') public departmentReportModal: ModalDirective;
  @ViewChild('divisionReportModal') public divisionReportModal: ModalDirective;
  @ViewChild('costCenterReportModal') public costCenterReportModal: ModalDirective;
  @ViewChild('typeReportModal') public typeReportModal: ModalDirective;
  @ViewChild('assetTypeReportModal') public assetTypeReportModal: ModalDirective;
  @ViewChild('projectReportModal') public projectReportModal: ModalDirective;

  @ViewChild('dashboardBudgetManagerEntityList') public dashboardBudgetManagerEntityList: DashboardEntityListComponent;
  @ViewChild('dashboardDepartmentEntityList') public dashboardDepartmentEntityList: DashboardEntityListComponent;
  @ViewChild('dashboardDivisionEntityList') public dashboardDivisionEntityList: DashboardEntityListComponent;
  @ViewChild('dashboardCostCenterEntityList') public dashboardCostCenterEntityList: DashboardEntityListComponent;
  @ViewChild('dashboardTypeEntityList') public dashboardTypeEntityList: DashboardEntityListComponent;
  @ViewChild('dashboardAssetTypeEntityList') public dashboardAssetTypeEntityList: DashboardEntityListComponent;
  @ViewChild('dashboardProjectEntityList') public dashboardProjectEntityList: DashboardEntityListComponent;

  @ViewChild('assetList') public assetList: AssetListComponent;


  public selectedBudgetManagers: Array<BudgetManager> = new Array<BudgetManager>();
  public selectedDepartments: Array<Department> = new Array<Department>();
  public selectedProjects: Array<Project> = new Array<Project>();
  public selectedCostCenters: Array<CostCenter> = new Array<CostCenter>();
  public selectedLocations: Array<Location> = new Array<Location>();
  public selectedDivisions: Array<Division> = new Array<Division>();
  public selectedSubTypes: Array<SubType> = new Array<SubType>();
  public selectedTypes: Array<Type> = new Array<Type>();
  public selectedAssetTypes: Array<AssetType> = new Array<AssetType>();

  inventoryId = 13;
  budgetManagerId = 0;
  departmentId = 0;
  divisionId = 0;
  costCenterId = 0;
  typeId = 0;
  assetTypeId = 0;
  projectId = 0;
  type = 0;
  showExportBtn = true;
  showValues = false;
  showBudgetManagerExportBtn = true;
  showDepartmentExportBtn = true;
  showDivisionExportBtn = true;
  showCostCenterExportBtn = true;
  showTypeExportBtn = true;
  showAssetTypeExportBtn = true;
  showProjectExportBtn = true;

  assetRowSelection: string = 'single';

  public filterSearch: string;

  public itemsInventory = new Array<InventoryTotal>();
  public costCenters = new Array<CostCenterTotal>();
  public assetTypes = new Array<AssetTypeTotal>();
  public projects = new Array<ProjectTotal>();
  public types = new Array<TypeTotal>();
  public subTypes = new Array<SubTypeTotal>();
  public divisions = new Array<DivisionTotal>();
  public departments = new Array<DepartmentTotal>();
  public budgetManagers = new Array<BudgetManagerTotal>();

  public itemsAssetsArray = new AssetStatusDashboard;


  public itemsTotalBudgetManagerArray= new Array<DashboardReport>();
  public itemsTotalDepartmentArray= new Array<DashboardReport>();
  public itemsTotalDivisionArray= new Array<DashboardReport>();
  public itemsTotalCostCenterArray= new Array<DashboardReport>();
  public itemsTotalTypeArray= new Array<DashboardReport>();
  public itemsTotalAssetTypeArray= new Array<DashboardReport>();
  public itemsTotalProjectArray= new Array<DashboardReport>();

  isBudgetManagerLoading: boolean = false;
  isDepartmentLoading: boolean = false;
  isDivisionLoading: boolean = false;
  isCostCenterLoading: boolean = false;
  isTypeLoading: boolean = false;
  isAssetTypeLoading: boolean = false;
  isProjectLoading: boolean = false;

  isBudgetManagerCollapsed: boolean = true;
  isDepartmentCollapsed: boolean = true;
  isDivisionCollapsed: boolean = true;
  isCostCenterCollapsed: boolean = true;
  isTypeCollapsed: boolean = true;
  isAssetTypeCollapsed: boolean = true;
  isProjectCollapsed: boolean = true;



  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }

  //   selectAllBudgetManagers() {
  //     this.selectedBudgetManagerLists = this.budgetManagers.map(x => x.name);
  // }

  // unselectAllBudgetManagers() {
  //     this.selectedBudgetManagerLists = [];
  // }

  // getSelectionAsParams() {
  //   let params = new Array<Param>();
  //   let dashboardFilter: DashboardFilter = new DashboardFilter();
  //   params.push(new Param('filter', ''));

  //   if (this.selectedBudgetManagerLists != null) {
  //     dashboardFilter.budgetManagerIds = new Array<number>();
  //     this.selectedBudgetManagerLists.forEach((selected) => {
  //       dashboardFilter.budgetManagerIds.push(selected.id);
  //     });
  //   }

  //   if (this.selectedDepartmentLists != null) {
  //     dashboardFilter.departmentIds = new Array<number>();
  //     this.selectedDepartmentLists.forEach((selected) => {
  //       dashboardFilter.departmentIds.push(selected.id);
  //     });
  //   }

  //   if (this.selectedDivisionLists != null) {
  //     dashboardFilter.divisionIds = new Array<number>();
  //     this.selectedDivisionLists.forEach((selected) => {
  //       dashboardFilter.divisionIds.push(selected.id);
  //     });
  //   }

  //   if (this.selectedCostCenterLists != null) {
  //     dashboardFilter.costCenterIds = new Array<number>();
  //     this.selectedCostCenterLists.forEach((costCenter) => {
  //       dashboardFilter.costCenterIds.push(costCenter.id);
  //     });
  //   }

  //   if (this.selectedSubTypeLists != null) {
  //   dashboardFilter.subTypeIds = new Array<number>();
  //   this.selectedSubTypeLists.forEach((subType) => {
  //     dashboardFilter.subTypeIds.push(subType.id);
  //   });
  //   }

  //   if (this.selectedTypeLists != null) {
  //     dashboardFilter.typeIds = new Array<number>();
  //     this.selectedTypeLists.forEach((type) => {
  //       dashboardFilter.typeIds.push(type.id);
  //     });
  //     }

  //   if (this.selectedAssetTypeLists != null) {
  //   dashboardFilter.assetTypeIds = new Array<number>();
  //   this.selectedAssetTypeLists.forEach((assetType) => {
  //     dashboardFilter.assetTypeIds.push(assetType.id);
  //   });
  //   }

  //   if (this.selectedProjectLists != null) {
  //     dashboardFilter.projectIds = new Array<number>();
  //     this.selectedProjectLists.forEach((project) => {
  //       dashboardFilter.projectIds.push(project.id);
  //     });
  //   }

  //   params.push(new Param('jsonFilter', JSON.stringify(dashboardFilter)));

  //   return params;
  // }

  changeLists() {
    let params = this.getFilters();
    this.assetList.refresh(params);
  }

  updateCostCenterSelection() {
    this.refreshAssetList();
  }

  refreshAssetList() {
    let params = this.getFilters();
    this.assetList.refresh(params);
  }

  refreshBudgetManagerList() {

    this.isBudgetManagerLoading = true;
    // let params = this.getFilters();

    this.budgetManagerHttpService.get(1, 1000, "code", "asc", null).subscribe( (res: any) => {
      this.budgetManagers = res.items;
    }, () => {}, () => { this.isBudgetManagerLoading = false; });
  }

  refreshCostCenterList(): Observable<boolean> {
    let resultSubject = new Subject<boolean>();

    this.isCostCenterLoading = true;
    let params = this.getFilters();

    this.costCenterHttpService.getData(params).subscribe( (res: any[]) => {
      this.costCenters = res;
      resultSubject.next(true);
    }, () => { resultSubject.next(false); }, () => { this.isCostCenterLoading = false; });

    return resultSubject.asObservable();
  }

  refreshDepartmentList(): Observable<boolean> {
    let resultSubject = new Subject<boolean>();

    this.isDepartmentLoading = true;
    let params = this.getFilters();

    this.departmentHttpService.getData(params).subscribe( (res: any[]) => {
      this.departments = res;
      resultSubject.next(true);
    }, () => { resultSubject.next(false); }, () => { this.isDepartmentLoading = false; });

    return resultSubject.asObservable();
  }

  refreshDivisionList() {
    let resultSubject = new Subject<boolean>();

    this.isDivisionLoading = true;
    let params = this.getFilters();

    this.divisionHttpService.getData(params).subscribe( (res: any[]) => {
      this.divisions = res;
      resultSubject.next(true);
    }, () => { resultSubject.next(false); }, () => { this.isDivisionLoading = false; });

    return resultSubject.asObservable();
  }

  updateCostCenterListSelection(): Observable<boolean> {
    const resultSubject = new Subject<boolean>();

    if (this.selectedCostCenters.length > 0) {
      this.refreshCostCenterList().subscribe(() => {

        let index = 0;
        while (index < this.selectedCostCenters.length) {
          const d = this.costCenters.find(a => a.id === this.selectedCostCenters[index].id);

          if (d === undefined) {
            this.selectedCostCenters.splice(index, 1);
          } else {
            index++;
          }
        }

        this.selectedCostCenters = [ ...this.selectedCostCenters ];
        resultSubject.next(true);
      });
    } else {
      setTimeout(() => {
        resultSubject.next(true);
      }, 10);
    }

    return resultSubject.asObservable();
  }

  updateDepartmentListSelection(): Observable<boolean> {
    let resultSubject = new Subject<boolean>();

    if (this.selectedDepartments.length > 0) {
      this.refreshDepartmentList().subscribe(() => {

        let index: number = 0;
        while(index < this.selectedDepartments.length) {
          let d = this.departments.find(a => a.id === this.selectedDepartments[index].id);

          if (d === undefined) {
            this.selectedDepartments.splice(index, 1);
          }
          else {
            index++;
          }
        }

        this.selectedDepartments = [ ...this.selectedDepartments ];
        resultSubject.next(true);
      });
    }
    else {
      setTimeout(() => {
        resultSubject.next(true);
      }, 10);
    }

    return resultSubject.asObservable();
  }

  updateDivisionListSelection(): Observable<boolean> {
    let resultSubject = new Subject<boolean>();

    if (this.selectedDivisions.length > 0) {
      this.refreshDivisionList().subscribe(() => {

        let index: number = 0;
        while(index < this.selectedDivisions.length) {
          let d = this.divisions.find(a => a.id === this.selectedDivisions[index].id);

          if (d === undefined) {
            this.selectedDivisions.splice(index, 1);
          }
          else {
            index++;
          }
        }

        this.selectedDivisions = [ ...this.selectedDivisions ];
        resultSubject.next(true);
      });
    }
    else {
      setTimeout(() => {
        resultSubject.next(true);
      }, 10);
    }

    return resultSubject.asObservable();
  }

  onBudgetManagerSelectionAdd() {
    this.refreshAssetList();
    this.clearDepartmentItems();
    this.clearDivisionItems();
    this.clearCostCenterItems();
  }

  onDepartmentSelectionAdd() {
    this.refreshAssetList();
    this.clearDivisionItems();
    this.clearCostCenterItems();
  }

  onDivisionSelectionAdd() {
    this.refreshAssetList();
    this.clearCostCenterItems();
  }

  onCostCenterSelectionAdd() {
    this.refreshAssetList();
  }

  onBudgetManagerSelectionRemove() {
    //this.updateDepartmentListSelection();

    this.updateCostCenterListSelection().subscribe(() => {
      this.updateDivisionListSelection().subscribe(() => {
        this.updateDepartmentListSelection().subscribe(() => {
          this.refreshAssetList();
        })
      });
    });
  }

  onBudgetManagerSelectionChange() {
    this.refreshAssetList();
    this.refreshDepartmentList();
    this.refreshDivisionList();
    this.refreshCostCenterList();
  }

  onBudgetManagerListFocus() {
    if(this.budgetManagers.length == 0) this.refreshBudgetManagerList();
  }

  onDepartmentListFocus() {
    if(this.departments.length == 0) this.refreshDepartmentList();
  }


  onDivisionListFocus() {
    if(this.divisions.length == 0) this.refreshDivisionList();
  }

  onDivisionSelectionChange() {
    this.refreshAssetList();
    this.refreshCostCenterList();
  }

  onCostCenterListFocus() {
    if(this.costCenters.length == 0) this.refreshCostCenterList();
  }

  clearCostCenterItems() {
    this.costCenters.splice(0, this.costCenters.length);
  }

  clearDepartmentItems() {
    this.departments.splice(0, this.departments.length);
  }

  clearDivisionItems() {
    this.divisions.splice(0, this.divisions.length);
  }


  ngOnInit(): void {
      // generate random values for mainChart
      // for (let i = 0; i <= this.mainChartElements; i++) {
      //   this.mainChartData1.push(this.random(50, 200));
      //   this.mainChartData2.push(this.random(80, 100));
      //   this.mainChartData3.push(65);
      // }

      // setTimeout(() => {
      //   this.loadData(this.departmentId, this.divisionId, this.costCenterId, this.typeId, this.assetTypeId, this.projectId);
      // }, 1000);

  }

  getFilters() {
    let params = new Array<Param>();
        let dashboardFilter: DashboardFilter = new DashboardFilter();


        if (this.selectedBudgetManagers != null) {
          dashboardFilter.budgetManagerIds = new Array<number>();
          this.selectedBudgetManagers.forEach((selected) => {
            dashboardFilter.budgetManagerIds.push(selected.id);
          });
        }

        if (this.selectedDepartments != null) {
          dashboardFilter.departmentIds = new Array<number>();
          this.selectedDepartments.forEach((selected) => {
            dashboardFilter.departmentIds.push(selected.id);
          });
        }

        if (this.selectedDivisions != null) {
          dashboardFilter.divisionIds = new Array<number>();
          this.selectedDivisions.forEach((selected) => {
            dashboardFilter.divisionIds.push(selected.id);
          });
        }

        if (this.selectedCostCenters != null) {
          dashboardFilter.costCenterIds = new Array<number>();
          this.selectedCostCenters.forEach((costCenter) => {
            dashboardFilter.costCenterIds.push(costCenter.id);
          });
        }

        if (this.selectedSubTypes != null) {
        dashboardFilter.subTypeIds = new Array<number>();
        this.selectedSubTypes.forEach((subType) => {
          dashboardFilter.subTypeIds.push(subType.id);
        });
        }

        if (this.selectedTypes != null) {
          dashboardFilter.typeIds = new Array<number>();
          this.selectedTypes.forEach((type) => {
            dashboardFilter.typeIds.push(type.id);
          });
          }

        if (this.selectedAssetTypes != null) {
        dashboardFilter.assetTypeIds = new Array<number>();
        this.selectedAssetTypes.forEach((assetType) => {
          dashboardFilter.assetTypeIds.push(assetType.id);
        });
        }

        if (this.selectedProjects != null) {
          dashboardFilter.projectIds = new Array<number>();
          this.selectedProjects.forEach((project) => {
            dashboardFilter.projectIds.push(project.id);
          });
        }

        params.push(new Param('jsonFilter', JSON.stringify(dashboardFilter)));

        return params;
  }

  // public loadData(departmentId: number, divisionId: number, costCenterId: number, typeId: number, assetTypeId: number, projectId: number) {

  //       let params = this.getFilters();


  //       this.dashboardHttpService.inventoryStatus(this.inventoryId, departmentId, divisionId, costCenterId, typeId, assetTypeId, projectId).subscribe( (res: any[]) => {
  //         this.itemsInventoryArray = res;
  //     });

  //       this.dashboardHttpService.costCenterStatus(this.inventoryId, departmentId, divisionId, costCenterId, typeId, assetTypeId, projectId).subscribe( (res: any[]) => {
  //         this.itemsCostCenterArray = res;
  //     });

  //     this.dashboardHttpService.assetTypeStatus(this.inventoryId, departmentId, divisionId, costCenterId, typeId, assetTypeId, projectId).subscribe( (res: any[]) => {
  //       this.itemsAssetTypeArray = res;
  //     });

  //     this.dashboardHttpService.typeStatus(this.inventoryId, departmentId, divisionId, costCenterId, typeId, assetTypeId, projectId).subscribe( (res: any[]) => {
  //       this.itemsTypeArray = res;
  //     });

  //     this.dashboardHttpService.divisionStatus(this.inventoryId, departmentId, divisionId, costCenterId, typeId, assetTypeId, projectId).subscribe( (res: any[]) => {
  //       this.itemsDivisionArray = res;
  //     });

  //     this.dashboardHttpService.departmentStatus(this.inventoryId, departmentId, divisionId, costCenterId, typeId, assetTypeId, projectId).subscribe( (res: any[]) => {
  //       this.itemsDepartmentArray = res;
  //     });

  //     this.dashboardHttpService.projectStatus(this.inventoryId, departmentId, divisionId, costCenterId, typeId, assetTypeId, projectId).subscribe( (res: any[]) => {
  //       this.itemsProjectArray = res;
  //     });

  //     this.dashboardHttpService.budgetManagerStatus(this.inventoryId, departmentId, divisionId, costCenterId, typeId, assetTypeId, projectId).subscribe( (res: any[]) => {
  //       this.itemsBudgetManager = res;
  //     });

  //     this.subTypeHttpService.getData(params).subscribe( (res: any[]) => {
  //       this.itemsSubTypeArray = res;
  //     });
  // }

  public onAssetSelectionChanged(assets: Array<any>) {
    // this.selectedAssetInvDetails = assets;
    // this.selectedAssets = new Array<any>();
    // assets.forEach((asset: any) => {
    //     this.selectedAssets.push(asset);
    // });
}

   /*begin department*/
   public selectBudgetManagers() {
    this.budgetManagerListModal.show();
    this.budgetManagerList.selectedItems = this.selectedBudgetManagers;
    this.budgetManagerList.refresh(null);
    }

    // public removeFromBudgetManagerSelection(budgetManager: BudgetManager) {
    //     let index: number = this.selectedBudgetManagers.indexOf(budgetManager);
    //     this.selectedBudgetManagers.splice(index, 1);
    //     this.loadData(
    //       this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    //       this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    //       this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    //       this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    //       this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    //       this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
    // }

    // public clearBudgetManagerSelection() {
    //     this.selectedBudgetManagers = new Array<BudgetManager>();
    //     this.loadData(
    //       this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    //       this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    //       this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    //       this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    //       this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    //       this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
    // }

    // public setSelectedBudgetManagers() {
    //     this.selectedBudgetManagers = this.budgetManagerList.selectedItems;
    //     this.budgetManagerListModal.hide();
    //     this.loadData(
    //       this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    //       this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    //       this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    //       this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    //       this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    //       this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
    // }
    /*end department*/


     /*begin department*/
   public selectDepartments() {
    this.departmentListModal.show();
    this.departmentList.selectedItems = this.selectedDepartments;
    this.departmentList.refresh(null);
    }

    // public removeFromDepartmentSelection(department: Department) {
    //     let index: number = this.selectedDepartments.indexOf(department);
    //     this.selectedDepartments.splice(index, 1);
    //     this.loadData(
    //       this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    //       this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    //       this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    //       this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    //       this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    //       this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
    // }

    // public clearDepartmentSelection() {
    //     this.selectedDepartments = new Array<Department>();
    //     this.loadData(
    //       this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    //       this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    //       this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    //       this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    //       this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    //       this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
    // }

    // public setSelectedDepartments() {
    //     this.selectedDepartments = this.departmentList.selectedItems;
    //     this.departmentListModal.hide();
    //     this.loadData(
    //       this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    //       this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    //       this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    //       this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    //       this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    //       this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
    // }
    /*end department*/

     /*begin Division */
     public selectDivisions() {

      let selectedDepartments: Array<Department> = null;
      let selectedLocations: Array<Location> = null;
      selectedDepartments = this.selectedDepartments;
      selectedLocations = this.selectedLocations;

      let params = new Array<Param>();
      params.push(new Param('departmentIds', AppUtils.getIdsList<Department, number>(selectedDepartments)));
      params.push(new Param('locationIds', AppUtils.getIdsList<Location, number>(selectedLocations)));


      this.divisionListModal.show();
      this.divisionList.selectedItems = this.selectedDivisions;
      this.divisionList.refresh(params);
  }

  // public removeFromDivisionSelection(division: Division) {
  //     let index: number = this.selectedDivisions.indexOf(division);
  //     this.selectedDivisions.splice(index, 1);
  //     this.loadData(
  //       this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
  //         this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
  //         this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
  //         this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
  //         this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
  //         this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
  // }

  // public clearDivisionSelection() {
  //     this.selectedDivisions = new Array<Division>();
  //     this.loadData(
  //       this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
  //         this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
  //         this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
  //         this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
  //         this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
  //         this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
  // }

  // public setSelectedDivisions() {
  //     this.selectedDivisions = this.divisionList.selectedItems;
  //     this.divisionListModal.hide();
  //     this.loadData(
  //       this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
  //         this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
  //         this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
  //         this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
  //         this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
  //         this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
  // }

  /* end Division */

  /* begin location */

  public selectLocations() {

    this.locationListModal.show();
    this.locationList.selectedItems = this.selectedLocations;
    this.locationList.refresh(null);
}

public removeFromLocationSelection(location: Location) {
    let index: number = this.selectedLocations.indexOf(location);
    this.selectedLocations.splice(index, 1);
    //this.checkForRefresh();
}

public clearLocationSelection() {
    this.selectedLocations = new Array<Location>();
    //this.checkForRefresh();
}

public setSelectedLocations() {
    this.selectedLocations = this.locationList.selectedItems;
    this.locationListModal.hide();
    //this.checkForRefresh();
}

/* enf location */

 /*begin costcenter*/
 public selectCostCenters() {

  let selectedDepartments: Array<Department> = null;
  let selectedDivisions: Array<Division> = null;
  let selectedLocations: Array<Location> = null;
  selectedDepartments = this.selectedDepartments;
  selectedDivisions = this.selectedDivisions;
  selectedLocations = this.selectedLocations;

  let params = new Array<Param>();
  params.push(new Param('departmentIds', AppUtils.getIdsList<Department, number>(selectedDepartments)));
  params.push(new Param('divisionIds', AppUtils.getIdsList<Division, number>(selectedDivisions)));
  params.push(new Param('locationIds', AppUtils.getIdsList<Location, number>(selectedLocations)));

  this.costCenterListModal.show();
  this.costCenterList.selectedItems = this.selectedCostCenters;
  this.costCenterList.refresh(params);
}

// public removeFromCostCenterSelection(costCenter: CostCenter) {
//   let index: number = this.selectedCostCenters.indexOf(costCenter);
//   this.selectedCostCenters.splice(index, 1);
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }

// public clearCostCenterSelection() {
//   this.selectedCostCenters = new Array<CostCenter>();
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }

// public setSelectedCostCenters() {
//   this.selectedCostCenters = this.costCenterList.selectedItems;
//   this.costCenterListModal.hide();
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }
/*end costcenter*/

/*begin type */
public selectTypes() {

  this.typeListModal.show();
  this.typeList.selectedItems = this.selectedTypes;
  this.typeList.refresh(null);
}

// public removeFromTypeSelection(type: Type) {
//   let index: number = this.selectedTypes.indexOf(type);
//   this.selectedTypes.splice(index, 1);
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }

// public clearTypeSelection() {
//   this.selectedTypes = new Array<Type>();
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }

// public setSelectedTypes() {
//   this.selectedTypes = this.typeList.selectedItems;
//   this.typeListModal.hide();
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }
/*end type*/


/*begin asset type */
public selectAssetTypes() {

  this.assetTypeListModal.show();
  this.assetTypeList.selectedItems = this.selectedAssetTypes;
  this.assetTypeList.refresh(null);
}

// public removeFromAssetTypeSelection(assetType: AssetType) {
//   let index: number = this.selectedAssetTypes.indexOf(assetType);
//   this.selectedAssetTypes.splice(index, 1);
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }

// public clearAsetTypeSelection() {
//   this.selectedAssetTypes = new Array<AssetType>();
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }

// public setSelectedAssetTypes() {
//   this.selectedAssetTypes = this.assetTypeList.selectedItems;
//   this.assetTypeListModal.hide();
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }
/*end  asset type*/

 /*begin project */
public selectProjects() {

  this.projectListModal.show();
  this.projectList.selectedItems = this.selectedProjects;
  this.projectList.refresh(null);
}

public removeFromProjectSelection(project: Project) {
  let index: number = this.selectedProjects.indexOf(project);
  this.selectedProjects.splice(index, 1);
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
}

// public clearProjectSelection() {
//   this.selectedProjects = new Array<Project>();
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }

// public setSelectedProjects() {
//   this.selectedProjects = this.projectList.selectedItems;
//   this.projectListModal.hide();
//   this.loadData(
//     this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
//           this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
//           this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
//           this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
//           this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
//           this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
// }
/*end  project */

public clearFilters() {

  this.selectedLocations = new Array<Location>();
  this.selectedCostCenters = new Array<CostCenter>();
  this.selectedDepartments = new Array<Department>();
  this.selectedBudgetManagers = new Array<BudgetManager>();
  this.selectedLocations = new Array<Location>();
  this.selectedDivisions = new Array<Division>();
  this.selectedTypes = new Array<Type>();
  this.selectedAssetTypes = new Array<AssetType>();
  this.selectedProjects = new Array<Project>();
  // this.loadData(
  //   this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
  //         this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
  //         this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
  //         this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
  //         this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
  //         this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
}

public expandAll() {
  this.isDepartmentCollapsed = false;
  this.isBudgetManagerCollapsed = false;
  this.isDivisionCollapsed = false;
  this.isCostCenterCollapsed = false;
  this.isTypeCollapsed = false;
  this.isAssetTypeCollapsed = false;
  this.isProjectCollapsed = false;
  // this.loadData(
  //   this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
  //         this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
  //         this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
  //         this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
  //         this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
  //         this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
}

public hideAll() {
  this.isDepartmentCollapsed = true;
  this.isBudgetManagerCollapsed = true;
  this.isDivisionCollapsed = true;
  this.isCostCenterCollapsed = true;
  this.isTypeCollapsed = true;
  this.isAssetTypeCollapsed = true;
  this.isProjectCollapsed = true;
  // this.loadData(
  //   this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
  //         this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
  //         this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
  //         this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
  //         this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
  //         this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);
}

public preventReload(event) {
  event.preventDefault();
}

hideDepartmentReport() {
  this.departmentReportModal.hide();
  this.itemsTotalDepartmentArray = [];
  this.departmentId = 0;
  this.type = 0;

}

public showTotalAssetByDepartmentId(id: number) {
  this.clearFilterSearch();
  this.departmentId = id;

  let params = new Array<Param>();
  let dashboardFilter: DashboardFilter = new DashboardFilter();
  params.push(new Param('filter', ''));


    dashboardFilter.departmentIds = new Array<number>();
    dashboardFilter.departmentIds.push(id);
    params.push(new Param('jsonFilter', JSON.stringify(dashboardFilter)));
    this.assetList.refresh(params);
}


hideBudgetManagerReport() {
  this.budgetManagerReportModal.hide();
  this.itemsTotalBudgetManagerArray = [];
  this.budgetManagerId = 0;
  this.type = 0;

}

public showTotalAssetByBudgetManagerId(id: number) {
  this.clearFilterSearch();
  this.budgetManagerId = id;

  let params = new Array<Param>();
  let dashboardFilter: DashboardFilter = new DashboardFilter();
  params.push(new Param('filter', ''));


    dashboardFilter.budgetManagerIds = new Array<number>();
    dashboardFilter.budgetManagerIds.push(id);
    params.push(new Param('jsonFilter', JSON.stringify(dashboardFilter)));
    this.assetList.refresh(params);
}


public showTotalAssetByDivisionId(id: number, type: number) {
  this.clearFilterSearch();
  this.divisionId = id;
  this.type = type;

  let params = new Array<Param>();
  let dashboardFilter: DashboardFilter = new DashboardFilter();
  params.push(new Param('filter', ''));

  dashboardFilter.divisionIds = new Array<number>();
  dashboardFilter.divisionIds.push(id);
  dashboardFilter.reportType = type;

  if (this.selectedDepartments != null) {
    dashboardFilter.departmentIds = new Array<number>();
    this.selectedDepartments.forEach((department) => {
      dashboardFilter.departmentIds.push(department.id);
    });
}

if (this.selectedCostCenters != null) {
  dashboardFilter.costCenterIds = new Array<number>();
  this.selectedCostCenters.forEach((costCenter) => {
    dashboardFilter.costCenterIds.push(costCenter.id);
  });
}

if (this.selectedTypes != null) {
dashboardFilter.typeIds = new Array<number>();
this.selectedTypes.forEach((type) => {
  dashboardFilter.typeIds.push(type.id);
});
}

if (this.selectedAssetTypes != null) {
dashboardFilter.assetTypeIds = new Array<number>();
this.selectedAssetTypes.forEach((assetType) => {
  dashboardFilter.assetTypeIds.push(assetType.id);
});
}

if (this.selectedProjects != null) {
  dashboardFilter.projectIds = new Array<number>();
  this.selectedProjects.forEach((project) => {
    dashboardFilter.projectIds.push(project.id);
  });
}
  params.push(new Param('jsonFilter', JSON.stringify(dashboardFilter)));

  this.dashboardDivisionEntityList.refresh(params);
  this.divisionReportModal.show();
}


hideDivisionReport() {
  this.divisionReportModal.hide();
  this.itemsTotalDivisionArray = [];
  this.divisionId = 0;
  this.type = 0;

}


public showTotalAssetByCostCenterId(id: number, type: number) {
  this.clearFilterSearch();
  this.costCenterId = id;
  this.type = type;

  let params = new Array<Param>();
  let dashboardFilter: DashboardFilter = new DashboardFilter();
  params.push(new Param('filter', ''));

  dashboardFilter.costCenterIds = new Array<number>();
  dashboardFilter.costCenterIds.push(id);
  dashboardFilter.reportType = type;

  if (this.selectedDepartments != null) {
    dashboardFilter.departmentIds = new Array<number>();
    this.selectedDepartments.forEach((department) => {
      dashboardFilter.departmentIds.push(department.id);
    });
}

if (this.selectedDivisions != null) {
  dashboardFilter.divisionIds = new Array<number>();
  this.selectedDivisions.forEach((division) => {
    dashboardFilter.divisionIds.push(division.id);
  });
}

if (this.selectedTypes != null) {
dashboardFilter.typeIds = new Array<number>();
this.selectedTypes.forEach((type) => {
  dashboardFilter.typeIds.push(type.id);
});
}

if (this.selectedAssetTypes != null) {
dashboardFilter.assetTypeIds = new Array<number>();
this.selectedAssetTypes.forEach((assetType) => {
  dashboardFilter.assetTypeIds.push(assetType.id);
});
}

if (this.selectedProjects != null) {
  dashboardFilter.projectIds = new Array<number>();
  this.selectedProjects.forEach((project) => {
    dashboardFilter.projectIds.push(project.id);
  });
}

  params.push(new Param('jsonFilter', JSON.stringify(dashboardFilter)));

  this.dashboardCostCenterEntityList.refresh(params);
  this.costCenterReportModal.show();
}


hideTypeReport() {
  this.typeReportModal.hide();
  this.itemsTotalTypeArray = [];
  this.typeId = 0;
  this.type = 0;

}

public showTotalAssetByTypeId(id: number, type: number) {
  this.clearFilterSearch();
  this.typeId = id;
  this.type = type;

  let params = new Array<Param>();
  let dashboardFilter: DashboardFilter = new DashboardFilter();
  params.push(new Param('filter', ''));

  dashboardFilter.typeIds = new Array<number>();
  dashboardFilter.typeIds.push(id);
  dashboardFilter.reportType = type;

  if (this.selectedDepartments != null) {
    dashboardFilter.departmentIds = new Array<number>();
    this.selectedDepartments.forEach((department) => {
      dashboardFilter.departmentIds.push(department.id);
    });
}

if (this.selectedDivisions != null) {
  dashboardFilter.divisionIds = new Array<number>();
  this.selectedDivisions.forEach((division) => {
    dashboardFilter.divisionIds.push(division.id);
  });
}

if (this.selectedCostCenters != null) {
dashboardFilter.costCenterIds = new Array<number>();
this.selectedCostCenters.forEach((costCenter) => {
  dashboardFilter.costCenterIds.push(costCenter.id);
});
}

if (this.selectedAssetTypes != null) {
dashboardFilter.assetTypeIds = new Array<number>();
this.selectedAssetTypes.forEach((assetType) => {
  dashboardFilter.assetTypeIds.push(assetType.id);
});
}

if (this.selectedProjects != null) {
  dashboardFilter.projectIds = new Array<number>();
  this.selectedProjects.forEach((project) => {
    dashboardFilter.projectIds.push(project.id);
  });
}

  params.push(new Param('jsonFilter', JSON.stringify(dashboardFilter)));

  this.dashboardTypeEntityList.refresh(params);
  this.typeReportModal.show();
}

hideAssetTypeReport() {
  this.assetTypeReportModal.hide();
  this.itemsTotalAssetTypeArray = [];
  this.assetTypeId = 0;
  this.type = 0;

}

public showTotalAssetByAssetTypeId(id: number, type: number) {

  this.clearFilterSearch();
  this.assetTypeId = id;
  this.type = type;

  let params = new Array<Param>();
  let dashboardFilter: DashboardFilter = new DashboardFilter();
  params.push(new Param('filter', ''));

  dashboardFilter.assetTypeIds = new Array<number>();
  dashboardFilter.assetTypeIds.push(id);
  dashboardFilter.reportType = type;

  if (this.selectedDepartments != null) {
    dashboardFilter.departmentIds = new Array<number>();
    this.selectedDepartments.forEach((department) => {
      dashboardFilter.departmentIds.push(department.id);
    });
}

if (this.selectedDivisions != null) {
  dashboardFilter.divisionIds = new Array<number>();
  this.selectedDivisions.forEach((division) => {
    dashboardFilter.divisionIds.push(division.id);
  });
}

if (this.selectedCostCenters != null) {
dashboardFilter.costCenterIds = new Array<number>();
this.selectedCostCenters.forEach((costCenter) => {
  dashboardFilter.costCenterIds.push(costCenter.id);
});
}

if (this.selectedTypes != null) {
dashboardFilter.typeIds = new Array<number>();
this.selectedTypes.forEach((type) => {
  dashboardFilter.typeIds.push(type.id);
});
}

if (this.selectedProjects != null) {
  dashboardFilter.projectIds = new Array<number>();
  this.selectedProjects.forEach((project) => {
    dashboardFilter.projectIds.push(project.id);
  });
}

  params.push(new Param('jsonFilter', JSON.stringify(dashboardFilter)));

  this.dashboardAssetTypeEntityList.refresh(params);
  this.assetTypeReportModal.show();
}

hideProjectReport() {
  this.projectReportModal.hide();
  this.itemsTotalProjectArray = [];
  this.projectId = 0;
  this.type = 0;

}

public showTotalAssetByProjectId(id: number, type: number) {

  this.clearFilterSearch();
  this.projectId = id;
  this.type = type;

  let params = new Array<Param>();
  let dashboardFilter: DashboardFilter = new DashboardFilter();
  params.push(new Param('filter', ''));

  dashboardFilter.projectIds = new Array<number>();
  dashboardFilter.projectIds.push(id);
  dashboardFilter.reportType = type;


  if (this.selectedAssetTypes != null) {
    dashboardFilter.assetTypeIds = new Array<number>();
    this.selectedAssetTypes.forEach((assetType) => {
      dashboardFilter.assetTypeIds.push(assetType.id);
    });
}

  if (this.selectedDepartments != null) {
    dashboardFilter.departmentIds = new Array<number>();
    this.selectedDepartments.forEach((department) => {
      dashboardFilter.departmentIds.push(department.id);
    });
}

if (this.selectedDivisions != null) {
  dashboardFilter.divisionIds = new Array<number>();
  this.selectedDivisions.forEach((division) => {
    dashboardFilter.divisionIds.push(division.id);
  });
}

if (this.selectedCostCenters != null) {
dashboardFilter.costCenterIds = new Array<number>();
this.selectedCostCenters.forEach((costCenter) => {
  dashboardFilter.costCenterIds.push(costCenter.id);
});
}

if (this.selectedTypes != null) {
dashboardFilter.typeIds = new Array<number>();
this.selectedTypes.forEach((type) => {
  dashboardFilter.typeIds.push(type.id);
});
}

  params.push(new Param('jsonFilter', JSON.stringify(dashboardFilter)));

  this.dashboardProjectEntityList.refresh(params);
  this.projectReportModal.show();
}


hideCostCenterReport() {
  this.costCenterReportModal.hide();
  this.itemsTotalCostCenterArray = [];
  this.costCenterId = 0;
  this.type = 0;

}


exportBudgetManagerReport() {
  this.showBudgetManagerExportBtn = false;
  this.dashboardHttpService.exportTotalAssetsByBudgetManager(this.inventoryId,
    this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId).subscribe((blob) => {
    fileSaveAs(blob.body, "Export.xlsx");
    this.showBudgetManagerExportBtn = true;
  });
}

exportDepartmentReport() {
  this.showDepartmentExportBtn = false;
  this.dashboardHttpService.exportTotalAssetsByDepartment(this.inventoryId,
    this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId).subscribe((blob) => {
    fileSaveAs(blob.body, "Export.xlsx");
    this.showDepartmentExportBtn = true;
  });
}

exportDivisionReport() {
  this.showDivisionExportBtn = false;
  this.dashboardHttpService.exportTotalAssetsByDivision(this.inventoryId,
    this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId).subscribe((blob) => {
    fileSaveAs(blob.body, "Export.xlsx");
    this.showDivisionExportBtn = true;
  });
}

exportCostCenterReport() {
  this.showCostCenterExportBtn = false;
  this.dashboardHttpService.exportTotalAssetsByCostCenter(this.inventoryId,
    this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId).subscribe((blob) => {
    fileSaveAs(blob.body, "Export.xlsx");
    this.showCostCenterExportBtn = true;
  });
}

exportTypeReport() {
  this.showTypeExportBtn = false;
  this.dashboardHttpService.exportTotalAssetsByType(this.inventoryId,
    this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId).subscribe((blob) => {
    fileSaveAs(blob.body, "Export.xlsx");
    this.showTypeExportBtn = true;
  });
}

exportAssetTypeReport() {
  this.showAssetTypeExportBtn = false;
  this.dashboardHttpService.exportTotalAssetsByAssetType(this.inventoryId,
    this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId).subscribe((blob) => {
    fileSaveAs(blob.body, "Export.xlsx");
    this.showAssetTypeExportBtn = true;
  });
}


exportProjectReport() {
  this.showProjectExportBtn = false;
  this.dashboardHttpService.exportTotalAssetsByProject(this.inventoryId,
    this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId,
    this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId,
    this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId,
    this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId,
    this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId,
    this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId).subscribe((blob) => {
    fileSaveAs(blob.body, "Export.xlsx");
    this.showProjectExportBtn = true;
  });
}


export () {
    this.showExportBtn = false;

    let params = new Array<Param>();
    let dashboardFilter: DashboardFilter = new DashboardFilter();

    dashboardFilter.costCenterIds = new Array<number>();
    dashboardFilter.costCenterIds.push(this.selectedCostCenters.length > 0 ? this.selectedCostCenters[0].id : this.costCenterId);

    dashboardFilter.departmentIds = new Array<number>();
    dashboardFilter.departmentIds.push(this.selectedDepartments.length > 0 ? this.selectedDepartments[0].id : this.departmentId);

    dashboardFilter.divisionIds = new Array<number>();
    dashboardFilter.divisionIds.push(this.selectedDivisions.length > 0 ? this.selectedDivisions[0].id : this.divisionId);

    dashboardFilter.typeIds = new Array<number>();
    dashboardFilter.typeIds.push(this.selectedTypes.length > 0 ? this.selectedTypes[0].id : this.typeId);

    dashboardFilter.assetTypeIds = new Array<number>();
    dashboardFilter.assetTypeIds.push(this.selectedAssetTypes.length > 0 ? this.selectedAssetTypes[0].id : this.assetTypeId);

    dashboardFilter.projectIds = new Array<number>();
    dashboardFilter.projectIds.push(this.selectedProjects.length > 0 ? this.selectedProjects[0].id : this.projectId);

    dashboardFilter.reportType = this.type;
    // dashboardFilter.filterDepartment = this.dashboardDepartmentEntityList.filterSearch;
    // dashboardFilter.filterDivision = this.dashboardDivisionEntityList.filterSearch;
    // dashboardFilter.filterCostCenter = this.dashboardCostCenterEntityList.filterSearch;
    // dashboardFilter.filterType = this.dashboardTypeEntityList.filterSearch;
    // dashboardFilter.filterAssetType = this.dashboardAssetTypeEntityList.filterSearch;
    dashboardFilter.filter =

    this.dashboardDepartmentEntityList.filterSearch != '' ? this.dashboardDepartmentEntityList.filterSearch :
    this.dashboardDivisionEntityList.filterSearch != '' ? this.dashboardDivisionEntityList.filterSearch :
    this.dashboardCostCenterEntityList.filterSearch != '' ? this.dashboardCostCenterEntityList.filterSearch :
    this.dashboardTypeEntityList.filterSearch != '' ? this.dashboardTypeEntityList.filterSearch :
    this.dashboardAssetTypeEntityList.filterSearch != '' ? this.dashboardAssetTypeEntityList.filterSearch : '';
    this.dashboardProjectEntityList.filterSearch != '' ? this.dashboardProjectEntityList.filterSearch : '';


    params.push(new Param('jsonFilter', JSON.stringify(dashboardFilter)));

    this.dashboardHttpService
        .export(params)
        .subscribe((blob) => {
    fileSaveAs(blob.body, 'Export.xlsx');
    this.showExportBtn = true;
  });
}

clearFilterSearch () {
  this.dashboardDepartmentEntityList.filterSearch = '';
  this.dashboardDivisionEntityList.filterSearch = '';
  this.dashboardCostCenterEntityList.filterSearch = '';
  this.dashboardTypeEntityList.filterSearch = '';
  this.dashboardAssetTypeEntityList.filterSearch = '';
  this.dashboardProjectEntityList.filterSearch = '';
}

}
