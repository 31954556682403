import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { TableDefinitionHttpService } from './services/http/common/table-definition.http.service';
import { ColumnDefinitionHttpService } from './services/http/common/column-definition.http.service';
import { ConfigValuesHttpService } from './services/http/common/config-values.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from './services/authentication.service';
import { CountyHttpService } from './services/http/administration/county.http.service';
import { TokenInterceptor } from './services/http/common/token-interceptor.service';
import { CountryHttpService } from './services/http/administration/contry.http.service';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { AuthGuard } from './services/auth.guard';
import { CountryListComponent } from './forms/administrations/countries/country.list';
import { CountryManageComponent } from './forms/administrations/countries/country.manage';
import { CountryDetailComponent } from './forms/administrations/countries/country.detail';
import { CountyManageComponent } from './forms/administrations/counties/county.manage';
import { CountyListComponent } from './forms/administrations/counties/county.list';
import { CountyDetailComponent } from './forms/administrations/counties/county.detail';
import { CityListComponent } from './forms/administrations/cities/city.list';
import { CityManageComponent } from './forms/administrations/cities/city.manage';
import { CityDetailComponent } from './forms/administrations/cities/city.detail';
import { AssetManageComponent } from './forms/assets/assets/asset.manage';
import { PaginationComponent, PaginationModule } from 'ngx-bootstrap/pagination';
import { AssetRecoManageComponent } from './forms/assets/assets/asset-reco.manage';
import { AssetRecoListComponent } from './forms/assets/assets/asset-reco.list';
import { DepartmentListComponent } from './forms/administrations/departments/department.list';
import { EmployeeListComponent } from './forms/administrations/employees/employee.list';
import { RegionListComponent } from './forms/administrations/regions/region.list';
import { AdmCenterListComponent } from './forms/administrations/adm-centers/adm-center.list';
import { LocationListComponent } from './forms/administrations/locations/location.list';
import { RoomListComponent } from './forms/administrations/rooms/room.list';
import { AdministrationListComponent } from './forms/administrations/administrations/administration.list';
import { AssetCategoryListComponent } from './forms/assets/asset-categories/asset-category.list';
import { UomListComponent } from './forms/assets/uoms/uom.list';
import { CompanyListComponent } from './forms/assets/companies/company.list';
import { AssetClassListComponent } from './forms/assets/asset-classes/asset-class.list';
import { AssetTypeListComponent } from './forms/assets/asset-types/asset-type.list';
import { DivisionListComponent } from './forms/administrations/divisions/division.list';
import { PartnerListComponent } from './forms/documents/partners/partner.list';
import { CostCenterListComponent } from './forms/administrations/cost-centers/cost-center.list';
import { DimensionListComponent } from './forms/assets/dimensions/dimension.list';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { DictionaryItemListComponent } from './forms/administrations/dictionary-item/dictionary-item.list';
import { DictionaryItemDetailComponent } from './forms/administrations/dictionary-item/dictionary-item.detail';
import { FieldByColumnDefinitionPipe } from './forms/common/pipes/field-by-column-definition';
import { AssetAllListComponent } from './forms/assets/assets/asset-all.list';
import { BoolPipe } from './forms/common/pipes/bool.pipe';
import { BoolPipeState } from './forms/common/pipes/bool.pipe_state';
import { BoolPipeInventory } from './forms/common/pipes/bool-pipe.inventory';
import { MergePipe } from './forms/common/pipes/merge.pipe';
import { MergeRoom } from './forms/common/pipes/merge-room.pipe';
import { HighlightPipe } from './forms/common/pipes/highlight-pipe ';
import { ConfigValuesList } from './forms/common/config-values.list';
import { ConfigValuesDetail } from './forms/common/config-values.detail';
import { ConfigValuesManage } from './forms/common/config-values.manage';
import { InventoryManage } from './forms/inventory/inventory.manage';
import { InventoryList } from './forms/inventory/inventory.list';
import { InventoryDetail } from './forms/inventory/inventory.detail';
import { AccMonthListComponent } from './forms/accounting/acc-month.list';
import { AccMonthDetailComponent } from './forms/accounting/acc-month.detail';
import { AccMonthManageComponent } from './forms/accounting/acc-month.manage';
import { InvStateList } from './forms/inventory/inv-state/inv-state.list';
import { InvStateDetail } from './forms/inventory/inv-state/inv-state.detail';
import { InvStateManage } from './forms/inventory/inv-state/inv-state.manage';
import { AssetRecoAllListComponent } from './forms/assets/assets/asset-reco-all.list';
import { DictionaryItemManageComponent } from './forms/administrations/dictionary-item/dictionary-item.manage';
import { DictionaryTypeManageComponent } from './forms/administrations/dictionary-type/dictionary-type.manage';
import { DictionaryTypeDetailComponent } from './forms/administrations/dictionary-type/dictionary-type.detail';
import { DictionaryTypeListComponent } from './forms/administrations/dictionary-type/dictionary-type.list';
import { AdministrationManageComponent } from './forms/administrations/administrations/administration.manage';
import { AdministrationDetailComponent } from './forms/administrations/administrations/administration.detail';
import { AssetDetailUIComponent } from './forms/assets/assets/asset.detail.ui';
import { PartnerDetailComponent } from './forms/documents/partners/partner.detail';
import { PartnerManageComponent } from './forms/documents/partners/partner.manage';
import { RoomDetailComponent } from './forms/administrations/rooms/room.detail';
import { RoomManageComponent } from './forms/administrations/rooms/room.manage';
import { UomDetailComponent } from './forms/assets/uoms/uom.detail';
import { UomManageComponent } from './forms/assets/uoms/uom.manage';
import { CostCenterDetailComponent } from './forms/administrations/cost-centers/cost-center.detail';
import { CostCenterManageComponent } from './forms/administrations/cost-centers/cost-center.manage';
import { LocationDetailComponent } from './forms/administrations/locations/location.detail';
import { LocationManageComponent } from './forms/administrations/locations/location.manage';
import { EmployeeDetailComponent } from './forms/administrations/employees/employee.detail';
import { EmployeeManageComponent } from './forms/administrations/employees/employee.manage';
import { DepartmentDetailComponent } from './forms/administrations/departments/department.detail';
import { DepartmentManageComponent } from './forms/administrations/departments/department.manage';
import { AssetTypeDetailComponent } from './forms/assets/asset-types/asset-type.detail';
import { AssetTypeManageComponent } from './forms/assets/asset-types/asset-type.manage';
import { AssetCategoryDetailComponent } from './forms/assets/asset-categories/asset-category.detail';
import { AssetCategoryManageComponent } from './forms/assets/asset-categories/asset-category.manage';
import { EntityFileListComponent } from './forms/common/entity-file.list';
import { AssetOpDetailListComponent } from './forms/assets/asset-ops/asset-op.detail.list';
import { FieldByNamePipe } from './forms/common/pipes/field-by-name.pipe';
import { AssetInventoryReportComponent } from './forms/assets/assets/asset-inventory-report';
import { UserListComponent } from './forms/auth/user.list';
import { AssetInventoryManageComponent } from './forms/assets/assets/asset-inventory.manage';
import { AssetInvTempDetailListComponent } from './forms/assets/assets/asset-inv-temp-detail.list';
import { AssetInvFullDetailListComponent } from './forms/assets/assets/asset-inv-full-detail.list';
import { AssetListComponent } from './forms/assets/assets/asset.list';
import { AssetDepManageComponent } from './forms/assets/assets/asset-dep.manage';
import { AdministrationHttpService } from './services/http/administration/administration.http.service';
import { AssetHttpService } from './services/http/assets/asset.http.service';
import { UomHttpService } from './services/http/assets/uom.http.service';
import { DivisionHttpService } from './services/http/administration/division.http.service';
import { AdmCenterDetailComponent } from './forms/administrations/adm-centers/adm-center.detail';
import { AdmCenterManageComponent } from './forms/administrations/adm-centers/adm-center.manage';
import { AssetEntityListComponent } from './forms/assets/assets/asset-entity.list';
import { IdentityManage } from './forms/auth/identity.manage';
import { RoleList } from './forms/auth/role.list';
import { RoleService } from './services/http/identity/role.service';
import { CompanyDetailComponent } from './forms/assets/companies/company.detail';
import { CompanyManageComponent } from './forms/assets/companies/company.manage';
import { AuthorizationService } from './services/authorization.service';
import { AssetStateManageComponent } from './forms/assets/asset-states/asset-state.manage';
import { AssetStateListComponent } from './forms/assets/asset-states/asset-state.list';
import { AssetStateDetailComponent } from './forms/assets/asset-states/asset-state.detail';
import { MasterTypeManageComponent } from './forms/assets/master-types/master-type.manage';
import { MasterTypeListComponent } from './forms/assets/master-types/master-type.list';
import { MasterTypeDetailComponent } from './forms/assets/master-types/master-type.detail';
import { LocationHttpService } from './services/http/administration/location.http.service';
import { BudgetValidateList } from './forms/administrations/budget/budget-validate.list';
import { BudgetValidateManage } from './forms/administrations/budget/budget-validate.manage';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BudgetDetailUI } from './forms/administrations/budget/budget.detail.ui';
import { AccountList } from './forms/administrations/account/account.list';
import { ProjectManage } from './forms/assets/projects/project.manage';
import { ProjectList } from './forms/assets/projects/project.list';
import { ProjectDetail } from './forms/assets/projects/project.detail';
import { SubTypeList } from './forms/administrations/sub-types/sub-type.list';
import { SubTypeManage } from './forms/administrations/sub-types/sub-type.manage';
import { SubTypeDetail } from './forms/administrations/sub-types/sub-type.detail';
import { TypeManage } from './forms/administrations/types/type.manage';
import { TypeDetail } from './forms/administrations/types/type.detail';
import { TypeList } from './forms/administrations/types/type.list';
import { EntityFileUploadComponent } from './forms/common/entity-file-upload';
import { EntityFileDownloadComponent } from './forms/common/entity-file-download';
import { BudgetOpDetailList } from './forms/administrations/budget-ops/budget-op.detail.list';
import { BudgetOpManage } from './forms/administrations/budget-ops/budget-op-manage';
import { BudgetManage } from './forms/administrations/budget/budget.manage';
import { BudgetList } from './forms/administrations/budget/budget.list';
import { BudgetHttpService } from './services/http/administration/budget.http.service';
import { OrderValidateList } from './forms/administrations/order/order-validate.list';
import { OfferValidateList } from './forms/administrations/offer/offer-validate.list';
import { OfferValidateManage } from './forms/administrations/offer/offer-validate.manage';
import { OfferDetailUIComponent } from './forms/administrations/offer/offer.detail.ui';
import { OfferOpDetailList } from './forms/administrations/offer-ops/offer-op.detail.list';
import { SanitizeHtml } from './forms/common/pipes/sanitize.html-pipe';
import { OfferManage } from './forms/administrations/offer/offer.manage';
import { OfferList } from './forms/administrations/offer/offer.list';
import { OfferHttpService } from './services/http/administration/offer.http.service';
import { OfferOpManage } from './forms/administrations/offer-ops/offer-op-manage';
import { OrderValidateManage } from './forms/administrations/order/order-validate.manage';
import { OrderDetailUIComponent } from './forms/administrations/order/order.detail.ui';
import { OrderOpDetailList } from './forms/administrations/order-ops/order-op.detail.list';
import { OrderList } from './forms/administrations/order/order.list';
import { OrderManageComponent } from './forms/administrations/order/order.manage';
import { OrderOpManage } from './forms/administrations/order-ops/order-op-manage';
import { OrderHttpService } from './services/http/administration/order.http.service';
import { EmailManagerManageComponent } from './forms/administrations/email-manager/email-manager.manage';
import { EmailManagerList } from './forms/administrations/email-manager/email-manager.list';
import { EmailManagerDetail } from './forms/administrations/email-manager/email-manager.detail';
import { EmailTypeDetail } from './forms/administrations/email-type/email-type.detail';
import { EmailTypeList } from './forms/administrations/email-type/email-type.list';
import { EmailTypeManage } from './forms/administrations/email-type/email-type.manage';
import { EmailManagerHttpService } from './services/http/administration/email-manager.http.service';
import { EmailTypeHttpService } from './services/http/administration/email-type.http.service';
import { AppStateHttpService } from './services/http/common/app-state.http.service';
import { EmailManagerDetailHttpService } from './services/http/administration/email-manager-detail.http.service';
import { AssetComponentManage } from './forms/assets/asset-components/asset-component.manage';
import { AssetComponentList } from './forms/assets/asset-components/asset-component.list';
import { AssetComponentDetail } from './forms/assets/asset-components/asset-component.detail';
import { AssetComponentDetailHttpService } from './services/http/assets/asset-component-detail.http.service';
import { AssetComponentOpDetailList } from './forms/assets/asset-component-ops/asset-component-op.detail.list';
import { EmployeeHttpService } from './services/http/administration/employee.http.service';
import { SubTypeHttpService } from './services/http/administration/sub-type.http.service';
import { AssetComponentHttpService } from './services/http/assets/asset-component.http.service';
import { EntityFileHttpService } from './services/http/common/entity-file.http.service';
import { EmployeeDetailUIComponent } from './forms/administrations/employees/employee.detail.ui';
import { DepartmentHttpService } from './services/http/administration/department.http.service';
import { EmployeeDetailHttpService } from './services/http/administration/employee-detail.http.service';
import { AssetEmployeeValidateList } from './forms/assets/assets/asset-employee-validate.list';
import { AssetInventoryEmployeeValidateManage } from './forms/assets/assets/asset-inventory-employee-validate.manage';
import { AssetInventoryEmailManage } from './forms/assets/assets/asset-inventory-email.manage';
import { AssetInvEmailList } from './forms/assets/assets/asset-inv-email.list';
import { AssetTypeHttpService } from './services/http/assets/asset-type.http.service';
import { AssetCategoryHttpService } from './services/http/assets/asset-category.http.service';
import { AdmCenterHttpService } from './services/http/administration/adm-center.http.service';
import { InventoryHttpService } from './services/http/inventory/inventory.http.service';
import { RegionHttpService } from './services/http/administration/region.http.service';
import { RoomDetailHttpService } from './services/http/administration/room-detail.http.service';
import { CompanyHttpService } from './services/http/assets/company.http.service';
import { CityHttpService } from './services/http/administration/city.http.service';
import { InvStateHttpService } from './services/http/inventory/inv-state.http.service';
import { DocumentTypeHttpService } from './services/http/documents/document-type.http.service';
import { AssetInvDetailListComponent } from './forms/assets/assets/asset-inv-detail.list';
import { AssetDepDetailListComponent } from './forms/assets/assets/asset-dep-detail.list';
import { AssetClassManageComponent } from './forms/assets/asset-classes/asset-class.manage';
import { AssetClassDetailComponent } from './forms/assets/asset-classes/asset-class.detail';
import { DocumentHttpService } from './services/http/documents/document.http.service';
import { AssetOpManageComponent } from './forms/assets/asset-ops/asset-op-manage';
import { AccountHttpService } from './services/http/administration/account.http.service';
import { TypeHttpService } from './services/http/administration/type.http.service';
import { MasterTypeHttpService } from './services/http/assets/master-type.http.service';
import { ProjectHttpService } from './services/http/assets/project.http.service';
import { InterCompanyHttpService } from './services/http/assets/inter-company.http.service';
import { BudgetOpHttpService } from './services/http/administration/budget-op.http.service';
import { AssetOpHttpService } from './services/http/assets/asset-op.http.service';
import { DimensionHttpService } from './services/http/administration/dimension.http.service';
import { DocumentTypeDropDownListComponent } from './forms/documents/document-types/document-type.drop-down.list';
import { DimensionManageComponent } from './forms/assets/dimensions/dimension.manage';
import { DimensionDetailComponent } from './forms/assets/dimensions/dimension.detail';
import { PaginationsComponent } from './views/base/paginations.component';
import { JwtHelperService, JwtModuleOptions, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AccMonthHttpService } from './services/http/accounting/acc-month.http.service';
import { CostCenterHttpService } from './services/http/administration/cost-center.http.service';
import { BaThemeConfigProvider } from './services/http/common/theme-colors.service';
import { RouteManageComponent } from './forms/common/route-definition/route.manage';
import { RouteListComponent } from './forms/common/route-definition/route.list';
import { RouteDetailComponent } from './forms/common/route-definition/route.detail';
import { RouteHttpService } from './services/http/common/route.http.service';
import { RouteChildManageComponent } from './forms/common/route-child-definition/route-child.manage';
import { RouteChildHttpService } from './services/http/common/route-child.http.service';
import { HideIfNotUnauthorizedDirective } from './directives/hideIfNotAuthorized';
import { DisableIfNotUnauthorizedDirective } from './directives/disableIfNotAuthorized';
import { PermissionTypeHttpService } from './services/http/common/permission-type.http.service';
import { PermissionHttpService } from './services/http/common/permission.http.service';
import { PermissionRoleHttpService } from './services/http/common/permission-role.http.service';
import { DashboardHttpService } from './services/http/common/dashboard.http.service';
import { OperationDetail } from './forms/documents/operations/operation.detail';
import { AssetMemoryService } from './services/memory/asset.memory.service';
import { AssetComponentOpHttpService } from './services/http/assets/asset-component-op.http.service';
import { DashboardOfferComponent } from './views/dashboard/dashboard-offer.component';
import { DashboardOrderComponent } from './views/dashboard/dashboard-order.component';
import { DashboardInventoryComponent } from './views/dashboard/dashboard-inventory.component';
import { AssetNatureHttpService } from './services/http/assets/asset-nature.http.service';
import { AssetNatureManageComponent } from './forms/assets/asset-natures/asset-nature.manage';
import { AssetNatureListComponent } from './forms/assets/asset-natures/asset-nature.list';
import { AssetNatureDetailComponent } from './forms/assets/asset-natures/asset-nature.detail';
import { InsuranceCategoryDetail } from './forms/assets/insurance-categories/insurance-category.detail';
import { InsuranceCategoryList } from './forms/assets/insurance-categories/insurance-category.list';
import { InsuranceCategoryManage } from './forms/assets/insurance-categories/insurance-category.manage';
import { InsuranceCategoryHttpService } from './services/http/assets/insurance-category.http.service';
import { TableDefinitionManageComponent } from './forms/common/table-definition/table-definition.manage';
import { RouteChildListComponent } from './forms/common/route-child-definition/route-child.list';
import { PermissionTypeManage } from './forms/common/permission-type/permission-type.manage';
import { PermissionTypeList } from './forms/common/permission-type/permission-type.list';
import { PermissionTypeDetail } from './forms/common/permission-type/permission-type.detail';
import { PermissionManage } from './forms/common/permission/permission.manage';
import { PermissionList } from './forms/common/permission/permission.list';
import { PermissionDetail } from './forms/common/permission/permission.detail';
import { PermissionRoleManage } from './forms/common/permission-role/permission-role.manage';
import { PermissionRoleList } from './forms/common/permission-role/permission-role.list';
import { PermissionRoleDetail } from './forms/common/permission-role/permission-role.detail';
import { TableDefinitionDetailComponent } from './forms/common/table-definition/table-definition.detail';
import { TableDefinitionListComponent } from './forms/common/table-definition/table-definition.list';
import { ToastrModule } from 'ngx-toastr';
import { NotificationService } from './services/notification.service';
import { RegisterComponent } from './views/register/register.component';
import { BrandDetail } from './forms/assets/brands/brand.detail';
import { BrandList } from './forms/assets/brands/brand.list';
import { BrandManage } from './forms/assets/brands/brand.manage';
import { BrandHttpService } from './services/http/assets/brand.http.service';
import { BadgeManage } from './forms/common/badge/badge.manage';
import { BadgeDetail } from './forms/common/badge/badge.detail';
import { BadgeList } from './forms/common/badge/badge.list';
import { BadgeHttpService } from './services/http/common/badge.http.service';
import { AccountManage } from './forms/administrations/account/account.manage';
import { AccountDetail } from './forms/administrations/account/account.detail';
import { ExpAccountDetail } from './forms/administrations/exp-account/exp-account.detail';
import { ExpAccountList } from './forms/administrations/exp-account/exp-account.list';
import { ExpAccountManage } from './forms/administrations/exp-account/exp-account.manage';
import { ExpAccountHttpService } from './services/http/administration/exp-account.http.service';
import { ArticleDetail } from './forms/administrations/article/article.detail';
import { ArticleList } from './forms/administrations/article/article.list';
import { ArticleManage } from './forms/administrations/article/article.manage';
import { ArticleHttpService } from './services/http/administration/article.http.service';
import { DivisionManageComponent } from './forms/administrations/divisions/division.manage';
import { DivisionDetailComponent } from './forms/administrations/divisions/division.detail';
import { BudgetManagerList } from './forms/administrations/budget-manager/budget-manager.list';
import { BudgetManagerDetail } from './forms/administrations/budget-manager/budget-manager.detail';
import { BudgetManagerHttpService } from './services/http/administration/budget-manager.http.service';
import { BudgetManagerManage } from './forms/administrations/budget-manager/budget-manager.manage';
import { MaterialManageComponent } from './forms/administrations/materials/material.manage';
import { MaterialList } from './forms/administrations/materials/material.list';
import { MaterialDetailComponent } from './forms/administrations/materials/material.detail';
import { MaterialHttpService } from './services/http/administration/material.http.service';
import { RegionManageComponent } from './forms/administrations/regions/region.manage';
import { RegionDetailComponent } from './forms/administrations/regions/region.detail';
import { EmployeeCostCenterManage } from './forms/administrations/employee-cost-centers/employee-cost-center.manage';
import { EmployeeCostCenterList } from './forms/administrations/employee-cost-centers/employee-cost-center.list';
import { EmployeeCostCenterDetail } from './forms/administrations/employee-cost-centers/employee-cost-center.detail';
import { EmployeeCostCenterHttpService } from './services/http/administration/employee-cost-center.http.service';
import { DashboardEntityListComponent } from './views/dashboard/dashboard-entity.list';
import { ActiveRowPipe } from './forms/common/pipes/active-row.pipe';
import { DashboardInventoryMarkComponent } from './views/dashboard/dashboard-inventory-mark.component';
import { DashboardListComponent } from './views/dashboard/dashboard.list';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './common/reuse-strategy';
import { IdentityService } from './services/http/identity/identity.service';
import { DeviceHttpService } from './services/http/common/device.http.service';
import { DeviceTypeHttpService } from './services/http/common/device-type.http.service';
import { DeviceDetailHttpService } from './services/http/common/device-detail.http.service';
import { DeviceDetail } from './forms/common/devices/device.detail';
import { DeviceList } from './forms/common/devices/device.list';
import { DeviceManage } from './forms/common/devices/device.manage';
import { DeviceTypeDetail } from './forms/common/device-types/device-type.detail';
import { DeviceTypeList } from './forms/common/device-types/device-type.list';
import { DeviceTypeManage } from './forms/common/device-types/device-type.manage';
import { AngularMaterialModule } from './angular-material.module';
import {TableModule} from 'primeng/table';
import {PickListModule} from 'primeng/picklist';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {TabViewModule} from 'primeng/tabview';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {SplitButtonModule} from 'primeng/splitbutton';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxEchartsModule } from 'ngx-echarts';
import { DashboardBudgetComponent } from './views/dashboard/Budget/dashboard-budget.component';
import { ActivityManage } from './forms/assets/activities/activity.manage';
import { ActivityList } from './forms/assets/activities/activity.list';
import { ActivityDetail } from './forms/assets/activities/activity.detail';
import { ActivityHttpService } from './services/http/assets/activity.http.service';
import { DashboardBudgetAssetComponent } from './views/dashboard/Budget/dashboard-budget-asset.component';
import {AngularTreeGridModule} from 'angular-tree-grid';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { BudgetPrimeNgList } from './forms/administrations/budget/budget-primeng.list';
import { EmailManagerUiList } from './forms/administrations/email-manager/email-manager-ui.list';
import { CategoryDetailComponent } from './forms/assets/categories/category.detail';
import { CategoryListComponent } from './forms/assets/categories/category.list';
import { CategoryManageComponent } from './forms/assets/categories/category.manage';
import { CategoryHttpService } from './services/http/assets/category.http.service';
import { InterCompanyENHttpService } from './services/http/assets/inter-company-en.http.service';
import { InterCompanyENManageComponent } from './forms/assets/inter-companies-en/inter-company-en.manage';
import { InterCompanyENListComponent } from './forms/assets/inter-companies-en/inter-company-en.list';
import { InterCompanyENDetailComponent } from './forms/assets/inter-companies-en/inter-company-en.detail';
import { InterCompanyManageComponent } from './forms/assets/inter-companies/inter-company.manage';
import { InterCompanyDetailComponent } from './forms/assets/inter-companies/inter-company.detail';
import { InterCompanyListComponent } from './forms/assets/inter-companies/inter-company.list';
import { CategoryENDetailComponent } from './forms/assets/categories-en/category-en.detail';
import { CategoryENListComponent } from './forms/assets/categories-en/category-en.list';
import { CategoryENManageComponent } from './forms/assets/categories-en/category-en.manage';
import { CategoryENHttpService } from './services/http/assets/category-en.http.service';
import { SubCategoryENDetailComponent } from './forms/assets/sub-categories-en/sub-category-en.detail';
import { SubCategoryENListComponent } from './forms/assets/sub-categories-en/sub-category-en.list';
import { SubCategoryENManageComponent } from './forms/assets/sub-categories-en/sub-category-en.manage';
import { SubCategoryENHttpService } from './services/http/assets/sub-category-en.http.service';
import { SubCategoryHttpService } from './services/http/assets/sub-category.http.service';
import { SubCategoryDetailComponent } from './forms/assets/sub-categories/sub-category.detail';
import { SubCategoryListComponent } from './forms/assets/sub-categories/sub-category.list';
import { SubCategoryManageComponent } from './forms/assets/sub-categories/sub-category.manage';
import { ContractDetailUIComponent } from './forms/administrations/contracts/contract.detail.ui';
import { ContractListComponent } from './forms/administrations/contracts/contract.list';
import { ContractManageComponent } from './forms/administrations/contracts/contract.manage';
import { ContractHttpService } from './services/http/administration/contract.http.service';
import { ContractOpHttpService } from './services/http/administration/contract-op.http.service';
import { ContractOpManageComponent } from './forms/administrations/contract-ops/contract-op-manage';
import { ContractOpDetailListComponent } from './forms/administrations/contract-ops/contract-op.detail.list';
import { FileUploadComponent } from './forms/documents/file-upload/file-upload.component';
import { AssetDetailUIAddComponent } from './forms/assets/assets/asset.detail-add.ui';
import { OfferMaterialManageComponent } from './forms/administrations/offer-materials/offer-material.manage';
import { OfferMaterialListComponent } from './forms/administrations/offer-materials/offer-material.list';
import { OfferMaterialDetailComponent } from './forms/administrations/offer-materials/offer-material.detail';
import { OfferMaterialHttpService } from './services/http/administration/offer-material.http.service';
import { AssetScrapManageComponent } from './forms/assets/assets/asset-scrap.manage';
import { AssetSoldManageComponent } from './forms/assets/assets/asset-sold.manage';
import { AssetCFOManageComponent } from './forms/assets/assets/asset-closed.manage';
import { AssetBuyManageComponent } from './forms/assets/assets/asset-suspended.manage';
import { AssetToValidateManageComponent } from './forms/assets/assets/asset-to-validate.manage';
import { StockDetailComponent } from './forms/administrations/stocks/stock.detail';
import { StockListComponent } from './forms/administrations/stocks/stock.list';
import { StockManageComponent } from './forms/administrations/stocks/stock.manage';
import { StockHttpService } from './services/http/administration/stock.http.service';
import { AssetStockDetailUIAddComponent } from './forms/assets/assets/asset-stock.detail-add.ui';
import { OrderValidateOfferListComponent } from './forms/assets/assets/order-validate.list';
import { OfferValidateOfferListComponent } from './forms/assets/assets/offer-validate.list';
import { OfferMaterialOrderValidateListComponent } from './forms/administrations/offer-materials/offer-material-order-validate.list';
import { OrderValidateReadOnlyOfferListComponent } from './forms/assets/assets/order-validate-read-only.list';
import { AddAssetValidationListComponent } from './forms/assets/assets/add-asset-validate.list';
import { RateHttpService } from './services/http/administration/rate.http.service';
import { RequestManageComponent } from './forms/administrations/request/request.manage';
import { RequestList } from './forms/administrations/request/request.list';
import { RequestDetailUIComponent } from './forms/administrations/request/request.detail.ui';
import { RequestHttpService } from './services/http/administration/request.http.service';
import { RequestOpHttpService } from './services/http/administration/request-op.http.service';
import { RequestOpManage } from './forms/administrations/request-ops/request-op-manage';
import { RequestOpDetailList } from './forms/administrations/request-ops/request-op.detail.list';
import { AssetDetailUIValidateComponent } from './forms/assets/assets/asset.detail-validate.ui';
import { AccountancyManageComponent } from './forms/administrations/accountancy/accountancy.manage';
import { AccountancyListComponent } from './forms/administrations/accountancy/accountancy.list';
import { AccountancyDetailComponent } from './forms/administrations/accountancy/accountancy.detail';
import { AccountancyHttpService } from './services/http/administration/accountancy.http.service';
import { MatrixHttpService } from './services/http/administration/matrix.http.service';
import { MatrixDetailComponent } from './forms/administrations/matrix/matrix.detail';
import { MatrixListComponent } from './forms/administrations/matrix/matrix.list';
import { MatrixManageComponent } from './forms/administrations/matrix/matrix.manage';
import { AreaDetailComponent } from './forms/administrations/areas/area.detail';
import { AreaListComponent } from './forms/administrations/areas/area.list';
import { AreaManageComponent } from './forms/administrations/areas/area.manage';
import { AreaHttpService } from './services/http/administration/area.http.service';
import { LevelHttpService } from './services/http/administration/level.http.service';
import { LevelDetailComponent } from './forms/administrations/level/level.detail';
import { LevelListComponent } from './forms/administrations/level/level.list';
import { LevelManageComponent } from './forms/administrations/level/level.manage';
import { MatrixLevelDetailComponent } from './forms/administrations/matrix-level/matrix-level.detail';
import { MatrixLevelListComponent } from './forms/administrations/matrix-level/matrix-level.list';
import { MatrixLevelManageComponent } from './forms/administrations/matrix-level/matrix-level.manage';
import { MatrixLevelHttpService } from './services/http/administration/matrix-level.http.service';
import { MatrixValidateReadOnlyListComponent } from './forms/administrations/matrix/matrix-read-only.list';
import { EntityFileValidateAssetDownloadComponent } from './forms/common/entity-file-validate-asset-download';
import { TaxManageComponent } from './forms/assets/taxs/tax.manage';
import { TaxListComponent } from './forms/assets/taxs/tax.list';
import { TaxDetailComponent } from './forms/assets/taxs/tax.detail';
import { TaxHttpService } from './services/http/assets/tax.http.service';
import { AcquisitionValidateReadOnlyOfferListComponent } from './forms/assets/assets/acquisition-validate-read-only.list';
import { OrderMaterialDetailComponent } from './forms/administrations/order-materials/order-material.detail';
import { OrderMaterialListComponent } from './forms/administrations/order-materials/order-material.list';
import { OrderMaterialManageComponent } from './forms/administrations/order-materials/order-material.manage';
import { OrderMaterialHttpService } from './services/http/administration/order-material.http.service';
import { OrderMaterialOrderValidateListComponent } from './forms/administrations/order-materials/order-material-order-validate.list';
import { AssetGNFRManageComponent } from './forms/assets/assets/asset-reception.manage';
import { AssetRejectedManageComponent } from './forms/assets/assets/asset-rejected.manage';
import { AssetDetailUIOperationTransferComponent } from './forms/assets/assets/asset.detail-operation-transfer.ui';
import { TimelineModule } from 'primeng/timeline';
import { MessageModule } from 'primeng/message';
import { AssetDetailUIOperationCassationComponent } from './forms/assets/assets/asset.detail-operation-cassation.ui';
import {OrganizationChartModule} from 'primeng/organizationchart';
import { ErrorDetailComponent } from './forms/common/errors/error.detail';
import { ErrorListComponent } from './forms/common/errors/error.list';
import { ErrorManageComponent } from './forms/common/errors/error.manage';
import { ErrorHttpService } from './services/http/common/error.http.service';
import { AmortizationHttpService } from './services/http/sap/asset-dep-md-sync';
import { AmortizationDetailComponent } from './forms/sap/amortization/amortization.detail';
import { AmortizationListComponent } from './forms/sap/amortization/amortization.list';
import { AmortizationManageComponent } from './forms/sap/amortization/amortization.manage';
import { OfferValidateReadOnlyOfferListComponent } from './forms/assets/assets/offer-validate-read-only.list';
import { OfferEmailValidateOfferListComponent } from './forms/assets/assets/offer-email-validate.list';
import { RateDetailComponent } from './forms/assets/rates/rate.detail';
import { RateListComponent } from './forms/assets/rates/rate.list';
import { RateManageComponent } from './forms/assets/rates/rate.manage';
import { ProjectTypeHttpService } from './services/http/assets/project-type.http.service';
import { ProjectTypeDetailComponent } from './forms/assets/project-types/project-type.detail';
import { ProjectTypeListComponent } from './forms/assets/project-types/project-type.list';
import { ProjectTypeManageComponent } from './forms/assets/project-types/project-type.manage';
import { ProjectTypeDivisionDetailComponent } from './forms/administrations/project-type-division/project-type-division.detail';
import { ProjectTypeDivisionListComponent } from './forms/administrations/project-type-division/project-type-division.list';
import { ProjectTypeDivisionManageComponent } from './forms/administrations/project-type-division/project-type-division.manage';
import { ProjectTypeDivisionHttpService } from './services/http/administration/project-type-division.http.service';
import { BudgetBaseListComponent } from './forms/administrations/budget-base/budget-base.list';
import { BudgetBaseValidateManageComponent } from './forms/administrations/budget-base/budget-base-validate.manage';
import { BudgetBaseManageComponent } from './forms/administrations/budget-base/budget-base.manage';
import { BudgetBaseValidateListComponent } from './forms/administrations/budget-base/budget-base-validate.list';
import { BudgetBaseHttpService } from './services/http/administration/budget-base.http.service';
import { AssetEmployeeDetailUIComponent } from './forms/assets/assets/asset-employee.detail.ui';
import { DictionaryItemHttpService } from './services/http/administration/dictionary-item.http.service';
import { AssetEmployeeAddNewAssetListComponent } from './forms/assets/assets/asset-employee-add-new-asset.list';
import { ModelHttpService } from './services/http/assets/model.http.service';
import { ModelDetail } from './forms/assets/models/model.detail';
import { ModelList } from './forms/assets/models/model.list';
import { ModelManage } from './forms/assets/models/model.manage';
import { FileHttpService } from './services/file.service';
import { AssetWFHManageComponent } from './forms/assets/assets/asset-wfh.manage';
import { CapAmortizationManageComponent } from './forms/sap/cap-amortization/cap-amortization.manage';
import { CapAmortizationListComponent } from './forms/sap/cap-amortization/cap-amortization.list';
import { CapAmortizationDetailComponent } from './forms/sap/cap-amortization/cap-amortization.detail';
import { CapAmortizationHttpService } from './services/http/sap/asset-dep-md-cap-sync';
import { InfoManageComponent } from './forms/info/info.manage';
import { AssetOpRecoManageComponent } from './forms/assets/asset--reco-ops/asset-reco-op-manage';
import { AssetOpRecoDetailListComponent } from './forms/assets/asset--reco-ops/asset-reco-op.detail.list';
import { SignalRService } from './services/signalR.service';
import { NotifyService } from './services/notify.service';
import { BudgetMonthBaseValidateListComponent } from './forms/administrations/budget-month-base/budget-month-base-validate.list';
import { BudgetMonthBaseValidateManageComponent } from './forms/administrations/budget-month-base/budget-month-base-validate.manage';
import { BudgetMonthBaseListComponent } from './forms/administrations/budget-month-base/budget-month-base.list';
import { BudgetMonthBaseManageComponent } from './forms/administrations/budget-month-base/budget-month-base.manage';
import { BudgetMonthBaseHttpService } from './services/http/administration/budget-month-base.http.service';
import { BudgetForecastHttpService } from './services/http/administration/budget-forecast.http.service';
import { BudgetForecastManageComponent } from './forms/administrations/budget-forecast/budget-forecast.manage';
import { BudgetForecastListComponent } from './forms/administrations/budget-forecast/budget-forecast.list';
import { BudgetForecastValidateListComponent } from './forms/administrations/budget-forecast/budget-forecast-validate.list';
import { BudgetForecastValidateManageComponent } from './forms/administrations/budget-forecast/budget-forecast-validate.manage';
import { AssetDetailUIValidateInvPlusComponent } from './forms/assets/assets/asset.detail-validate-inv-plus.ui';
import { AssetServiceManageComponent } from './forms/assets/assets/asset-inv-plus.manage';
import { LocalizedNumericInputDirective } from './forms/common/directives/mat-input-commified.directive.';
import { OrderTypeHttpService } from './services/http/orders/order-type.http.service';
import { OrderTypeDetailComponent } from './forms/orders/order-type/order-type.detail';
import { OrderTypeListComponent } from './forms/orders/order-type/order-type.list';
import { OrderTypeManageComponent } from './forms/orders/order-type/order-type.manage';
import { BudgetBaseDetailUIComponent } from './forms/administrations/budget-base/budget-base.detail.ui';
import { AppStateDetailComponent } from './forms/assets/app-states/app-state.detail';
import { AppStateListComponent } from './forms/assets/app-states/app-state.list';
import { AppStateManageComponent } from './forms/assets/app-states/app-state.manage';
import { AppStateDropDownListComponent } from './forms/assets/app-states/app-state.drop-down.list';
import { AssetDetailUIOperationStornoComponent } from './forms/assets/assets/asset.detail-operation-storno.ui';
import { TwoDigitDecimaNumberDirective } from './forms/common/directives/two-digit-decima-number.directive';
import { RequestNotValidatePageComponent } from './forms/common/request-not-validate-page';
import { CreateAssetSAPDetailComponent } from './forms/sap/create-asset-sap/create-asset-sap.detail';
import { CreateAssetSAPListComponent } from './forms/sap/create-asset-sap/create-asset-sap.list';
import { CreateAssetSAPManageComponent } from './forms/sap/create-asset-sap/create-asset-sap.manage';
import { CreateAssetSAPHttpService } from './services/http/sap/create-asset-sap-http.service';
import { AssetDetailUIAcquisitionOperationStornoComponent } from './forms/assets/assets/asset.detail-acquisition-operation-storno.ui';
import { EmailStatusDstEmployeeValidatePageComponent } from './forms/common/email-status-dst-employee-validate-page';
import { EmailStatusDstEmployeeAllocateApprovePageComponent } from './forms/common/email-status-dst-employee-allocate-approve-page';
import { EmailStatusDstEmployeeAllocateRejectPageComponent } from './forms/common/email-status-dst-employee-allocate-reject-page';
import { EmailStatusDstEmployeeRejectPageComponent } from './forms/common/email-status-dst-employee-reject-page';
import { AssetITDeleteDataManageComponent } from './forms/assets/assets/asset-stock-it.manage';
import { AssetDetailChangeUIComponent } from './forms/assets/assets/asset.detail-change.ui';
import { AssetEmployeePersonalDetailUIComponent } from './forms/assets/assets/asset-employee-personal.detail.ui';
import { AssetEmployeePersonalValidateListComponent } from './forms/assets/assets/asset-employee-personal-validate.list';
import { AquisitionAssetSAPListComponent } from './forms/sap/acquisition-asset-sap/acquisition-asset-sap.list';
import { AquisitionAssetSAPDetailComponent } from './forms/sap/acquisition-asset-sap/acquisition-asset-sap.detail';
import { AquisitionAssetSAPManageComponent } from './forms/sap/acquisition-asset-sap/acquisition-asset-sap.manage';
import { AquisitionAssetSAPHttpService } from './services/http/sap/aquisition-asset-sap-http.service';
import { AssetChangeSAPManageComponent } from './forms/sap/asset-change-sap/asset-change-sap.manage';
import { AssetChangeSAPListComponent } from './forms/sap/asset-change-sap/asset-change-sap.list';
import { AssetChangeSAPDetailComponent } from './forms/sap/asset-change-sap/asset-change-sap.detail';
import { AssetInvMinusSAPManageComponent } from './forms/sap/asset-inv-minus-sap/asset-inv-minus-sap.manage';
import { AssetInvMinusSAPListComponent } from './forms/sap/asset-inv-minus-sap/asset-inv-minus-sap.list';
import { AssetInvMinusSAPDetailComponent } from './forms/sap/asset-inv-minus-sap/asset-inv-minus-sap.detail';
import { AssetInvPlusSAPManageComponent } from './forms/sap/asset-inv-plus-sap/asset-inv-plus-sap.manage';
import { AssetInvPlusSAPListComponent } from './forms/sap/asset-inv-plus-sap/asset-inv-plus-sap.list';
import { AssetInvPlusSAPDetailComponent } from './forms/sap/asset-inv-plus-sap/asset-inv-plus-sap.detail';
import { RetireAssetSAPManageComponent } from './forms/sap/retire-asset-sap/retire-asset-sap.manage';
import { RetireAssetSAPListComponent } from './forms/sap/retire-asset-sap/retire-asset-sap.list';
import { RetireAssetSAPDetailComponent } from './forms/sap/retire-asset-sap/retire-asset-sap.detail';
import { TransferAssetSAPManageComponent } from './forms/sap/transfer-asset-sap/transfer-asset-sap.manage';
import { TransferAssetSAPListComponent } from './forms/sap/transfer-asset-sap/transfer-asset-sap.list';
import { TransferAssetSAPDetailComponent } from './forms/sap/transfer-asset-sap/transfer-asset-sap.detail';
import { TransferInStockSAPManageComponent } from './forms/sap/transfer-in-stock-sap/transfer-in-stock-sap.manage';
import { TransferInStockSAPListComponent } from './forms/sap/transfer-in-stock-sap/transfer-in-stock-sap.list';
import { TransferInStockSAPDetailComponent } from './forms/sap/transfer-in-stock-sap/transfer-in-stock-sap.detail';
import { AssetChangeSAPHttpService } from './services/http/sap/asset-change-sap-http.service';
import { AssetInvMinusSAPHttpService } from './services/http/sap/asset-inv-minus-sap-http.service';
import { AssetInvPlusSAPHttpService } from './services/http/sap/asset-inv-plus-sap-http.service';
import { RetireAssetSAPHttpService } from './services/http/sap/retire-asset-sap-http.service';
import { TransferInStockSAPHttpService } from './services/http/sap/transfer-in-stock-sap-http.service';
import { TransferAssetSAPHttpService } from './services/http/sap/transfer-asset-sap-http.service';
import { AssetDetailUIOperationCloneTransferComponent } from './forms/assets/assets/asset.detail-operation-clone-transfer.ui';
import { EntityTypeDetailComponent } from './forms/administrations/entity-type/entity-type.detail';
import { EntityTypeListComponent } from './forms/administrations/entity-type/entity-type.list';
import { EntityTypeHttpService } from './services/http/administration/entity-type.http.service';
import { EntityTypeManageComponent } from './forms/administrations/entity-type/entity-type.manage';
import { UploadModalComponent } from './forms/common/upload-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AppProgressComponent } from './views/progress/progress.component';
import { DndDirective } from './directives/dnd.directive';
import { BudgetBaseAddDetailUIComponent } from './forms/administrations/budget-base/budget-base.add-detail.ui';
import { BudgetBaseOpDetailListComponent } from './forms/administrations/budget-base-ops/budget-base-op.detail.list';
import { BudgetBaseOpManageComponent } from './forms/administrations/budget-base-ops/budget-base-op-manage';
import { BudgetBaseOpHttpService } from './services/http/administration/budget-base-op.http.service';
import { MatButtonModule } from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { AgGridModule } from 'ag-grid-angular';
import { AssetStockITToValidateManageComponent } from './forms/assets/assets/asset-stock-it-to-validate.manage';
import { AssetAccountingMFManageComponent } from './forms/assets/assets/asset-stock-it-to-validate-employee.manage';
import { UploadRequestModalComponent } from './forms/common/upload-request-modal.component';
import { AssetExtraseToValidateManageComponent } from './forms/assets/assets/asset-stock-it-mfx.manage';
import { UploadOrderModalComponent } from './forms/common/upload-order-modal.component';
import { StockOrderListComponent } from './forms/administrations/stocks/stock-order.list';
import { OfferStockValidateOfferListComponent } from './forms/assets/assets/offer-stock-validate.list';
import { OfferTypeDetailComponent } from './forms/offers/offer-type/offer-type.detail';
import { OfferTypeListComponent } from './forms/offers/offer-type/offer-type.list';
import { OfferTypeManageComponent } from './forms/offers/offer-type/offer-type.manage';
import { OfferTypeHttpService } from './services/http/offfers/offer-type.http.service';
import { OrderEditDetailUIComponent } from './forms/administrations/order/order-edit.detail.ui';
import { DashboardAssetComponent } from './views/dashboard/Asset/dashboard-asset.component';
import { BudgetForecastUIListComponent } from './forms/administrations/budget-forecast/budget-forecast.ui.list';
import { UploadBuyModalComponent } from './forms/common/upload-buy-modal.component';
// import {NgApexchartsModule} from "ng-apexcharts";
import {TooltipModule} from 'primeng/tooltip';
import { RouteChildDetailComponent } from './forms/common/route-child-definition/route-child.detail';
import { ColumnDefinitionManageComponent } from './forms/common/column-definition/column-definition.manage';
import { ColumnDefinitionListComponent } from './forms/common/column-definition/column-definition.list';
import { ColumnDefinitionDetail } from './forms/common/column-definition/column-definition.detail';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { AssetEmployeeManagerDetailUIComponent } from './forms/assets/assets/asset-employee-manager.detail.ui';
import { AssetErrorManageComponent } from './forms/assets/assets/asset-error.manage';
import { AssetDetailErorrUIAddComponent } from './forms/assets/assets/asset.detail-error-add.ui';
import { AssetEmployeeTransferFromDetailUIComponent } from './forms/assets/assets/asset-employee-transfer-from.detail.ui';
import { ManagerDetailComponent } from './forms/administrations/managers/manager.detail';
import { ManagerListComponent } from './forms/administrations/managers/manager.list';
import { ManagerManageComponent } from './forms/administrations/managers/manager.manage';
import { ManagerDetailHttpService } from './services/http/administration/manager-detail.http.service';
import { ManagerHttpService } from './services/http/administration/manager.http.service';
import { EmployeeManagerHttpService } from './services/http/administration/employee-manager.http.service';
import { EmployeeManagerList } from './forms/administrations/employee-managers/employee-manager.list';
import { ConfirmDialogModule} from 'primeng/confirmdialog';
import { AssetEmployeeAllocateApproveDetailUIComponent } from './forms/assets/assets/asset-employee-allocate-approve.detail.ui';
import { EmployeeManagerManage } from './forms/administrations/employee-managers/employee-manager.manage';
import { EmployeeManagerDetail } from './forms/administrations/employee-managers/employee-manager.detail';
import { ConfirmEmailPage } from './views/reset-password/confirm-email-page';
import { ErrorConfirmEmailPage } from './views/reset-password/error-confirm-email-page';
import { ErrorResetEmailPage } from './views/reset-password/error-reset-email-page';
import { PasswordEmailReset } from './views/reset-password/password-email-reset';
import { EmployeeCompanyManage } from './forms/administrations/employee-companies/employee-company.manage';
import { EmployeeCompanyList } from './forms/administrations/employee-companies/employee-company.list';
import { EmployeeCompanyDetail } from './forms/administrations/employee-companies/employee-company.detail';
import { EmployeeCompanyHttpService } from './services/http/administration/employee-company.http.service';
import { EmployeeAllocateManageComponent } from './forms/administrations/employee-allocates/employee-allocate.manage';
import { EmployeeAllocateListComponent } from './forms/administrations/employee-allocates/employee-allocate.list';
import { EmployeeAllocateDetailComponent } from './forms/administrations/employee-allocates/employee-allocate.detail';
import { AssetEmployeePersonalTransferFromListComponent } from './forms/assets/assets/asset-employee-personal-transfer-from.list';
import { AssetEmployeePersonalWFHManagerListComponent } from './forms/assets/assets/asset-employee-personal-wfh-manager.list';
import { AssetExtraseViewComponent } from './forms/assets/assets/asset-extrase.view';
import { AssetBuyHistoryManageComponent } from './forms/assets/assets/asset-suspended-history.manage';
import { AssetOpHistoryManageComponent } from './forms/assets/asset-history-ops/asset-op-history-manage';
import { AssetOpHistoryDetailListComponent } from './forms/assets/asset-history-ops/asset-op-history.detail.list';
import { AssetAccountingMFViewComponent } from './forms/assets/assets/asset-accounting-mf.view';
import { EntityFileAccountingMFDelete } from './forms/common/entity-file-accounting-mf-delete';
import { AssetOpTempManageComponent } from './forms/assets/asset-temp-ops/asset-op-temp-manage';
import { AssetOpTempDetailListComponent } from './forms/assets/asset-temp-ops/asset-op-temp.detail.list';
import { AssetMarketPlaceManageComponent } from './forms/assets/assets/asset-marketplace.manage';
import { AssetListMarketPlaceComponent } from './forms/assets/assets/asset.list-marketplace';
import { UploadMarketModalComponent } from './forms/common/upload-market-modal.component';
import { CarouselComponent, CarouselModule } from 'ngx-bootstrap/carousel';
import { AssetMarketPlaceDetailUIComponent } from './forms/assets/assets/asset.market-place-detail.ui';
import { UploadMarketBuyModalComponent } from './forms/common/upload-market-buy-modal.component';
import { AssetServiceOpHistoryManageComponent } from './forms/assets/asset-service-history-ops/asset-service-history-ops-manage';
import { AssetServiceOpHistoryDetailListComponent } from './forms/assets/asset-service-history-ops/asset-service-history-ops.detail.list';
import { EmployeeErrorManageComponent } from './forms/administrations/employee-errors/employee-error.manage';
import { EmployeeErrorListComponent } from './forms/administrations/employee-errors/employee-error.list';
import { EmployeeErrorDetailComponent } from './forms/administrations/employee-errors/employee-error.detail';
import { EmployeeErrorDetailUIComponent } from './forms/administrations/employee-errors/employee-error.detail.ui';
import { ExportService } from './services/export.service';
import { AuditAssetListComponent } from './forms/assets/assets/audit.asset.list';
import { AuditHttpService } from './services/http/administration/audit.http.service';
import { AuditemployeeListComponent } from './forms/assets/assets/audit.employee.list';
import { AuditSaleListComponent } from './forms/assets/assets/audit.sale.list';
import { AssetExtraseToValidateViewDetail } from './forms/assets/assets/asset-extrasetovalidate.view';

export function getToken() {
  const token = localStorage.getItem('id_token');
  alert(token);
  return token;
}

const JWT_Module_Options: JwtModuleOptions = {
  config: {
      tokenGetter: getToken,
      // whitelistedDomains: '1'
  }
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AgGridModule.withComponents([]),
    AppHeaderModule,
    AppSidebarModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    TooltipModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    ToastrModule.forRoot({tapToDismiss: true, closeButton: true, extendedTimeOut: 10000, progressBar: true, preventDuplicates: true, countDuplicates: true, positionClass: 'toast-bottom-right', newestOnTop: true}),
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DropdownModule,
    TabViewModule,
    TimelineModule,
    MessageModule,
    OrganizationChartModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    CheckboxModule,
    ToggleButtonModule,
    OverlayPanelModule,
    PickListModule,
    SplitButtonModule,
    ProgressBarModule,
    HttpClientModule,
    AngularTreeGridModule,
    CarouselModule.forRoot(),
    // NgApexchartsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: getToken,
    //     allowedDomains: ['localhost:7000', 'localhost:4200'],
    //     // disallowedRoutes: ['http://example.com/examplebadroute/'],
    //     // authScheme: 'Basic',
    //     headerName: 'Authorization',
    //   },
    // }),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    CollapseModule.forRoot(),

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    AssetListComponent,
    AssetDepManageComponent,
    AssetManageComponent,
    AssetErrorManageComponent,
    AssetScrapManageComponent,
    AssetSoldManageComponent,
    AssetCFOManageComponent,
    AssetBuyManageComponent,
    AssetToValidateManageComponent,
    AssetGNFRManageComponent,
    AssetRejectedManageComponent,
    AssetWFHManageComponent,
    AssetRecoManageComponent,
    AssetAllListComponent,
    AssetRecoListComponent,
    AssetRecoAllListComponent,
    AssetDetailUIComponent,
    AssetDetailUIAddComponent,
    AssetStockDetailUIAddComponent,
    AssetDetailUIOperationTransferComponent,
    AssetDetailUIOperationCassationComponent,
    DepartmentListComponent,
    DepartmentDetailComponent,
    DepartmentManageComponent,
    EmployeeListComponent,
    EmployeeDetailComponent,
    EmployeeManageComponent,
    EmployeeDetailUIComponent,
    RegionListComponent,
    AdmCenterListComponent,
    AdmCenterDetailComponent,
    AdmCenterManageComponent,
    LocationListComponent,
    LocationDetailComponent,
    LocationManageComponent,
    RoomListComponent,
    RoomDetailComponent,
    RoomManageComponent,
    AdministrationListComponent,
    AssetCategoryListComponent,
    AssetCategoryDetailComponent,
    AssetCategoryManageComponent,
    UomListComponent,
    UomDetailComponent,
    UomManageComponent,
    CompanyListComponent,
    CompanyDetailComponent,
    CompanyManageComponent,
    AssetClassListComponent,
    AssetTypeListComponent,
    AssetTypeDetailComponent,
    AssetTypeManageComponent,
    DivisionListComponent,
    PartnerListComponent,
    PartnerDetailComponent,
    PartnerManageComponent,
    CostCenterListComponent,
    CostCenterDetailComponent,
    CostCenterManageComponent,
    DimensionListComponent,
    DictionaryItemListComponent,
    DictionaryItemDetailComponent,
    CountryListComponent,
    CountryManageComponent,
    CountryDetailComponent,
    CountyManageComponent,
    CountyListComponent,
    CountyDetailComponent,
    CityListComponent,
    CityManageComponent,
    CityDetailComponent,
    FieldByNamePipe,
    FieldByColumnDefinitionPipe,
    ConfigValuesList,
    ConfigValuesDetail,
    ConfigValuesManage,
    TableDefinitionManageComponent,
    TableDefinitionListComponent,
    ColumnDefinitionListComponent,
    ColumnDefinitionDetail,
    InventoryManage,
    InventoryList,
    InventoryDetail,
    AccMonthListComponent,
    AccMonthDetailComponent,
    AccMonthManageComponent,
    InvStateList,
    InvStateDetail,
    InvStateManage,
    DictionaryItemManageComponent,
    DictionaryItemDetailComponent,
    DictionaryItemListComponent,
    DictionaryTypeManageComponent,
    DictionaryTypeDetailComponent,
    DictionaryTypeListComponent,
    AdministrationListComponent,
    AdministrationManageComponent,
    AdministrationDetailComponent,
    EntityFileListComponent,
    AssetOpDetailListComponent,
    AssetInventoryReportComponent,
    AssetInventoryManageComponent,
    AssetInvTempDetailListComponent,
    AssetInvFullDetailListComponent,
    UserListComponent,
    AssetEntityListComponent,
    IdentityManage,
    RoleList,
    AssetStateManageComponent,
    AssetStateListComponent,
    AssetStateDetailComponent,
    MasterTypeManageComponent,
    MasterTypeListComponent,
    MasterTypeDetailComponent,
    BudgetValidateList,
    BudgetValidateManage,
    BudgetDetailUI,
    AccountList,
    AcquisitionValidateReadOnlyOfferListComponent,
    InterCompanyManageComponent,
    InterCompanyDetailComponent,
    InterCompanyListComponent,
    InsuranceCategoryDetail,
    InsuranceCategoryList,
    InsuranceCategoryManage,
    ResetPasswordComponent,
    ConfirmEmailPage,
    ErrorConfirmEmailPage,
    ErrorResetEmailPage,
    PasswordEmailReset,
    ProjectManage,
    ProjectList,
    ProjectDetail,
    SubTypeList,
    SubTypeManage,
    SubTypeDetail,
    TypeManage,
    TypeDetail,
    TypeList,
    EntityFileUploadComponent,
    EntityFileDownloadComponent,
    BudgetOpDetailList,
    BudgetOpManage,
    BudgetManage,
    BudgetList,
    OfferValidateList,
    OfferValidateManage,
    OfferDetailUIComponent,
    OfferOpDetailList,
    SanitizeHtml,
    OfferManage,
    OfferList,
    OfferValidateReadOnlyOfferListComponent,
    OfferOpManage,
    OrderValidateList,
    OrderValidateManage,
    OrderDetailUIComponent,
    OrderOpDetailList,
    OrderList,
    OrderManageComponent,
    OrderOpDetailList,
    OrderOpManage,
    EmailManagerManageComponent,
    EmailManagerList,
    EmailManagerDetail,
    EmailTypeDetail,
    EmailTypeList,
    EmailTypeManage,
    AssetComponentDetail,
    AssetComponentManage,
    AssetComponentList,
    AssetComponentOpDetailList,
    AssetEmployeeValidateList,
    AssetInventoryEmployeeValidateManage,
    AssetInventoryEmailManage,
    AssetInvEmailList,
    AssetInvDetailListComponent,
    AssetDepDetailListComponent,
    AssetClassManageComponent,
    AssetClassDetailComponent,
    AssetOpManageComponent,
    DocumentTypeDropDownListComponent,
    DimensionManageComponent,
    DimensionDetailComponent,
    DimensionDetailComponent,
    PaginationsComponent,
    DashboardBudgetComponent,
    DashboardAssetComponent,
    RouteManageComponent,
    RouteListComponent,
    RouteDetailComponent,
    RouteChildManageComponent,
    RouteChildListComponent,
    RouteChildDetailComponent,
    HighlightPipe,
    HideIfNotUnauthorizedDirective,
    DisableIfNotUnauthorizedDirective,
    PermissionTypeManage,
    PermissionTypeList,
    PermissionTypeDetail,
    PermissionManage,
    PermissionList,
    PermissionDetail,
    PermissionRoleManage,
    PermissionRoleList,
    PermissionRoleDetail,
    DocumentTypeDropDownListComponent,
    OperationDetail,
    DashboardOfferComponent,
    DashboardOrderComponent,
    DashboardInventoryComponent,
    DashboardInventoryMarkComponent,
    AssetNatureManageComponent,
    AssetNatureListComponent,
    AssetNatureDetailComponent,
    ColumnDefinitionManageComponent,
    TableDefinitionDetailComponent,
    BrandDetail,
    BrandList,
    BrandManage,
    BadgeManage,
    BadgeDetail,
    BadgeList,
    AccountManage,
    AccountList,
    AccountDetail,
    ExpAccountManage,
    ExpAccountList,
    ExpAccountDetail,
    ArticleManage,
    ArticleList,
    ArticleDetail,
    DivisionManageComponent,
    DivisionListComponent,
    DivisionDetailComponent,
    BudgetManagerManage,
    BudgetManagerList,
    BudgetManagerDetail,
    MaterialManageComponent,
    MaterialList,
    MaterialDetailComponent,
    RegionManageComponent,
    RegionListComponent,
    RegionDetailComponent,
    EmployeeCostCenterManage,
    EmployeeCostCenterList,
    EmployeeCostCenterDetail,
    EmployeeCompanyManage,
    EmployeeCompanyList,
    EmployeeCompanyDetail,
    DashboardEntityListComponent,
    DashboardListComponent,
    DeviceDetail,
    DeviceList,
    DeviceManage,
    DeviceTypeDetail,
    DeviceTypeList,
    DeviceTypeManage,
    ActivityManage,
    ActivityList,
    ActivityDetail,
    TaxManageComponent,
    TaxListComponent,
    TaxDetailComponent,
    DashboardBudgetAssetComponent,
    BudgetList,
    BudgetPrimeNgList,
    EmailManagerUiList,
    CategoryDetailComponent,
    CategoryListComponent,
    CategoryManageComponent,
    InterCompanyENManageComponent,
    InterCompanyENListComponent,
    InterCompanyENDetailComponent,
    CategoryENDetailComponent,
    CategoryENListComponent,
    CategoryENManageComponent,
    SubCategoryENDetailComponent,
    SubCategoryENListComponent,
    SubCategoryENManageComponent,
    SubCategoryDetailComponent,
    SubCategoryListComponent,
    SubCategoryManageComponent,
    ContractDetailUIComponent,
    ContractListComponent,
    ContractManageComponent,
    ContractOpDetailListComponent,
    ContractOpManageComponent,
    OfferMaterialManageComponent,
    OfferMaterialListComponent,
    OfferMaterialDetailComponent,
    OrderMaterialManageComponent,
    OrderMaterialListComponent,
    OrderMaterialDetailComponent,
    StockDetailComponent,
    StockListComponent,
    StockOrderListComponent,
    StockManageComponent,
    OrderValidateOfferListComponent,
    OfferValidateOfferListComponent,
    OfferStockValidateOfferListComponent,
    OfferMaterialOrderValidateListComponent,
    OrderMaterialOrderValidateListComponent,
    OrderValidateReadOnlyOfferListComponent,
    AddAssetValidationListComponent,
    RequestManageComponent,
    RequestList,
    RequestDetailUIComponent,
    RequestOpManage,
    RequestOpDetailList,
    AssetDetailUIValidateComponent,
    AssetDetailUIComponent,
    AccountancyManageComponent,
    AccountancyListComponent,
    AccountancyDetailComponent,
    MatrixDetailComponent,
    MatrixListComponent,
    MatrixManageComponent,
    AreaDetailComponent,
    AreaListComponent,
    AreaManageComponent,
    LevelDetailComponent,
    LevelDetailComponent,
    LevelListComponent,
    LevelManageComponent,
    MatrixLevelDetailComponent,
    MatrixLevelListComponent,
    MatrixLevelManageComponent,
    MatrixValidateReadOnlyListComponent,
    EntityFileValidateAssetDownloadComponent,
    OfferEmailValidateOfferListComponent,
    FileUploadComponent,
    ErrorDetailComponent,
    ErrorListComponent,
    ErrorManageComponent,
    AmortizationDetailComponent,
    AmortizationListComponent,
    AmortizationManageComponent,
    CapAmortizationDetailComponent,
    CapAmortizationListComponent,
    CapAmortizationManageComponent,
    RateDetailComponent,
    RateListComponent,
    RateManageComponent,
    ProjectTypeDetailComponent,
    ProjectTypeListComponent,
    ProjectTypeManageComponent,
    ProjectTypeDivisionDetailComponent,
    ProjectTypeDivisionListComponent,
    ProjectTypeDivisionManageComponent,
    BudgetBaseManageComponent,
    BudgetBaseListComponent,
    BudgetBaseValidateManageComponent,
    BudgetBaseValidateListComponent,
    BudgetMonthBaseManageComponent,
    BudgetMonthBaseListComponent,
    BudgetMonthBaseValidateManageComponent,
    BudgetMonthBaseValidateListComponent,
    BudgetForecastManageComponent,
    BudgetForecastListComponent,
    BudgetForecastValidateManageComponent,
    BudgetForecastValidateListComponent,
    BudgetForecastUIListComponent,
    AssetEmployeeDetailUIComponent,
    AssetEmployeeAddNewAssetListComponent,
    ModelDetail,
    ModelList,
    ModelManage,
    InfoManageComponent,
    AssetOpRecoManageComponent,
    AssetOpRecoDetailListComponent,
    AssetDetailUIValidateInvPlusComponent,
    AssetServiceManageComponent,
    OrderTypeDetailComponent,
    OrderTypeListComponent,
    OrderTypeManageComponent,
    OfferTypeDetailComponent,
    OfferTypeListComponent,
    OfferTypeManageComponent,
    BudgetBaseDetailUIComponent,
    AppStateDetailComponent,
    AppStateListComponent,
    AppStateManageComponent,
    AppStateDropDownListComponent,
    AssetDetailUIOperationStornoComponent,
    TwoDigitDecimaNumberDirective,
    RequestNotValidatePageComponent,
    CreateAssetSAPDetailComponent,
    CreateAssetSAPListComponent,
    CreateAssetSAPManageComponent,
    AssetDetailUIAcquisitionOperationStornoComponent,
    EmailStatusDstEmployeeValidatePageComponent,
    EmailStatusDstEmployeeAllocateApprovePageComponent,
    EmailStatusDstEmployeeRejectPageComponent,
    EmailStatusDstEmployeeAllocateRejectPageComponent,
    AssetITDeleteDataManageComponent,
    AssetDetailChangeUIComponent,
    AssetEmployeePersonalDetailUIComponent,
    AssetEmployeePersonalValidateListComponent,
    AquisitionAssetSAPManageComponent,
    AquisitionAssetSAPListComponent,
    AquisitionAssetSAPDetailComponent,
    AssetChangeSAPManageComponent,
    AssetChangeSAPListComponent,
    AssetChangeSAPDetailComponent,
    AssetInvMinusSAPManageComponent,
    AssetInvMinusSAPListComponent,
    AssetInvMinusSAPDetailComponent,
    AssetInvPlusSAPManageComponent,
    AssetInvPlusSAPListComponent,
    AssetInvPlusSAPDetailComponent,
    RetireAssetSAPManageComponent,
    RetireAssetSAPListComponent,
    RetireAssetSAPDetailComponent,
    TransferAssetSAPManageComponent,
    TransferAssetSAPListComponent,
    TransferAssetSAPDetailComponent,
    TransferInStockSAPManageComponent,
    TransferInStockSAPListComponent,
    TransferInStockSAPDetailComponent,
    AssetDetailUIOperationCloneTransferComponent,
    EntityTypeDetailComponent,
    EntityTypeListComponent,
    EntityTypeManageComponent,
    UploadModalComponent,
    UploadRequestModalComponent,
    UploadOrderModalComponent,
    UploadBuyModalComponent,
    AppProgressComponent,
    DndDirective,
    BudgetBaseAddDetailUIComponent,
    BudgetBaseOpManageComponent,
    BudgetBaseOpDetailListComponent,
    AssetStockITToValidateManageComponent,
    AssetAccountingMFManageComponent,
    AssetExtraseToValidateManageComponent,
    OrderEditDetailUIComponent,
    AssetEmployeeManagerDetailUIComponent,
    AssetDetailErorrUIAddComponent,
    AssetEmployeeTransferFromDetailUIComponent,
    ManagerDetailComponent,
    ManagerListComponent,
    ManagerManageComponent,
    EmployeeManagerList,
    AssetEmployeeAllocateApproveDetailUIComponent,
    EmployeeManagerManage,
    EmployeeManagerList,
    EmployeeManagerDetail,
    EmployeeAllocateManageComponent,
    EmployeeAllocateListComponent,
    EmployeeAllocateDetailComponent,
    AssetEmployeePersonalTransferFromListComponent,
    AssetEmployeePersonalWFHManagerListComponent,
    AssetExtraseViewComponent,
    AssetExtraseToValidateViewDetail,
    AssetAccountingMFViewComponent,
    AssetBuyHistoryManageComponent,
    AssetOpHistoryManageComponent,
    AssetOpHistoryDetailListComponent,
    EntityFileAccountingMFDelete,
    AssetOpTempManageComponent,
    AssetOpTempDetailListComponent,
    AssetMarketPlaceManageComponent,
    AssetListMarketPlaceComponent,
    UploadMarketModalComponent,
    AssetMarketPlaceDetailUIComponent,
    UploadMarketBuyModalComponent,
    AssetServiceOpHistoryDetailListComponent,
    AssetServiceOpHistoryManageComponent,
    EmployeeErrorManageComponent,
    EmployeeErrorListComponent,
    EmployeeErrorDetailComponent,
    EmployeeErrorDetailUIComponent,
    AuditAssetListComponent,
    AuditemployeeListComponent,
    AuditSaleListComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
  { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  JwtHelperService,
  AuthGuard,
  AuthorizationService,
  ActivityHttpService,
  AccMonthHttpService,
  BaThemeConfigProvider,
  AppStateHttpService,
  AccountHttpService,
  SubTypeHttpService,
  TypeHttpService,
  MasterTypeHttpService,
  BrandHttpService,
  BudgetBaseOpHttpService,
  ModelHttpService,
  FileHttpService,
  ProjectHttpService,
  InterCompanyHttpService,
  BudgetOpHttpService,
  CostCenterHttpService,
  AssetComponentOpHttpService,
  AquisitionAssetSAPHttpService,
  AssetChangeSAPHttpService,
  AssetInvMinusSAPHttpService,
  AssetInvPlusSAPHttpService,
  RetireAssetSAPHttpService,
  TransferAssetSAPHttpService,
  TransferInStockSAPHttpService,
  AssetMemoryService,
  AssetHttpService,
  AssetOpHttpService,
  AssetTypeHttpService,
  AssetCategoryHttpService,
  AdmCenterHttpService,
  InventoryHttpService,
  ManagerHttpService,
  ManagerDetailHttpService,
  CityHttpService,
  ConfirmationService,
  DimensionHttpService,
  RegionHttpService,
  RoomDetailHttpService,
  CompanyHttpService,
  EntityTypeHttpService,
  InvStateHttpService,
  InsuranceCategoryHttpService,
  DocumentTypeHttpService,
  DictionaryItemHttpService,
  AssetComponentDetailHttpService,
  EmployeeManagerHttpService,
  AdministrationHttpService,
  AssetNatureHttpService,
  NotificationService,
  BudgetHttpService,
  OfferHttpService,
  OrderHttpService,
  DocumentHttpService,
  ContractHttpService,
  ContractOpHttpService,
  ErrorHttpService,
  AmortizationHttpService,
  CapAmortizationHttpService,
  TaxHttpService,
  ProjectTypeHttpService,
  StockHttpService,
  BudgetBaseHttpService,
  BudgetMonthBaseHttpService,
  BudgetForecastHttpService,
  CreateAssetSAPHttpService,
  DatePipe,
  DecimalPipe,
  BoolPipe,
  BoolPipeState,
  BoolPipeInventory,
  MergePipe,
  MergeRoom,
  HighlightPipe,
  CarouselComponent,
  ActiveRowPipe,
  LocalizedNumericInputDirective,
  AuthenticationService,
  BsModalService,
  BsModalRef,
  ConfigValuesHttpService,
  ColumnDefinitionHttpService,
  SignalRService,
  NotifyService,
  CountyHttpService,
  CountryHttpService,
  UomHttpService,
  DivisionHttpService,
  DepartmentHttpService,
  EmployeeDetailHttpService,
  LocationHttpService,
  EmailManagerHttpService,
  EmailManagerDetailHttpService,
  EmailTypeHttpService,
  EmployeeHttpService,
  MatrixLevelHttpService,
  AreaHttpService,
  LevelHttpService,
  OfferMaterialHttpService,
  OrderMaterialHttpService,
  SubTypeHttpService,
  EntityFileHttpService,
  AccountancyHttpService,
  AssetComponentHttpService,
  PermissionTypeHttpService,
  PermissionHttpService,
  PermissionRoleHttpService,
  MatrixHttpService,
  ProjectTypeDivisionHttpService,
  RequestHttpService,
  RequestOpHttpService,
  RoleService,
  RouteHttpService,
  RouteChildHttpService,
  DashboardHttpService,
  IdentityService,
  BadgeHttpService,
  ExpAccountHttpService,
  ArticleHttpService,
  BudgetManagerHttpService,
  MaterialHttpService,
  EmployeeCostCenterHttpService,
  EmployeeCompanyHttpService,
  DeviceHttpService,
  DeviceTypeHttpService,
  DeviceDetailHttpService,
  CategoryHttpService,
  CategoryENHttpService,
  InterCompanyENHttpService,
  SubCategoryENHttpService,
  SubCategoryHttpService,
  OrderTypeHttpService,
  OfferTypeHttpService,
  RateHttpService,
  MessageService,
  TableDefinitionHttpService,
  FieldByColumnDefinitionPipe,
  ExportService,
  AuditHttpService
],
  exports: [PaginationComponent, HighlightPipe, HideIfNotUnauthorizedDirective, DisableIfNotUnauthorizedDirective],
  bootstrap: [ AppComponent ]
})

export class AppModule { }
