// import { Injectable } from '@angular/core';
import { AssetCategory } from '../../../model/api/assets/asset-category';
import { GenericHttpService } from '../generic.http.service';
// import { AuthHttp } from 'angular2-jwt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AssetCategoryHttpService extends GenericHttpService<AssetCategory, number> {
    constructor(public http: HttpClient) {
        super(http, "", "assetcategories");
    }
}