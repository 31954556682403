export class RequestSave {
    id: number;
    budgetBaseId: number;
    budgetForecastId: number;
    costCenterId: number;
    employeeId: number;
    info: string;
    validated: boolean;
    endDate: Date;
    budgetValueNeed: number;
    startAccMonthId: number;

}
