import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RetireAssetSAP } from '../../../model/api/sap/retire-asset-sap';
import { GenericDetail } from '../../generic/generic.detail';

@Component({
    selector: 'app-retire-asset-sap-detail',
    templateUrl: 'retire-asset-sap.detail.html'
})
export class RetireAssetSAPDetailComponent extends GenericDetail<RetireAssetSAP, number> {

    @ViewChild('detailForm') detailForm: FormGroup;

    setItemDefaultValues() {
        this.item = new RetireAssetSAP();
    }

    public resetForm() {
        this.detailForm.reset();
    }
}
