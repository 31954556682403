import { Component, ElementRef } from '@angular/core';
import { GenericTableList } from '../../generic/generic.table.list';
import * as _ from 'underscore';
import { AssetDepTotal } from '../../../model/api/assets/asset-dep-total';
import { AppData } from '../../../app-data';
import { Param } from '../../../model/common/param';
import { GenericPrimeNgDataTableList } from '../../generic/generic.prime-ng-data-table.list';
import { ExportService } from '../../../services/export.service';

@Component({
    selector: 'app-asset-employee-personal-wfh-manager-list',
    //templateUrl: '../../assets/assets/asset-employee-personal-wfh-manager.list.html',
    templateUrl: '../../generic/generic.prime-ng-data-table.list.html',
    styleUrls: ['./asset-employee-personal-wfh-manager.list.scss'],
})
//export class AssetEmployeePersonalWFHManagerListComponent extends GenericTableList<any, number> {
export class AssetEmployeePersonalWFHManagerListComponent extends GenericPrimeNgDataTableList<any, number> {
    public query = '';
    public filteredList = [];
    public elementRef;
    public listTemplate: string = 'WHFMANAGER';
    public loadType: string = '';
    public totals: AssetDepTotal = null;

    constructor(public myElement: ElementRef, public exportService: ExportService) {
        super('invNo', 'asc', null, exportService);

        this.columns = AppData.ColumnDefinitions[this.listTemplate];
        this.elementRef = myElement;
    }

    public refresh(filters: Array<Param>) {
        this.columns = AppData.ColumnDefinitions[this.listTemplate];
        super.refresh(filters);
    }

    public resetSort() {
        this.dt1.sortOrder = 0;
        this.dt1.sortField = undefined;
        this.dt1.reset();
    }   

    public resetRows(rows) {
        this.dt1.rows = rows;
    } 
    
    public setCurrentPageData(pageData: any) {
        this.totals = pageData.totals;
        super.setCurrentPageData(pageData);
    }

    public filter() {
        if (this.query !== '') {
            this.filteredList = this.tableItems.filter(function(el) {
                return el.item.invNo.toString().toLowerCase().indexOf(this.query.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredList = [];
        }
    }

    public select(item) {
        this.query = item;
        this.filteredList = [];
        if (item != null) {
            this.tableItems = this.tableItems.filter(function(el) {
                return el.item.invNo.toString().toLowerCase().indexOf(item.toLowerCase()) > -1;
            });
        }
    }
}
