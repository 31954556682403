import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityFile } from '../../model/api/common/entity-file';
import { EntityFileHttpService } from '../../services/http/common/entity-file.http.service';
import { saveAs as fileSaveAs } from 'file-saver-es';

@Component({
    selector: 'app-entity-file-download',
    templateUrl: 'entity-file-download.html',
    providers: [ EntityFileHttpService ]
})
export class EntityFileDownloadComponent {
    // @Input() entityFileId: number = 0;
    @Input() showAllButton: boolean = false;
    @Input() entityFile: EntityFile = null;
    @Input() entityFiles: EntityFile[] = [];
    @Output() public downloadFinished = new EventEmitter<void>();

    constructor(public entityFileHttpService: EntityFileHttpService) {}

    download(file) {
        this.entityFileHttpService
            .download(file.id)
            .subscribe((blob) => {
                this.downloadFinished.emit(null);
                //console.log(JSON.stringify(res));
                //console.log('download finished!');

                //this.downloadFile(res);
                fileSaveAs(blob, file.name);
                console.log(file);
            });
    }

    downloadFiles() {
        this.entityFiles.forEach((file) => {
            this.download(file);
          });
    }

    isDisabled(){
        return this.entityFiles.length === 0;
    }
}