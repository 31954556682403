import { Directive, ViewChild } from '@angular/core';
import { isNumericLiteral } from 'typescript';
import { Employee } from '../../model/api/administration/employee';
import { Representative } from '../../model/api/assets/customer';
import { InvState } from '../../model/api/inventory/inv-state';
import { Param } from '../../model/common/param';
import { IEntity } from '../../model/i-entity';
import { GenericTableList } from './generic.table.list';
import { map } from 'rxjs/operators';
import { PropertyFilterEntity } from '../../model/api/common/property-filter-entity';
import { Table } from 'primeng/table';
import { AssetFilter } from '../../model/api/assets/asset.filter';
import { LazyLoadEvent } from 'primeng/api';
@Directive()
export class GenericPrimeNgFilterDataTableList<T extends IEntity<V>, V> extends GenericTableList<T, V> {

  @ViewChild("dt1", {static: false}) dt1: Table;

    // representatives: Representative[];
    // invStates: InvState[];
    // selectedInvStates: number[];
    // propertyFilters = new Array<PropertyFilterEntity>();
    first = 1;
    rows = this.pageSize;
    // erpCode = '';
    // invNo = '';
    // name = '';
    // serialNumber = '';
    firstIndex = 0;

    selectedRows: Array<any>;

    public loadData(event: any) {
        // event.first = First row offset
        // event.rows = Number of rows per page
        // event.sortField = Field name to sort in single sort mode
        // event.sortOrder = Sort order as number, 1 for asc and -1 for dec in single sort mode
        // multiSortMeta: An array of SortMeta objects used in multiple columns sorting. Each SortMeta has field and order properties.
        // filters: Filters object having field as key and filter value, filter matchMode as value

        // let sortColumn: string = event.sortField === undefined ? this.sortColumn : event.sortField;

        // this.invStates = [
        //     {id: 1, parentCode: '', mask: '', isDeleted: false, state: '', name: 'New York', code: 'NY', notSync: '', isLocked: false},
        //     {id: 1, parentCode: '', mask: '', isDeleted: false, state: '', name: 'Rome', code: 'RM', notSync: '', isLocked: false},
        //     {id: 1, parentCode: '', mask: '', isDeleted: false, state: '', name: 'London', code: 'LDN', notSync: '', isLocked: false},
        //     {id: 1, parentCode: '', mask: '', isDeleted: false, state: '', name: 'Istanbul', code: 'IST', notSync: '', isLocked: false},
        //     {id: 1, parentCode: '', mask: '', isDeleted: false, state: '', name: 'Paris', code: 'PRS', notSync: '', isLocked: false}
        // ];

      //   this.representatives = [
      //     {name: "Amy Elsner", image: 'amyelsner.png'},
      //     {name: "Anna Fali", image: 'annafali.png'},
      //     {name: "Asiya Javayant", image: 'asiyajavayant.png'},
      //     {name: "Bernardo Dominic", image: 'bernardodominic.png'},
      //     {name: "Elwin Sharvill", image: 'elwinsharvill.png'},
      //     {name: "Ioni Bowcher", image: 'ionibowcher.png'},
      //     {name: "Ivan Magalhaes",image: 'ivanmagalhaes.png'},
      //     {name: "Onyama Limba", image: 'onyamalimba.png'},
      //     {name: "Stephen Shaw", image: 'stephenshaw.png'},
      //     {name: "Xuxue Feng", image: 'xuxuefeng.png'}
      // ];

      // this.invStates = [
      //     {label: 'Unqualified', value: 'unqualified'},
      //     {label: 'Qualified', value: 'qualified'},
      //     {label: 'New', value: 'new'},
      //     {label: 'Negotiation', value: 'negotiation'},
      //     {label: 'Renewal', value: 'renewal'},
      //     {label: 'Proposal', value: 'proposal'}
      // ]

        let sortColumn = '';
        if (event.sortField === undefined || event.sortField === null) {
            sortColumn = this.sortColumn;
        } else {
            sortColumn = event.sortField;
            this.sortDirection = event.sortOrder === -1 ? 'desc' : 'asc';
        }

        let dotIndex = -1;
        dotIndex = sortColumn.lastIndexOf('.');
        // if (dotIndex >= 0) sortColumn = sortColumn.substring(dotIndex + 1);

        this.currentPage = (event.first / event.rows) + 1;
        this.pageSize = event.rows;
        this.sortColumn = sortColumn;

        if (this.initialized){
          console.log('1');
          setTimeout(() => {

          //   const assetFilter: AssetFilter = new AssetFilter();

          //   if (this.selectedInvStates != null) {
          //     assetFilter.invStateIds = new Array<number>();
          //     this.selectedInvStates.forEach((id) => {
          //         assetFilter.invStateIds.push(id);
          //     });
          // }


            // if(this.propertyFilters.length == 0){
            //   this.erpCode = '';
            //   this.invNo = '';
            //   this.name = '';
            //   this.serialNumber = '';
            // }
            // this.params.push(new Param('propertyFilters', JSON.stringify(this.propertyFilters)));
            // this.params.push(new Param('jsonFilter', JSON.stringify(assetFilter)));
            // this.propertyFilters = new Array<PropertyFilterEntity>();
            // this.selectedInvStates = new Array<number>();
              this.refreshItems();
            }, 0);
        }


    }

    paginate(event) {
        this.currentPage = event.first;
        this.pageSize = event.rows;
        this.refreshItems();
        console.log('currentPaginationPage = first: ' + this.currentPage + ' rows: ' + this.pageSize);
        }

        getValue(event: Event): string {
            return (event.target as HTMLInputElement).value;
          }

    resetPage(){
      this.firstIndex = 0;
    }   

    resetSort(sortField) {
      this.dt1.sortOrder = 0;
      this.dt1.sortField = sortField;
      this.dt1.reset();
    }   

   resetRows(rows) {
      this.dt1.rows = rows;
  }
    isNumber(value: string | number): boolean {
        return ((value != null) &&
                (value !== '') &&
                !isNaN(Number(value.toString())));
      }

      // filterInvState(event){


      //   // console.log(event);
      //   this.selectedInvStates = new Array<number>();
      //   event.map((item) => this.selectedInvStates.push(item.id));
      //   // console.log(event);
      // }


      // filterProperty(event, include, sortBy){
      //   console.log('1: ' + event.target.value);
      //    console.log('2: ' + include);
      //    console.log('3: ' + sortBy);
      //   if(sortBy == 'ErpCode' || sortBy == 'Asset.ErpCode'){
      //     this.erpCode = event.target.value;
      //   };
      //   if(sortBy == 'InvNo' || sortBy == 'Asset.InvNo'){
      //     this.invNo = event.target.value;
      //   }

      //   if(sortBy == 'Name' || sortBy == 'Asset.Name'){
      //     this.name = event.target.value;
      //   }

      //   if(sortBy == 'SerialNumber' || sortBy == 'Asset.SerialNumber'){
      //     this.serialNumber = event.target.value;
      //   }


      //   this.propertyFilters = new Array<PropertyFilterEntity>();
      //   let propertyFilter = new PropertyFilterEntity(include + sortBy , event.target.value);
      //   this.propertyFilters.push(propertyFilter);
      //   // this.selectedInvStates = new Array<number>();
      //   // event.map((item) => this.selectedInvStates.push(item.id));
      //   // console.log(event);

      // }


      onEditComplete(editInfo) {
        let fieldChanged = editInfo.column.field;
        let newRowValues = editInfo.data;
        alert(`You edited ${fieldChanged} to ${newRowValues[fieldChanged]}`);
      }

      onRowSelect(rowInfo) {
        //console.log(JSON.stringify(rowInfo.data)); // or this.selectedRow
      }

      loadTimes(event: LazyLoadEvent) {

        console.log(JSON.stringify(event));

        // let table = this.dt1.table("timesheet");

        var query: any;

        // Dexie doesn't support ordering AND filtering, so we branch here
        // Alternative strategies here: https://github.com/dfahlander/Dexie.js/issues/297
        if (event.filters && event.filters["project"]) {
          // query = table.where("project").equals(event.filters["project"]["value"]);
        } else if (event.globalFilter) {
          // query = table.where("project").startsWithIgnoreCase(event.globalFilter)
            //.or("user").startsWithIgnoreCase(event.globalFilter)
            //.or("category").startsWithIgnoreCase(event.globalFilter);
        } else {
          // query = table.orderBy(event.sortField);
        }

        query = query
          .offset(event.first)
          .limit(event.rows);

        if (event.sortOrder == -1) {
          query = query.reverse();
        };

        query.toArray((nextBlockOfTimes) => {
          // console.log("Loaded times: %s", JSON.stringify(nextBlockOfTimes));
          this.tableItems = nextBlockOfTimes;
        });
      }
}
