import { AssetSimpleDetail } from '../../../model/api/assets/asset-simple-detail';
import { Component, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Param } from '../../../model/common/param';
import { AssetListComponent } from './asset.list';

import { AssetFilter } from '../../../model/api/assets/asset.filter';
import { Res } from '../../../resources/resources';
import { AuditHttpService } from '../../../services/http/administration/audit.http.service';

@Component({
    selector: 'app-audit-employee-list',
    templateUrl: 'audit.employee.list.html',
    styleUrls: ['audit.employee.list.scss'],
    providers: []
})
export class AuditemployeeListComponent implements AfterViewInit { // extends GenericManage<AssetInvDetail> {

    @ViewChild('assetList') public assetList: AssetListComponent;

    public exportBtnInfo = Res.ExportBtnInfo;
    public detailsBtnInfo = Res.DetailsBtnInfo;

    public filter: string;
    public filterAll: string;
    public filterName: string;
    public filterInv: string;
    public filterPurchaseDate: string;
    public isPrinted: string = '-';
    public isDuplicate: string = '-';
    public isWaitingValidation: string = '-';
    public isInTransfer: string = '-';
    public isClosed: string = '-';
    public smallPageSize: number = 5;
    public largePageSize: number = 10;

    public pageSizeUpdatedEvent: EventEmitter<number> = new EventEmitter<number>();
    public noOfItems: number = 0;

    appStateId = 0;
    // pageSize = 10;
    public showExportBtn = true;

   
    public params: Array<Param> = null;


    public from = '';
    public to = '';
    public closeOnDate = '';

    public view: string;
    public assetRowSelection: string = 'single';
    public selectedAssets: Array<AssetSimpleDetail> = new Array<AssetSimpleDetail>();

    public mainTitle: string = '';


    public initialSortColumn = '';
    public initialSortDirection = 'asc';
    public initialPageSize = 50;
    public refreshBtnInfo = Res.RefreshBtnInfo;

    constructor(public route: ActivatedRoute,
                public router: Router,
                public auditHttpService: AuditHttpService,
               ) {            
            //  this.options = new DatePickerOptions();
            //  // this.toastr.setRootViewContainerRef(vcr);
            this.router.events.subscribe((evt) => {
                if (evt instanceof NavigationEnd) {
                    if (evt.urlAfterRedirects === '/audit/users') {
                        // console.log('refreshing asset inv details');
                        // console.log(JSON.stringify(evt));
                        // this.refreshAssets();

                        setTimeout(() => {
                            this.refreshAssets();
                          }, 100);
                    }
                }
            });
    }

    ngOnInit() {
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngAfterViewInit() {
        this.initialSortColumn = this.assetList.sortColumn;
        this.initialSortDirection = this.assetList.sortDirection;
        this.initialPageSize = this.assetList.pageSize;
        setTimeout(() => {
            this.clearFilters();
          }, 1000);
    }

    public checkForRefresh() {
        // this.clearSelection();
        if (this.filter !== '' && this.filter !== undefined){
            this.assetList.currentPage = 1;
            this.assetList.firstIndex = 0;
        }
        this.refreshAssets();
    }

    public resetAllFilters(){
        //set first page + refetch data
        this.assetList.currentPage = 1;
        this.assetList.pageSize = this.initialPageSize;
        this.assetList.resetRows(this.initialPageSize);
        
        //reset sorting
        this.assetList.sortColumn = this.initialSortColumn;
        this.assetList.sortDirection = this.initialSortDirection;
        this.assetList.resetSort();
        
        //reset filters
        this.clearFilters();
    }

    public clearSelection() {
        this.selectedAssets = new Array<AssetSimpleDetail>();
        this.assetList.selectedItems = this.selectedAssets;

    }

    public clearFilters() {
        this.filter = '';
        this.to =  '';
        this.from = '';
        this.appStateId = 0;
        this.selectedAssets = new Array<AssetSimpleDetail>();
        this.checkForRefresh();
    }


    public onAssetSelectionChanged(assets: Array<any>) {
        this.assetList.unselectAll()
    }

    public refreshAssets() {
        const params: Array<Param> = this.getFilters();

       // if (this.depView) this.requestAssetDepDetailRefreshEvent.emit(params);
        // if (this.invView) this.requestAssetInvDetailRefreshEvent.emit(params);
        this.assetList.refresh(params);
    }



    public getFilters(): Array<Param> {
        let params = new Array<Param>();
        let assetFilter: AssetFilter = new AssetFilter();

   
        assetFilter.filter = this.filter;
         assetFilter.isPrinted = ((this.isPrinted === '-') ? null : (this.isPrinted === 'DA' ? true : false));
        assetFilter.isDuplicate = ((this.isDuplicate === '-') ? null : (this.isDuplicate === 'DA' ? true : false));
        assetFilter.filterName = this.filterName;
        assetFilter.filterInv = this.filterInv;
        assetFilter.filterPurchaseDate = this.filterPurchaseDate ? this.filterPurchaseDate : 'false' ;
        assetFilter.fromDate = new Date(this.from);
        assetFilter.toDate = new Date(this.to);
        assetFilter.toDate = new Date(this.closeOnDate);
        // params.push(new Param('pageSize', this.pageSize.toString()));
        params.push(new Param('jsonFilter', JSON.stringify(assetFilter)));
       // console.log(assetFilter);
        return params;
    }

    public export() {
        this.assetList.export$('Audit utilizatori').subscribe(
            () => {},
            error => {}
        );
    }

    get canExport() {
        return this.assetList?.canExport;
    }

}
