export class NeedBudget {
    projectTypeDivisionId: number;
    costCenterId: number;
    assetTypeId: number;
    employeeId: number;
    ownerId: number;
    info: string;
    endDate: Date;
    budgetValueNeed: number;
    startAccMonthId: number;
}
