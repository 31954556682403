import { Component } from '@angular/core';

@Component({
    selector: 'error-reset-email-page',
    templateUrl: 'error-reset-email-page.html'
})
export class ErrorResetEmailPage {
    constructor() {

    }
}
