import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericHttpService } from "../generic.http.service";
import { ExpAccount } from "../../../model/api/administration/exp-account";

@Injectable()
export class ExpAccountHttpService extends GenericHttpService<ExpAccount, number> {
    constructor(public http: HttpClient) {
        super(http, "", "expaccounts");
    }
}