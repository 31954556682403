import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OfferType } from '../../../model/api/offer/offer-type';
import { GenericHttpService } from '../generic.http.service';
// import { AuthHttp } from 'angular2-jwt';

@Injectable()
export class OfferTypeHttpService extends GenericHttpService<OfferType, number> {
    constructor(public http: HttpClient) {
        super(http, "", "offertypes");
    }
}
