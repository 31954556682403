import { Injectable } from '@angular/core';
import { GenericHttpService } from '../generic.http.service';
import { MasterType } from '../../../model/api/assets/master-type';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MasterTypeHttpService extends GenericHttpService<MasterType, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'mastertypes');
    }
}
