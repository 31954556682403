import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../../config';
import { Department } from '../../../model/api/administration/department';
import { Param } from '../../../model/common/param';
import { GenericHttpService } from '../generic.http.service';
// import { AuthHttp } from 'angular2-jwt';

@Injectable()
export class DepartmentHttpService extends GenericHttpService<Department, number> {
    constructor(public http: HttpClient) {
        super(http, "", "departments");
    }


    public getData(params: Array<Param>): Observable<any> {
        let searchParams: HttpParams = null;
        searchParams = this.getSearchParams(params);
        return this.http.get(AppConfig.urlPrefix + 'dashboards/departments/' , { params: searchParams, headers: this.headers }).pipe(
        map((data: Response) => {
            return data;
        }));
    }
}