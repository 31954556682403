import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdmCenter } from '../../../model/api/administration/adm-center';
import { GenericHttpService } from '../generic.http.service';
// import { AuthHttp } from 'angular2-jwt';

@Injectable()
export class AdmCenterHttpService extends GenericHttpService<AdmCenter, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'admcenters');
    }
}
