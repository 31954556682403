import { Component } from '@angular/core';
import { GenericTableList } from './generic.table.list';
import { IEntity } from '../../model/i-entity';
import { GridOptions, IsFullWidthRowParams } from 'ag-grid-community';

export class GenericAgGridList<T extends IEntity<V>, V> extends GenericTableList<T, V> {


    public gridOptions: GridOptions = <GridOptions>{};
    public columnDefs: any[] = null;
    //public fullWidthCellRenderer: any = FullWidthCellRenderer;

    public itemAdded(addedItem: T) {
        super.itemAdded(addedItem);
        this.refreshGrid();
    }

    public itemUpdated(updatedItem: T) {
        super.itemUpdated(updatedItem);
        this.refreshGrid();
    }

    public itemDeleted(deletedItem: T) {
        super.itemDeleted(deletedItem);
        this.refreshGrid();
    }

    refreshGrid() {
        var newItemList: Array<T> = [];
        if (this.items) {
            this.items.forEach((item) => {
                newItemList.push(item);
            });
        }
        this.items = newItemList;
    }

    public onSelectionChanged($event) {
        this.selectedItems = this.gridOptions.api.getSelectedRows();

        // if (String(this.notifyOnChange) === "true") {
        if (this.notifyOnChange) {
            this.notifyCurrentSelection();
        }
    }

    public onGridReady(params) {
      console.log('Grid ready');
      this.refreshGrid();
    }

    public isFullWidthRow: (params: IsFullWidthRowParams) => boolean = (
      params: IsFullWidthRowParams
    ) => {
      return this.isFullWidth(params.rowNode.data);
    };

     isFullWidth(data: any) {
      // return true when country is Peru, France or Italy
      return true;
    }

    onFilterChanged (filter) {
      //const model = this.gridApi.getFilterModel();
    }
}
