import { Component, ElementRef } from '@angular/core';
import { GenericTableList } from '../../generic/generic.table.list';
import { Employee } from '../../../model/api/administration/employee';
import { AppData } from '../../../app-data';
import { Param } from '../../../model/common/param';
import { GenericPrimeNgDataTableList } from '../../generic/generic.prime-ng-data-table.list';
import { AssetDepTotal } from '../../../model/api/assets/asset-dep-total';
import { EmployeeHttpService } from '../../../services/http/administration/employee.http.service';
import { InvStateHttpService } from '../../../services/http/inventory/inv-state.http.service';
import { ExportService } from '../../../services/export.service';

@Component({
    selector: 'app-employee-error-list',
    //templateUrl: '../../generic/generic.table.list.html',
    templateUrl: '../../generic/generic.prime-ng-data-table.list.html',
    inputs: [ 'listTemplate' ],
})
//export class EmployeeListComponent extends GenericTableList<Employee, number> {
export class EmployeeErrorListComponent extends GenericPrimeNgDataTableList<any, number> {
  public columns: any = [];

  public query = '';
  public filteredList = [];
  public elementRef;
  public listTemplate: string = 'EMPLOYEEERRORS';
  public loadType: string = '';
  public totals: AssetDepTotal = null;

  constructor(public myElement: ElementRef,
    public employeeHttpService: EmployeeHttpService,
    public invStateHttpService: InvStateHttpService,
    public exportService: ExportService) {
      super('id', 'asc', null, exportService);

      this.columns = AppData.ColumnDefinitions[this.listTemplate];
      this.elementRef = myElement;
  }

    public refresh(filters: Array<Param>) {
      this.columns = AppData.ColumnDefinitions[this.listTemplate];
      super.refresh(filters);
  }

      public setCurrentPageData(pageData: any) {
      this.totals = pageData.totals;
      super.setCurrentPageData(pageData);
  }

  public filter() {
      if (this.query !== ''){
          this.filteredList = this.tableItems.filter(function(el){
              return el.item.invNo.toString().toLowerCase().indexOf(this.query.toLowerCase()) > -1;
          }.bind(this));
      } else{
          this.filteredList = [];
      }
  }

    public resetSort() {
        this.dt1.sortOrder = 0;
        this.dt1.sortField = undefined;
        this.dt1.reset();
    }   

    public resetRows(rows) {
        this.dt1.rows = rows;
    } 

  public select(item){
      this.query = item;
      this.filteredList = [];
      if(item != null){
          this.tableItems = this.tableItems.filter(function(el){
              return el.item.invNo.toString().toLowerCase().indexOf(item.toLowerCase()) > -1;
          })
      }
  }

  public filterCust(ev) {
      console.log(JSON.stringify(ev));
    }

    // public async loadEmployee(){
    //   this.invStateHttpService.get(1, 5, 'id', 'asc',[],null,'').subscribe((res: any)=> {
    //     // this.invStates = res;
    //     //console.log(res.items);
    //     this.invStates= [];
    //     this.invStates = res.items;
    //     //console.log(this.invStates);
    //     console.log('LOAD EMPLOYEE');
    //   })

    // }
}
