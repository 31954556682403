import { EmployeeImport } from './../../../model/common/import/employee-import';
import { Param } from './../../../model/common/param';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { Department } from '../../../model/api/administration/department';
import { Employee } from '../../../model/api/administration/employee';
import { EmployeeDetail } from '../../../model/api/administration/employee-detail';
import { EmployeeHttpService } from '../../../services/http/administration/employee.http.service';
import { EmployeeDetailHttpService } from '../../../services/http/administration/employee-detail.http.service';
import { NavigationEnd, Router } from '@angular/router';
import { saveAs as fileSaveAs } from 'file-saver-es';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DepartmentListComponent } from '../departments/department.list';
import { AppData } from '../../../app-data';
import { DepartmentHttpService } from '../../../services/http/administration/department.http.service';
import { AppUtils } from '../../../common/app.utils';
import { CostCenterListComponent } from '../cost-centers/cost-center.list';
import { CostCenter } from '../../../model/api/administration/cost-center';
import { CostCenterHttpService } from '../../../services/http/administration/cost-center.http.service';
import alasql from 'alasql';
import { EmployeeManagerAdd } from '../../../model/api/assets/employee-manager-add';
import { EmployeeManagerHttpService } from '../../../services/http/administration/employee-manager.http.service';
import { NotificationService } from '../../../services/notification.service';
import { EmployeeManagerList } from '../employee-managers/employee-manager.list';
import { RoleList } from '../../auth/role.list';
import { RoleEntity } from '../../../model/api/common/role-entity';
import { RoleService } from '../../../services/http/identity/role.service';
import { CompanyListComponent } from '../../assets/companies/company.list';
import { Company } from '../../../model/api/assets/company';
import { CompanyHttpService } from '../../../services/http/assets/company.http.service';
import { ManagerListComponent } from '../managers/manager.list';
import { Manager } from '../../../model/api/administration/manager';
import { ManagerHttpService } from '../../../services/http/administration/manager.http.service';
import { EmployeeAllocateListComponent } from './employee-allocate.list';
import { ValidateEmployeeManager } from '../../../model/api/administration/validate-employee-manager';
import { DeleteEmployeeManager } from '../../../model/api/administration/delete-employee-manager';
import { forkJoin, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ExportService } from '../../../services/export.service';
import { Res } from '../../../resources/resources';

@Component({
    selector: 'app-employee-allocate-manage',
    templateUrl: 'employee-allocate.manage.html',
    styles: [`
      :host ::ng-deep .p-datatable-resizable>.p-datatable-wrapper {
        overflow-y: scroll !important;
        height: 400px !important;
      }  
    `],
})
export class EmployeeAllocateManageComponent extends GenericManage<Employee, number> implements OnInit {

    @ViewChild('employeeDetailModal') employeeDetailModal: ModalDirective;
    @ViewChild('employeeList') employeeList: EmployeeAllocateListComponent;
    @ViewChild('confirmationModal') public confirmationModal: ModalDirective;

    @ViewChild('employeeManagerList') employeeManagerList: EmployeeManagerList;
    
    public refreshBtnInfo = Res.RefreshBtnInfo;
    public allocateBtnInfo = Res.AllocateBtnInfo;
    public recallingBtnInfo = Res.RecallingBtnInfo;
    public deleteBtnInfo = Res.DeleteBtnInfo;
    public exportBtnInfo = Res.ExportBtnInfo;
    
    public filter: string = '';
    public employee: Employee = null;;
    isCollapsed: boolean = true;
    //pageSize = 50;
    public data;
    public initialSortColumn = '';
    public initialSortDirection = 'asc';
    public initialPageSize = 50;

    public initialManagerSortColumn = '';
    public initialManagerSortDirection = 'asc';
    public initialManagerPageSize = 5;

    isEmpManagerLoading: boolean = false;

    public confirmationMessage: string = '';
    public operationType: OperationType = OperationType.NotSet;

    constructor(
                public managerHttpService: ManagerHttpService,
                public employeeHttpService: EmployeeHttpService,
                public employeeManagerHttpService: EmployeeManagerHttpService,
                public notificationService: NotificationService,
                public router: Router,
                public employeeDetailHttpService: EmployeeDetailHttpService,
                public exportService: ExportService) {

        super();
        this.router.events.subscribe((evt) => {
          if (evt instanceof NavigationEnd) {
              if (evt.urlAfterRedirects === '/wfh/allocate') {
                  setTimeout(() => {
                      this.refresh();
                    }, 100);
              }
          }
      });
    }
  ngOnInit(): void {
    // setTimeout(() => {
    //   if(this.employeeManagerList != undefined){
    //     this.refreshEmployeeManagerList();
    //   }
    // }, 3000);
  }

  ngAfterViewInit() {
    this.initialSortColumn = this.employeeList.sortColumn;
    this.initialSortDirection = this.employeeList.sortDirection;
    this.initialPageSize = this.employeeList.pageSize;

    this.initialManagerSortColumn = this.employeeManagerList.sortColumn;
    this.initialManagerSortDirection = this.employeeManagerList.sortDirection;
    this.initialManagerPageSize = this.employeeManagerList.pageSize;
  }

  public export() {
    this.export$('Alocare drepturi').subscribe(
        () => {},
        error => {}
    );
  }

  get canExport() {
    return this.employeeList?.canExport && this.employeeManagerList?.canExport;
  }

  private export$(name: string) {
    if (!this.exportService) {
        return throwError(new Error('Export service is not available.'));
    }
    
    return forkJoin([this.employeeList.getExportItems$(), this.employeeManagerList.getExportItems$()]).pipe(
      switchMap(([employeeItems, employeeManagerItems]) => this.exportService.exportMultiple$(name, [
        {sourceRows: employeeItems, sourceColumns: this.employeeList.columns}, 
        {sourceRows: employeeManagerItems, sourceColumns: this.employeeManagerList.columns}])),
      catchError(exportError => {
        console.error('Error exporting data:', exportError);
        return throwError(exportError);
      }));
  }

    public detailInitialize() {
        super.detailInitialize();
        this.employeeDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.employeeDetailModal.hide();
    }

    public doSimpleSearch(filter: string) {
      if (this.filter !== '' && this.filter !== undefined){
        this.employeeList.currentPage = 1;
        this.employeeList.first = 1;
        this.employeeList.resetDt();
      }
      super.doSimpleSearch(filter);
    }

    public resetAllFilters(){
      //set first page + refetch data
      this.employeeManagerList.currentPage = 1;
      this.employeeManagerList.pageSize = this.initialManagerPageSize;
      this.employeeManagerList.resetRows(this.initialManagerPageSize);

      this.employeeList.currentPage = 1;
      this.employeeList.pageSize = this.initialPageSize;
      this.employeeList.resetRows(this.initialPageSize);

      //reset sorting
      this.employeeManagerList.sortColumn = this.initialManagerSortColumn;
      this.employeeManagerList.sortDirection = this.initialManagerSortDirection;
      this.employeeManagerList.resetSort();

      this.employeeList.sortColumn = this.initialSortColumn;
      this.employeeList.sortDirection = this.initialSortDirection;
      this.employeeList.resetSort();

      //reset filters
      this.clearFilter();

      //clear selections
      this.clearSelection();

      this.refresh();
    }

    public clearSelection(){
      this.employeeList.refresh(null);
      this.employeeManagerList.refresh(null);
      this.employeeList.selectedItems = new Array<any>();
      this.employeeManagerList.selectedItems = new Array<any>();
    }

    public clearFilter() {
      this.filter ='';
    }

    public refresh() {
        let params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        //params.push(new Param('pageSize', this.pageSize.toString()));
        params.push(new Param('onlyActive', "true"));
        this.employeeList.refresh(params);

        if(this.employeeManagerList != undefined){
          this.employeeManagerList.refresh(params);
        }
    }

    refreshEmployeeManagerList() {
      let resultSubject = new Subject<boolean>();

      this.isEmpManagerLoading = true;
      let params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        //params.push(new Param('pageSize', this.pageSize.toString()));
        params.push(new Param('onlyActive', "true"));

        if(this.employeeManagerList != undefined){
          this.employeeManagerList.refresh(params);
          resultSubject.next(true);
          return resultSubject.asObservable();
        }else {
          resultSubject.next(false);
          this.isEmpManagerLoading = false;
          return resultSubject.asObservable();
        }
    }


    public getFilters(): Array<Param> {
        let params: Array<Param> = new Array<Param>();
        params.push(new Param('filter', this.filter));
        //params.push(new Param('pageSize', this.pageSize.toString()));
        return params;
    }

    public exportToExcel() {

        let params: Array<Param> = null;

        params = this.getFilters();
        this.employeeHttpService
            .export(params)
            .subscribe((blob) => {
                fileSaveAs(blob.body, 'Export.xlsx');
            });
    }

    public editEmployee() {
        let selectedEmployeeId = this.employeeList.selectedItems.length > 0 ? this.employeeList.selectedItems[0].id : 0;
        if (selectedEmployeeId > 0) {
            this.router.navigate(['/employee', selectedEmployeeId]);
        }
    }



    collapsed(event: any): void {
        // console.log(event);
      }

      expanded(event: any): void {
        // console.log(event);
      }

    //   public onPageUpdate(number: number) {
    //     this.pageSize = number;
    //     this.refresh();
    // }

  public onAllocate() {
    this.operationType = OperationType.MakeManager;
    this.confirmationMessage = 'Alocati dreptul de manager angajatului selectat?';
    this.confirmationModal.show();
}

public onCancelAllocate() {
  this.operationType = OperationType.CancelManager;
  this.confirmationMessage = 'Revocati dreptul de manager angajatului selectat?';
  this.confirmationModal.show();
}

public onDeleteAllocate() {
  this.operationType = OperationType.DeleteAllocate;
  this.confirmationMessage = 'Stergeti alocarea selectata?';
  this.confirmationModal.show();
}

  public onConfirmationCanceled() {
    this.operationType = OperationType.NotSet;
    this.confirmationModal.hide();
}

public onConfirmationApproved() {

    switch (this.operationType) {
        case OperationType.MakeManager:
             this.makeManager();
            break;
          case OperationType.CancelManager:
            this.cancelManager();
             break;
          case OperationType.DeleteAllocate:
          this.deleteManager();
             break;
        default:
            break;
    }

    this.operationType = OperationType.NotSet;
    this.confirmationModal.hide();
}

public makeManager() {

  let items: Array<any> = this.employeeList.selectedItems;
  this.employee = ((items != null) && (items.length === 1)) ? items[0] : null;
  let makeManager: EmployeeManagerAdd = new EmployeeManagerAdd();
  makeManager.employeeId = this.employee != null ? this.employee.id : null;

  this.employeeManagerHttpService.addManagerByEmployee(makeManager).subscribe( (res) => {
          if (res.statusCode === 200) {
              this.notificationService.showSuccess('Datele au fost salvate cu success!', 'Alocare drepturi manager');
              this.clearSelection();
              // this.employeeCostCenterList.refresh(null);
              // this.costCenterList.selectedItems = new Array<CostCenter>();
          } else if (res.statusCode === 404) {
              this.notificationService.showError('Nu exista', 'Alocare drepturi manager');
              // this.costCenterList.selectedItems = new Array<CostCenter>();
          }
  }, (error) => {
      this.notificationService.showError('Eroare salvare!', 'Alocare drepturi manager');
      // this.costCenterList.selectedItems = new Array<CostCenter>();
  });
}

public cancelManager() {

  let items: Array<any> = this.employeeManagerList.selectedItems;
  // this.employee = ((items != null) && (items.length === 1)) ? items[0] : null;
  // console.log(JSON.stringify(this.employeeManagerList.selectedItem));

  let rejects = new Array<ValidateEmployeeManager>();
  for (let index = 0; index < this.employeeManagerList.selectedItems.length; index++) {
    const element = this.employeeManagerList.selectedItems[index];
    let item = new ValidateEmployeeManager();
    item.id = element.id;
    item.reason = '';
    rejects.push(item);
  }

  this.employeeManagerHttpService.rejectMultipleAllocate(rejects).subscribe( (res) => {
          if (res.success) {
              this.notificationService.showSuccess(res.message, 'Alocare drepturi manager');
              this.clearSelection();
              // this.employeeCostCenterList.refresh(null);
              // this.costCenterList.selectedItems = new Array<CostCenter>();
          } else {
              this.notificationService.showError(res.message, 'Alocare drepturi manager');
              // this.costCenterList.selectedItems = new Array<CostCenter>();
          }
  }, (error) => {
      this.notificationService.showError('Eroare salvare!', 'Alocare drepturi manager');
      // this.costCenterList.selectedItems = new Array<CostCenter>();
  });
}

public deleteManager() {

  let items: Array<any> = this.employeeManagerList.selectedItems;

  let rejects = new Array<DeleteEmployeeManager>();
  for (let index = 0; index < this.employeeManagerList.selectedItems.length; index++) {
    const element = this.employeeManagerList.selectedItems[index];
    let item = new DeleteEmployeeManager();
    item.id = element.id;
    item.reason = '';
    rejects.push(item);
  }

  this.employeeManagerHttpService.deleteMultipleAllocate(rejects).subscribe( (res) => {
          if (res.success) {
              this.notificationService.showInfo(res.message, 'Stergere alocare drepturi manager');
              this.employeeList.refresh(null);
              this.employeeManagerList.refresh(null);
              this.employeeManagerList.selectedItems = new Array<any>();
              // this.employeeCostCenterList.refresh(null);
              // this.costCenterList.selectedItems = new Array<CostCenter>();
          } else {
              this.notificationService.showError(res.message, 'Stergere alocare drepturi manager');
              // this.costCenterList.selectedItems = new Array<CostCenter>();
          }
  }, (error) => {
      this.notificationService.showError('Eroare salvare!', 'Stergere alocare drepturi manager');
      // this.costCenterList.selectedItems = new Array<CostCenter>();
  });
}

public get allowDelete(): boolean {
  if(this.employeeManagerList != undefined && this.employeeManagerList.selectedItems.length > 0 && this.employeeManagerList.selectedItems.length === 1){
   for (let index = 0; index < this.employeeManagerList.selectedItems.length; index++) {
     const element = this.employeeManagerList.selectedItems[index];
     if(element.complete && !element.emailConfirm){
       return true;
     }
   }
   return false;
  } else {
   return false;
  }
}

public get allowRevoke(): boolean {
  if(this.employeeManagerList != undefined && this.employeeManagerList.selectedItems.length > 0 && this.employeeManagerList.selectedItems.length === 1){
   for (let index = 0; index < this.employeeManagerList.selectedItems.length; index++) {
     const element = this.employeeManagerList.selectedItems[index];
     if((!element.complete && !element.emailConfirm) || (element.complete && element.emailConfirm)){
       return true;
     }
   }
   return false;
  } else {
   return false;
  }
}
}

enum OperationType {
  NotSet = 1,
  MakeManager = 2,
  CancelManager = 3,
  DeleteAllocate = 4,
  ProcessAssetOp = 5
}

