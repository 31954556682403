export enum Res {
    CheckBtnInfo = "Daca aveti selectate mai multe linii, apasarea acestui buton va sterge selectia respectiva.",
    RefreshBtnInfo = "Actualizare date.",
    DetailsBtnInfo = "Vizualizati detaliile liniei selectate.",
    ExportBtnInfo = "Export date in fisier excel (tine cont de filtrarea prin cautare).",
    PrintBtnInfo = "Printare etichete bun/bunuri selectate.",
    EquipmentDeliveryBtnInfo = "Butonul este activ cand selectati una sau mai multe linii necolorate. Apasarea butonului deschide meniul de utilizatori, unde veti selecta utilizatorul catre care doriti predarea echipamentelor. Obligatoriu veti trece si motivul transferului.",
    TemporaryEquipmentDeliveryBtnInfo = "Butonul este activ cand selectati o singura linie necolorata. Apasarea butonului deschide meniul de utilizatori, unde veti selecta utilizatorul catre care doriti predarea echipamentului temporar. Dupa ce selectati utilizatorul, se va deschide o fereastra cu bunurile utilizatorului respectiv si aici veti selecta bunul defect din gestiunea lui (se poate selecta un singur bun). Obligatoriu veti trece si motivul transferului.",
    BuyBtnInfo = "Butonul este activ cand selectati o singura linie necolorata. Trimite solicitarea de cumparare pe fluxul de aprobare pentru bunul selectat (pe care doriti sa-l cumparati).",
    CancelPurchaseBtnInfo = "Butonul este activ cand selectati o singura linie albastra si prin apasare lui veti renunta la cumpararea bunului respectiv. Obligatoriu veti trece si motivul renuntarii la cumparare.",
    SaleBtnInfo = "Butonul este activ cand selectati o singura linie necolorata. Prin apasarea acestui buton, veti scoate bunul respectiv la vanzare catre toti utilizatorii din aplicatie. Se va deschide o fereastra unde veti trece: pretul de vanzare cu TVA, o scurta descriere, linkul cu specificatiile bunului si veti incarca cateva poze cu bunul scos la vanzare.",
    SaleCancelBtnInfo = "Butonul este activ cand selectati o singura linie scoasa la vanzare. Apasarea lui va scoate linia din fluxul de vanzare si din meniul Bunuri de vanzare. Obligatoriu veti trece si motivul renuntarii la vanzare.",
    NotesBtnInfo = "Butonul este activ cand selectati o singura linie necolorata. Permite completarea campului Notite a bunului selectat, camp ce va poate ajuta la gestionarea/catalogarea bunurilor dvs. Campul se va sterge cand bunul este transferat catre un alt utilizator.",
    UploadProofOfPaymentBtnInfo = "Butonul este activ cand selectati o singura linie albastra in starea: Incarca dovada plata (vezi coloana Status vanzare/BM). Incarcarea dovezii de plata va trimite automat in pasul urmator solicitarea de cumparare.",
    TransferAcceptanceBtnInfo = "Aprobati bonul de miscare selectat, bon care poate avea o singura linie sau linii multiple. Dupa aprobare, bunul/bunurile se vor muta in gestiunea dvs. si vor aparea in meniul Angajat. Daca de exemplu avem bonul de miscare cu numarul 192, cu 5 linii, la apasarea acestui buton va aparea mesajul: 'Validati transferul selectat? Atentie ca acest bon de miscare contine 5 bunuri! Pentru mai multe detalii puteti cauta dupa numarul 192 pe coloana Nr. BM'. Daca se apasa Da se vor aproba toate cele 5 linii ale bonului de miscare.",
    TransferDeclineBtnInfo = "Refuzati bonul de miscare selectat, bon care poate avea o singura linie sau linii multiple. Obligatoriu veti trece si motivul refuzului. Daca de exemplu avem bonul de miscare cu numarul 192, cu 5 linii, la apasarea acestui buton va aparea mesajul: 'Esti sigur ca vrei sa anulezi acest bon de miscare? Atentie ca acest bon de miscare contine 5 bunuri! Pentru mai multe detalii puteti cauta dupa numarul 192 pe coloana Nr. BM'. Daca se apasa Da se vor refuza toate cele 5 linii ale BM-ului.",
    AllocateBtnInfo = "Permite alocarea drepturilor dvs. catre utilizatorul selectat in primul tabel. Dupa ce utilizatorul catre care ati alocat drepturile dvs. aproba solicitarea, el va vedea in meniul Echipa toate bunurile dvs. si toate bunurile utilizatorilor din subordinea dvs. Este activ cand selectati un utilizator in primul tabel si apasarea lui va genera o solicitare de aprobare alocare drepturi catre utilizatorul selectat.",
    RecallingBtnInfo = "Butonul este activ cand selectati o linie non-gri din al doilea tabel si apasarea lui va revoca drepturile utilizatorului respectiv.",
    DeleteBtnInfo = "Butonul este activ cand selectati o linie gri din al doilea tabel si apasarea lui va sterge linia respectiva din tabel.",
    RightsAcceptanceBtnInfo = "Aproba solicitarea selectata de alocare drepturi catre dvs. Linia va ramane in meniu in culoarea verde, pana cand utilizatorul, care v-a alocat drepturile, le revoca.",
    RightsDeclineBtnInfo = "Refuzati solicitarea selectata si veti trece obligatoriu motivul refuzului. Linia va disparea din acest meniu.",
    TeamEquipmentDeliveryBtnInfo = "Butonul este activ cand selectati una sau mai multe linii necolorate. Apasarea butonului deschide meniul de utilizatori, unde veti selecta utilizatorul catre care doriti predarea echipamentelor. Obligatoriu veti trece si motivul transferului.",
    TeamTemporaryEquipmentDeliveryBtnInfo = "Butonul este activ cand selectati o singura linie necolorata. Apasarea butonului deschide meniul de utilizatori, unde veti selecta utilizatorul catre care doriti predarea echipamentului temporar. Dupa ce selectati utilizatorul, se va deschide o fereastra cu bunurile utilizatorului respectiv si aici veti selecta bunul defect din gestiunea lui (se poate selecta un singur bun). Obligatoriu veti trece si motivul transferului.",
    TeamBuyBtnInfo = "Butonul este activ cand selectati o singura linie necolorata. Prin apasarea acestui buton, veti scoate bunul respectiv la vanzare catre toti utilizatorii din aplicatie. Se va deschide o fereastra unde veti trece: pretul de vanzare cu TVA, o scurta descriere, linkul cu specificatiile bunului si veti incarca cateva poze cu bunul scos la vanzare.",
    TeamCancelPurchaseBtnInfo = "Butonul este activ cand selectati o singura linie scoasa la vanzare. Apasarea lui va scoate linia din fluxul de vanzare si din meniul Bunuri de vanzare. Obligatoriu veti trece si motivul renuntarii la vanzare.",
    PurchaseHistoryDetailsBtnInfo = "Vizualizati si descarcati documentele atasate la linia respectiva (dovada plata, factura etc.).",
    SignedBMBtnInfo = "Butonul este activ cand selectati un bon de miscare aprobat de primitor (are Finalizat sau Aprobat primitor pe coloana Stare). Apasarea acestui buton va genera bonul de miscare semnat electronic pentru linia selectata.",
    GoodsForSaleDetailsBtnInfo = "Vizualizati detaliile si pozele bunului scos la vanzare selectat.",
    GoodsForSaleBuyBtnInfo = "Rezervati bunul scos la vanzare selectat. Veti primi pe mail notificare cu detaliile bancare pentru plata.",
    GoodsForSaleCancelPurchaseBtnInfo = "Butonul este activ cand selectati un bun rezervat de dvs. si apasarea lui va anula rezervarea respectiva. Obligatoriu veti trece si motivul renuntarii la cumparare.",
    GoodsForSaleUploadProofOfPaymentBtnInfo = "Butonul este activ cand selectati un bun rezervat de dvs. si apasarea lui va permite incarcarea dovezii de plata pentru bunul rezervat. Incarcarea dovezii de plata va trimite automat in pasul urmator solicitarea de cumparare.",
 }