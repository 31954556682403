import { Component } from '@angular/core';
import { GenericTableList } from '../../generic/generic.table.list';
import { Employee } from '../../../model/api/administration/employee';
import { AppData } from '../../../app-data';
import { Param } from '../../../model/common/param';
import { GenericPrimeNgDataTableList } from '../../generic/generic.prime-ng-data-table.list';

@Component({
    selector: 'app-employee-allocate-list',
    //templateUrl: '../../generic/generic.table.list.html',
    templateUrl: '../../generic/generic.prime-ng-data-table.list.html',
})
// export class EmployeeAllocateListComponent extends GenericTableList<Employee, number> {
export class EmployeeAllocateListComponent extends GenericPrimeNgDataTableList<any, number> {
    private listName: string = 'EMPLOYEEALLOCATE';

    constructor() {
        super('internalCode', 'asc', '');
        this.columns = AppData.ColumnDefinitions[this.listName];
    }

    public refresh(filters: Array<Param>) {
        this.columns = AppData.ColumnDefinitions[this.listName];
        super.refresh(filters);
    }

    public resetSort() {
        this.dt1.sortOrder = 0;
        this.dt1.sortField = undefined;
        this.dt1.reset();
    }   

    public resetRows(rows) {
        this.dt1.rows = rows;
    } 
}
