import { Param } from './../../../model/common/param';
import { Component, ViewChild } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { saveAs as fileSaveAs } from 'file-saver-es';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Model } from '../../../model/api/assets/model';
import { ModelList } from './model.list';
import { ModelDetail } from './model.detail';
import { ModelHttpService } from '../../../services/http/assets/model.http.service';
import { BrandHttpService } from '../../../services/http/assets/brand.http.service';
import { BrandList } from '../brands/brand.list';
import { CodeNameEntity } from '../../../model/api/common/code-name-entity';
import { Brand } from '../../../model/api/assets/brand';
import { AppUtils } from '../../../common/app.utils';

@Component({
    selector: 'model-manage',
    templateUrl: 'model.manage.html',
    providers: [BrandHttpService]
})
export class ModelManage extends GenericManage<Model, number> {

    @ViewChild('modelDetailModal') modelDetailModal: ModalDirective;
    @ViewChild('modelList') modelList: ModelList;
    @ViewChild('modelDetail') modelDetail: ModelDetail;

    @ViewChild('brandListModal') brandListModal: ModalDirective;
    @ViewChild('brandList') brandList: BrandList;

    public selectedBrand: Brand = null;

    public filter: string = '';
    isCollapsed: boolean = true;
    constructor(
        public modelHttpService: ModelHttpService, public brandHttpService: BrandHttpService) {
        super();
    }

    public addNewItem() {
        super.addNewItem();

        this.modelDetail.brand = null;
    }

    public editItem() {
        super.editItem();

        const model: Model = this.selectedItem as Model;

        this.modelDetail.brand = null;

        if (model != null && model.brand != null) {
            this.brandHttpService
                .getById(model.brand.id)
                .subscribe((brand: Brand) => {
                    this.modelDetail.brand = brand;
                });
        }
    }

    public detailInitialize() {
        super.detailInitialize();
        this.modelDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.modelDetailModal.hide();
    }

    public onModelDetailBrandNeeded() {
        this.modelDetailModal.hide();
        this.selectBrand();
    }

    public onBrandListCancel() {
        this.brandListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.modelDetailModal.show();
        }
    }


    public refresh() {
        let params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        params.push(new Param("brandIds", AppUtils.getIdsList<Brand, number>([ this.selectedBrand ])));

        this.modelList.refresh(params);
    }

    public selectBrand() {
        this.brandListModal.show();
        this.brandList.refresh(null);
    }

    public setSelectedBrand() {
        switch(this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedBrand = this.brandList.selectedItem;
                this.brandListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.modelDetail.brand = this.brandList.selectedItem;
                this.brandListModal.hide();
                this.modelDetailModal.show();
                break;
        }
    }

    // public exportToExcel(){

    //      let params: Array<Param> = null;

    //     if ((this.filter != null) && (this.filter.length > 0)) {
    //         params = new Array<Param>();
    //         params.push(new Param('filter', this.filter));
    //     }

    //     this.locationHttpService.get(1, 10000000, 'code', 'asc', params, null).subscribe(
    //         (data: PagedResult<Location>) => {

    //             let options = {
    //                 sheetid: 'Buildings',
    //                 headers: true,
    //                 column: { style: { Font: { Bold: '1' } } },
    //                 rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
    //                 cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
    //             };

    //             alasql(`SELECT id as [Id],
    //                 code as [Cod],
    //                 name as [Denumire],
    //                 region->name as [Judet],
    //                 admCenter->name as [Regiune]
    //                 INTO XLSX("Buildings.xlsx",?) FROM ?`, [ options, data.items ]);

    //         });

    // }

    public getFilters(): Array<Param> {
        let params: Array<Param> = new Array<Param>();
        params.push(new Param('filter', this.filter));

        return params;
    }

    public exportToExcel() {

        let params: Array<Param> = null;

        params = this.getFilters();
        this.modelHttpService
            .export(params)
            .subscribe((blob) => {
                fileSaveAs(blob, 'model.xlsx');
            });
    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }
}
