import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AccMonthManageComponent } from './forms/accounting/acc-month.manage';
import { AccountManage } from './forms/administrations/account/account.manage';
import { AccountancyManageComponent } from './forms/administrations/accountancy/accountancy.manage';
import { AdmCenterManageComponent } from './forms/administrations/adm-centers/adm-center.manage';
import { AdministrationManageComponent } from './forms/administrations/administrations/administration.manage';
import { AreaManageComponent } from './forms/administrations/areas/area.manage';
import { ArticleManage } from './forms/administrations/article/article.manage';
import { BudgetBaseAddDetailUIComponent } from './forms/administrations/budget-base/budget-base.add-detail.ui';
import { BudgetBaseDetailUIComponent } from './forms/administrations/budget-base/budget-base.detail.ui';
import { BudgetBaseManageComponent } from './forms/administrations/budget-base/budget-base.manage';
import { BudgetForecastManageComponent } from './forms/administrations/budget-forecast/budget-forecast.manage';
import { BudgetManagerManage } from './forms/administrations/budget-manager/budget-manager.manage';
import { BudgetMonthBaseManageComponent } from './forms/administrations/budget-month-base/budget-month-base.manage';
import { BudgetDetailUI } from './forms/administrations/budget/budget.detail.ui';
import { BudgetManage } from './forms/administrations/budget/budget.manage';
import { CityManageComponent } from './forms/administrations/cities/city.manage';
import { ContractDetailUIComponent } from './forms/administrations/contracts/contract.detail.ui';
import { ContractManageComponent } from './forms/administrations/contracts/contract.manage';
import { CostCenterManageComponent } from './forms/administrations/cost-centers/cost-center.manage';
import { CountyManageComponent } from './forms/administrations/counties/county.manage';
import { CountryManageComponent } from './forms/administrations/countries/country.manage';
import { DepartmentManageComponent } from './forms/administrations/departments/department.manage';
import { DictionaryItemManageComponent } from './forms/administrations/dictionary-item/dictionary-item.manage';
import { DictionaryTypeManageComponent } from './forms/administrations/dictionary-type/dictionary-type.manage';
import { DivisionManageComponent } from './forms/administrations/divisions/division.manage';
import { EmailManagerManageComponent } from './forms/administrations/email-manager/email-manager.manage';
import { EmailTypeManage } from './forms/administrations/email-type/email-type.manage';
import { EmployeeAllocateManageComponent } from './forms/administrations/employee-allocates/employee-allocate.manage';
import { EmployeeCostCenterManage } from './forms/administrations/employee-cost-centers/employee-cost-center.manage';
import { EmployeeDetailUIComponent } from './forms/administrations/employees/employee.detail.ui';
import { EmployeeManageComponent } from './forms/administrations/employees/employee.manage';
import { ExpAccountManage } from './forms/administrations/exp-account/exp-account.manage';
import { LevelManageComponent } from './forms/administrations/level/level.manage';
import { LocationManageComponent } from './forms/administrations/locations/location.manage';
import { MaterialManageComponent } from './forms/administrations/materials/material.manage';
import { MatrixLevelManageComponent } from './forms/administrations/matrix-level/matrix-level.manage';
import { MatrixManageComponent } from './forms/administrations/matrix/matrix.manage';
import { OfferMaterialManageComponent } from './forms/administrations/offer-materials/offer-material.manage';
import { OfferDetailUIComponent } from './forms/administrations/offer/offer.detail.ui';
import { OfferManage } from './forms/administrations/offer/offer.manage';
import { OrderMaterialManageComponent } from './forms/administrations/order-materials/order-material.manage';
import { OrderEditDetailUIComponent } from './forms/administrations/order/order-edit.detail.ui';
import { OrderDetailUIComponent } from './forms/administrations/order/order.detail.ui';
import { OrderManageComponent } from './forms/administrations/order/order.manage';
import { ProjectTypeDivisionManageComponent } from './forms/administrations/project-type-division/project-type-division.manage';
import { RegionManageComponent } from './forms/administrations/regions/region.manage';
import { RequestDetailUIComponent } from './forms/administrations/request/request.detail.ui';
import { RequestManageComponent } from './forms/administrations/request/request.manage';
import { RoomManageComponent } from './forms/administrations/rooms/room.manage';
import { StockManageComponent } from './forms/administrations/stocks/stock.manage';
import { SubTypeManage } from './forms/administrations/sub-types/sub-type.manage';
import { TypeManage } from './forms/administrations/types/type.manage';
import { ActivityManage } from './forms/assets/activities/activity.manage';
import { AppStateManageComponent } from './forms/assets/app-states/app-state.manage';
import { AssetOpRecoManageComponent } from './forms/assets/asset--reco-ops/asset-reco-op-manage';
import { AssetCategoryManageComponent } from './forms/assets/asset-categories/asset-category.manage';
import { AssetClassManageComponent } from './forms/assets/asset-classes/asset-class.manage';
import { AssetComponentManage } from './forms/assets/asset-components/asset-component.manage';
import { AssetOpHistoryManageComponent } from './forms/assets/asset-history-ops/asset-op-history-manage';
import { AssetNatureManageComponent } from './forms/assets/asset-natures/asset-nature.manage';
import { AssetOpManageComponent } from './forms/assets/asset-ops/asset-op-manage';
import { AssetStateManageComponent } from './forms/assets/asset-states/asset-state.manage';
import { AssetTypeManageComponent } from './forms/assets/asset-types/asset-type.manage';
import { AssetAccountingMFViewComponent } from './forms/assets/assets/asset-accounting-mf.view';
import { AssetCFOManageComponent } from './forms/assets/assets/asset-closed.manage';
import { AssetEmployeeAllocateApproveDetailUIComponent } from './forms/assets/assets/asset-employee-allocate-approve.detail.ui';
import { AssetEmployeeManagerDetailUIComponent } from './forms/assets/assets/asset-employee-manager.detail.ui';
import { AssetEmployeePersonalDetailUIComponent } from './forms/assets/assets/asset-employee-personal.detail.ui';
import { AssetEmployeeTransferFromDetailUIComponent } from './forms/assets/assets/asset-employee-transfer-from.detail.ui';
import { AssetEmployeeDetailUIComponent } from './forms/assets/assets/asset-employee.detail.ui';
import { AssetErrorManageComponent } from './forms/assets/assets/asset-error.manage';
import { AssetExtraseViewComponent } from './forms/assets/assets/asset-extrase.view';
import { AssetServiceManageComponent } from './forms/assets/assets/asset-inv-plus.manage';
import { AssetInventoryEmailManage } from './forms/assets/assets/asset-inventory-email.manage';
import { AssetInventoryEmployeeValidateManage } from './forms/assets/assets/asset-inventory-employee-validate.manage';
import { AssetInventoryReportComponent } from './forms/assets/assets/asset-inventory-report';
import { AssetInventoryManageComponent } from './forms/assets/assets/asset-inventory.manage';
import { AssetGNFRManageComponent } from './forms/assets/assets/asset-reception.manage';
import { AssetRejectedManageComponent } from './forms/assets/assets/asset-rejected.manage';
import { AssetScrapManageComponent } from './forms/assets/assets/asset-scrap.manage';
import { AssetSoldManageComponent } from './forms/assets/assets/asset-sold.manage';
import { AssetExtraseToValidateManageComponent } from './forms/assets/assets/asset-stock-it-mfx.manage';
import { AssetAccountingMFManageComponent } from './forms/assets/assets/asset-stock-it-to-validate-employee.manage';
import { AssetStockITToValidateManageComponent } from './forms/assets/assets/asset-stock-it-to-validate.manage';
import { AssetITDeleteDataManageComponent } from './forms/assets/assets/asset-stock-it.manage';
import { AssetStockDetailUIAddComponent } from './forms/assets/assets/asset-stock.detail-add.ui';
import { AssetBuyHistoryManageComponent } from './forms/assets/assets/asset-suspended-history.manage';
import { AssetBuyManageComponent } from './forms/assets/assets/asset-suspended.manage';
import { AssetToValidateManageComponent } from './forms/assets/assets/asset-to-validate.manage';
import { AssetWFHManageComponent } from './forms/assets/assets/asset-wfh.manage';
import { AssetDetailUIAcquisitionOperationStornoComponent } from './forms/assets/assets/asset.detail-acquisition-operation-storno.ui';
import { AssetDetailUIAddComponent } from './forms/assets/assets/asset.detail-add.ui';
import { AssetDetailChangeUIComponent } from './forms/assets/assets/asset.detail-change.ui';
import { AssetDetailErorrUIAddComponent } from './forms/assets/assets/asset.detail-error-add.ui';
import { AssetDetailUIOperationCassationComponent } from './forms/assets/assets/asset.detail-operation-cassation.ui';
import { AssetDetailUIOperationCloneTransferComponent } from './forms/assets/assets/asset.detail-operation-clone-transfer.ui';
import { AssetDetailUIOperationStornoComponent } from './forms/assets/assets/asset.detail-operation-storno.ui';
import { AssetDetailUIOperationTransferComponent } from './forms/assets/assets/asset.detail-operation-transfer.ui';
import { AssetDetailUIValidateInvPlusComponent } from './forms/assets/assets/asset.detail-validate-inv-plus.ui';
import { AssetDetailUIValidateComponent } from './forms/assets/assets/asset.detail-validate.ui';
import { AssetDetailUIComponent } from './forms/assets/assets/asset.detail.ui';
import { AssetManageComponent } from './forms/assets/assets/asset.manage';
import { BrandManage } from './forms/assets/brands/brand.manage';
import { CategoryENManageComponent } from './forms/assets/categories-en/category-en.manage';
import { CategoryManageComponent } from './forms/assets/categories/category.manage';
import { CompanyManageComponent } from './forms/assets/companies/company.manage';
import { DimensionManageComponent } from './forms/assets/dimensions/dimension.manage';
import { InsuranceCategoryManage } from './forms/assets/insurance-categories/insurance-category.manage';
import { InterCompanyENManageComponent } from './forms/assets/inter-companies-en/inter-company-en.manage';
import { InterCompanyManageComponent } from './forms/assets/inter-companies/inter-company.manage';
import { ModelManage } from './forms/assets/models/model.manage';
import { ProjectTypeManageComponent } from './forms/assets/project-types/project-type.manage';
import { ProjectManage } from './forms/assets/projects/project.manage';
import { RateManageComponent } from './forms/assets/rates/rate.manage';
import { SubCategoryENManageComponent } from './forms/assets/sub-categories-en/sub-category-en.manage';
import { SubCategoryManageComponent } from './forms/assets/sub-categories/sub-category.manage';
import { TaxManageComponent } from './forms/assets/taxs/tax.manage';
import { UomManageComponent } from './forms/assets/uoms/uom.manage';
import { IdentityManage } from './forms/auth/identity.manage';
import { BadgeManage } from './forms/common/badge/badge.manage';
import { ColumnDefinitionManageComponent } from './forms/common/column-definition/column-definition.manage';
import { ConfigValuesManage } from './forms/common/config-values.manage';
import { DeviceTypeManage } from './forms/common/device-types/device-type.manage';
import { DeviceManage } from './forms/common/devices/device.manage';
import { EmailStatusDstEmployeeValidatePageComponent } from './forms/common/email-status-dst-employee-validate-page';
import { EmailStatusDstEmployeeRejectPageComponent } from './forms/common/email-status-dst-employee-reject-page';
import { EmailStatusDstEmployeeAllocateApprovePageComponent } from './forms/common/email-status-dst-employee-allocate-approve-page';
import { EmailStatusDstEmployeeAllocateRejectPageComponent } from './forms/common/email-status-dst-employee-allocate-reject-page';
import { ErrorManageComponent } from './forms/common/errors/error.manage';
import { LocationMap } from './forms/common/maps/maps';
import { PermissionRoleManage } from './forms/common/permission-role/permission-role.manage';
import { PermissionTypeManage } from './forms/common/permission-type/permission-type.manage';
import { PermissionManage } from './forms/common/permission/permission.manage';
import { RequestNotValidatePageComponent } from './forms/common/request-not-validate-page';
import { RouteChildManageComponent } from './forms/common/route-child-definition/route-child.manage';
import { RouteManageComponent } from './forms/common/route-definition/route.manage';
import { TableDefinitionManageComponent } from './forms/common/table-definition/table-definition.manage';
import { OperationDetail } from './forms/documents/operations/operation.detail';
import { PartnerManageComponent } from './forms/documents/partners/partner.manage';
import { InfoManageComponent } from './forms/info/info.manage';
import { InvStateManage } from './forms/inventory/inv-state/inv-state.manage';
import { InventoryManage } from './forms/inventory/inventory.manage';
import { OfferTypeManageComponent } from './forms/offers/offer-type/offer-type.manage';
import { OrderTypeManageComponent } from './forms/orders/order-type/order-type.manage';
import { AquisitionAssetSAPManageComponent } from './forms/sap/acquisition-asset-sap/acquisition-asset-sap.manage';
import { AmortizationManageComponent } from './forms/sap/amortization/amortization.manage';
import { AssetChangeSAPManageComponent } from './forms/sap/asset-change-sap/asset-change-sap.manage';
import { AssetInvMinusSAPManageComponent } from './forms/sap/asset-inv-minus-sap/asset-inv-minus-sap.manage';
import { AssetInvPlusSAPManageComponent } from './forms/sap/asset-inv-plus-sap/asset-inv-plus-sap.manage';
import { CapAmortizationManageComponent } from './forms/sap/cap-amortization/cap-amortization.manage';
import { CreateAssetSAPManageComponent } from './forms/sap/create-asset-sap/create-asset-sap.manage';
import { RetireAssetSAPManageComponent } from './forms/sap/retire-asset-sap/retire-asset-sap.manage';
import { TransferAssetSAPManageComponent } from './forms/sap/transfer-asset-sap/transfer-asset-sap.manage';
import { TransferInStockSAPManageComponent } from './forms/sap/transfer-in-stock-sap/transfer-in-stock-sap.manage';
import { AuthGuard } from './services/auth.guard';
import { DashboardAssetComponent } from './views/dashboard/Asset/dashboard-asset.component';
import { DashboardBudgetAssetComponent } from './views/dashboard/Budget/dashboard-budget-asset.component';
import { DashboardBudgetComponent } from './views/dashboard/Budget/dashboard-budget.component';
import { DashboardInventoryMarkComponent } from './views/dashboard/dashboard-inventory-mark.component';
import { DashboardInventoryComponent } from './views/dashboard/dashboard-inventory.component';
import { DashboardOfferComponent } from './views/dashboard/dashboard-offer.component';
import { DashboardOrderComponent } from './views/dashboard/dashboard-order.component';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ConfirmEmailPage } from './views/reset-password/confirm-email-page';
import { ErrorConfirmEmailPage } from './views/reset-password/error-confirm-email-page';
import { ErrorResetEmailPage } from './views/reset-password/error-reset-email-page';
import { PasswordEmailReset } from './views/reset-password/password-email-reset';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { AssetOpTempManageComponent } from './forms/assets/asset-temp-ops/asset-op-temp-manage';
import { AssetMarketPlaceManageComponent } from './forms/assets/assets/asset-marketplace.manage';
import { AssetMarketPlaceDetailUIComponent } from './forms/assets/assets/asset.market-place-detail.ui';
import { AssetServiceOpHistoryManageComponent } from './forms/assets/asset-service-history-ops/asset-service-history-ops-manage';
import { EmployeeErrorManageComponent } from './forms/administrations/employee-errors/employee-error.manage';
import { AuditAssetListComponent } from './forms/assets/assets/audit.asset.list';
import { AuditemployeeListComponent } from './forms/assets/assets/audit.employee.list';
import { AuditSaleListComponent } from './forms/assets/assets/audit.sale.list';
import { AssetExtraseToValidateViewDetail } from './forms/assets/assets/asset-extrasetovalidate.view';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'passwordreset/:userName',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard],
    data: {
      title: '',
      auth: "RESET|RESETPASSWORD", shouldDetach: true
    },
  },
  { path: 'passwordemailreset', component: PasswordEmailReset, data: { title: '' } },
  { path: 'confirmemail', component: ConfirmEmailPage },
  { path: 'errorconfirmemail', component: ErrorConfirmEmailPage },
  { path: 'errorresetemail', component: ErrorResetEmailPage },
  // {
  //   path: '',
  //   component: DefaultLayoutComponent,
  //   data: {
  //     title: ''
  //   },
  //   children: [
  //     // {
  //     //   path: 'base',
  //     //   loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
  //     // },
  //     // {
  //     //   path: 'buttons',
  //     //   loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
  //     // },
  //     // {
  //     //   path: 'charts',
  //     //   loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
  //     // },
  //     // {
  //     //   path: 'dashboard',
  //     //   loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  //     // },
  //     // {
  //     //   path: 'map',
  //     //   loadChildren: () => import('./views/maps/maps.module').then(m => m.MapsModule)
  //     // },
  //     // {
  //     //   path: 'floor',
  //     //   loadChildren: () => import('./views/floors/floors.module').then(m => m.FloorsModule)
  //     // },
  //     // {
  //     //   path: 'budget',
  //     //   loadChildren: () => import('./views/budgets/budgets.module').then(m => m.BudgetsModule)
  //     // },
  //     // {
  //     //   path: 'offer',
  //     //   loadChildren: () => import('./views/offers/offers.module').then(m => m.OffersModule)
  //     // },
  //     // {
  //     //   path: 'order',
  //     //   loadChildren: () => import('./views/orders/orders.module').then(m => m.OrdersModule)
  //     // },
  //     // {
  //     //   path: 'asset',
  //     //   loadChildren: () => import('./views/assets/assets.module').then(m => m.AssetsModule)
  //     // },
  //     // {
  //     //   path: 'assetcomponent',
  //     //   loadChildren: () => import('./views/assetcomponents/assetcomponents.module').then(m => m.AssetComponentsModule)
  //     // },
  //     // {
  //     //   path: 'employee',
  //     //   loadChildren: () => import('./views/employees/employees.module').then(m => m.EmployeesModule)
  //     // },
  //     // {
  //     //   path: 'wfh',
  //     //   loadChildren: () => import('./views/wfhs/wfhs.module').then(m => m.WfhsModule)
  //     // },
  //     // {
  //     //   path: 'inventory',
  //     //   loadChildren: () => import('./views/inventories/inventories.module').then(m => m.InventoriesModule)
  //     // },
  //     // {
  //     //   path: 'email',
  //     //   loadChildren: () => import('./views/emails/emails.module').then(m => m.EmailsModule)
  //     // },
  //     // {
  //     //   path: 'operation',
  //     //   loadChildren: () => import('./views/operations/operations.module').then(m => m.OperationsModule)
  //     // },
  //     // {
  //     //   path: 'admin',
  //     //   loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule)
  //     // },
  //     // {
  //     //   path: 'icons',
  //     //   loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
  //     // },
  //     // {
  //     //   path: 'notifications',
  //     //   loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
  //     // },
  //     // {
  //     //   path: 'configs',
  //     //   loadChildren: () => import('./views/configs/configs.module').then(m => m.ConfigsModule)
  //     // },
  //     // {
  //     //   path: 'routes',
  //     //   loadChildren: () => import('./views/routes/routes.module').then(m => m.RoutesModule)
  //     // },
  //     // {
  //     //   path: 'nomenclatures',
  //     //   loadChildren: () => import('./views/nomenclatures/nomenclatures.module').then(m => m.NomenclaturesModule)
  //     // },
  //     // {
  //     //   path: 'theme',
  //     //   loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
  //     // },
  //     // {
  //     //   path: 'widgets',
  //     //   loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
  //     // }
  //   ]
  // },

  // INFO //

  { path: 'info', component: InfoManageComponent, data: { title: 'Informatii' }},

  // INFO //

  // DASHBOARD //

  { path: 'dashboard/assets', component: DashboardAssetComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|S_DASHBOARD_BUDGET', shouldDetach: true }},
  { path: 'dashboard/budget', component: DashboardBudgetComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|S_DASHBOARD_BUDGET', shouldDetach: true }},
  { path: 'dashboard/budgetasset', component: DashboardBudgetAssetComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|S_DASHBOARD_BUDGET', shouldDetach: true }},
  { path: 'dashboard/offer', component: DashboardOfferComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|S_DASHBOARD_OFFER', shouldDetach: true }},
  { path: 'dashboard/order', component: DashboardOrderComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|S_DASHBOARD_ORDER', shouldDetach: true }},
  { path: 'dashboard/inventory', component: DashboardInventoryComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|S_DASHBOARD_INVENTORY', shouldDetach: true }},
  { path: 'dashboard/inventorymark', component: DashboardInventoryMarkComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|S_DASHBOARD_INVENTORY_MARK', shouldDetach: true }},

  // DASHBOARDS //

    // SAP //

    { path: 'sap', component: AmortizationManageComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|AMORTIZATIONS', shouldDetach: true }},
    { path: 'sap/createassetsap', component: CreateAssetSAPManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|CREATEASSETSAPS', shouldDetach: false }},
    { path: 'sap/aquisitionassetsap', component: AquisitionAssetSAPManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|AQUISITIONASSETSAPS', shouldDetach: false }},
    { path: 'sap/assetchangesap', component: AssetChangeSAPManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|ASSETCHANGESAPS', shouldDetach: false }},
    { path: 'sap/assetinvminussap', component: AssetInvMinusSAPManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|ASSETINVMINUSSAPS', shouldDetach: false }},
    { path: 'sap/assetinvplussap', component: AssetInvPlusSAPManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|ASSETINVPLUSSAPS', shouldDetach: false }},
    { path: 'sap/retireassetsap', component: RetireAssetSAPManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|RETIREASSETSAPS', shouldDetach: false }},
    { path: 'sap/transferassetsap', component: TransferAssetSAPManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|TRANSFERASSETSAPS', shouldDetach: false }},
    { path: 'sap/transferinstocksap', component: TransferInStockSAPManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|TRANSFERINSTOCKSAPS', shouldDetach: false }},
    { path: 'sap/amortization', component: AmortizationManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|AMORTIZATIONS', shouldDetach: false }},
    { path: 'sap/capamortization', component: CapAmortizationManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|CAPAMORTIZATIONS', shouldDetach: false }},
    { path: 'sap/errors', component: ErrorManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|ERRORS', shouldDetach: false }},
    { path: 'sap/rates', component: RateManageComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'VIEW|RATES', shouldDetach: false }},

    // SAP //

  // MAPS //

  { path: 'map', component: LocationMap, canActivate: [AuthGuard], data: { title: 'Harti', auth: 'VIEW|MAP', shouldDetach: true }},

  // MAPS //

    // MATRIX //

    { path: 'matrix/status', component: MatrixManageComponent, canActivate: [AuthGuard], data: { title: 'Matrice', auth: 'VIEW|MATRIX', shouldDetach: true }},
    { path: 'matrix/level', component: LevelManageComponent, canActivate: [AuthGuard], data: { title: 'Niveluri', auth: 'VIEW|LEVELS', shouldDetach: true }},
    { path: 'matrix/mapping', component: MatrixLevelManageComponent, canActivate: [AuthGuard], data: { title: 'Mapare nivel', auth: 'VIEW|MATRIXLEVELS', shouldDetach: true }},
    { path: 'matrix/projects', canActivate: [AuthGuard], component: ProjectManage, data: { title: 'WBS', auth: 'VIEW|PROJECTS', shouldDetach: true }},
    { path: 'matrix/projecttypes', component: ProjectTypeManageComponent, canActivate: [AuthGuard], data: { title: 'Proiecte', auth: 'VIEW|PROJECTTYPES', shouldDetach: true }},
    { path: 'matrix/projecttypedivisions', component: ProjectTypeDivisionManageComponent, canActivate: [AuthGuard], data: { title: 'Proiecte/Departamente', auth: 'VIEW|PROJECTTYPEDIVISIONS', shouldDetach: true }},
    { path: 'matrix/accountancies', canActivate: [AuthGuard], component: AccountancyManageComponent, data: { title: 'Inventare', auth: 'VIEW|ACCOUNTANCIES', shouldDetach: true }},
    { path: 'matrix/intercompanies', canActivate: [AuthGuard], component: InterCompanyManageComponent, data: { title: 'Supracategorii TRN', auth: 'VIEW|INTERCOMPANIES', shouldDetach: true }},
    { path: 'matrix/intercompaniesen', canActivate: [AuthGuard], component: InterCompanyENManageComponent, data: { title: 'Supracategorii EN', auth: 'VIEW|INTERCOMPANIESEN', shouldDetach: true }},
    { path: 'matrix/categories', canActivate: [AuthGuard], component: CategoryManageComponent, data: { title: 'Categorii', auth: 'VIEW|CATEGORIES', shouldDetach: true }},
    { path: 'matrix/subcategories', canActivate: [AuthGuard], component: SubCategoryManageComponent, data: { title: 'SubCategorii', auth: 'VIEW|SUBCATEGORIES', shouldDetach: true }},
    { path: 'matrix/categoriesen', canActivate: [AuthGuard], component: CategoryENManageComponent, data: { title: 'Categorii EN', auth: 'VIEW|CATEGORIESEN', shouldDetach: true }},
    { path: 'matrix/subcategoriesen', canActivate: [AuthGuard], component: SubCategoryENManageComponent, data: { title: 'SubCategorii EN', auth: 'VIEW|SUBCATEGORIESEN', shouldDetach: true }},
    { path: 'matrix/accmonths', canActivate: [AuthGuard], component: AccMonthManageComponent, data: { title: 'Inventare', auth: 'VIEW|ACCMONTHS', shouldDetach: true }},

    // MATRIX //

  // BUDGETS //

  { path: 'budget/report', component: BudgetManage, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|BUDGETBASE', shouldDetach: true }},
  { path: 'budgetbase/:id', component: BudgetBaseDetailUIComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'EDIT|BUDGETBASE', shouldDetach: false }},
  { path: 'budgetbaseadd/new', component: BudgetBaseAddDetailUIComponent, canActivate: [AuthGuard], data: {  title: '', auth: 'ADD|BUDGETBASE', shouldDetach: false }},
  { path: 'budget/status', component: BudgetBaseManageComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|BUDGETBASE', shouldDetach: true }},
  { path: 'budget/month', component: BudgetMonthBaseManageComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|BUDGETMONTHBASE', shouldDetach: true }},
  { path: 'budget/forecast', component: BudgetForecastManageComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|BUDGETFORECAST', shouldDetach: true }},

  // BUDGETS //


    // PROCUREMENT //

    { path: 'procurement/request', canActivate: [AuthGuard], component: RequestManageComponent, data: { title: '', auth: 'VIEW|REQUEST', shouldDetach: true}},
    { path: 'procurement/request/:id', component: RequestDetailUIComponent, canActivate: [AuthGuard], data: { title: '', auth: 'EDIT|REQUEST', shouldDetach: false }},
    { path: 'procurement/request/new', component: RequestDetailUIComponent, canActivate: [AuthGuard], data: { title: '', auth: 'ADD|REQUEST', shouldDetach: false }},
    { path: 'procurement/catalog', canActivate: [AuthGuard], component: MaterialManageComponent, data: { title: '', auth: 'VIEW|MATERIALS', shouldDetach: false }},
    { path: 'procurement/partners', canActivate: [AuthGuard], component: PartnerManageComponent, data: { title: 'Suppliers', auth: 'VIEW|PARTNERS', shouldDetach: true }},
    { path: 'requestbudgetnotvalidate/:guidRequest/:id', component: RequestNotValidatePageComponent, data: { title: 'Refuz tichet buget' }},
    { path: 'requestbudgetnotvalidate', component: RequestNotValidatePageComponent, data: { title: 'Refuz buget' }},
      // OFFERS //

    { path: 'procurement/offer/status', canActivate: [AuthGuard], component: OfferManage, data: { title: '', auth: 'VIEW|S_OFFER_STATUS', shouldDetach: true }},
    { path: 'procurement/offer/email', canActivate: [AuthGuard], component: EmailManagerManageComponent, data: { title: '', auth: 'VIEW|S_OFFER_EMAIL', shouldDetach: true }},
    { path: 'procurement/offer/:id', component: OfferDetailUIComponent, canActivate: [AuthGuard], data: { title: '', auth: 'EDIT|OFFER', shouldDetach: false }},
    { path: 'procurement/offer/new', component: OfferDetailUIComponent, canActivate: [AuthGuard], data: { title: '', auth: 'ADD|OFFER', shouldDetach: false }},
    { path: 'offermaterials', canActivate: [AuthGuard], component: OfferMaterialManageComponent, data: { title: '', auth: 'VIEW|OFFERMATERIALS', shouldDetach: true }},
    { path: 'ordermaterials', canActivate: [AuthGuard], component: OrderMaterialManageComponent, data: { title: '', auth: 'VIEW|ORDERMATERIALS', shouldDetach: true }},
    { path: 'procurement/ordertypes', component: OrderTypeManageComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|ORDERTYPES', shouldDetach: false }},
    { path: 'procurement/offertypes', component: OfferTypeManageComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|OFFERTYPES', shouldDetach: false }},
    // OFFERS //

      // ORDERS //

      { path: 'procurement/order', canActivate: [AuthGuard], component: OrderManageComponent, data: { title: '', auth: 'VIEW|ORDER', shouldDetach: true}},
      { path: 'procurement/order/:id', component: OrderDetailUIComponent, canActivate: [AuthGuard], data: { title: '', auth: 'EDIT|ORDER', shouldDetach: false }},
      { path: 'procurement/orderchange/:id', component: OrderEditDetailUIComponent, canActivate: [AuthGuard], data: { title: '', auth: 'EDIT|ORDER', shouldDetach: false }},
      { path: 'procurement/order/new', component: OrderDetailUIComponent, canActivate: [AuthGuard], data: { title: '', auth: 'ADD|ORDER', shouldDetach: false }},

      // ORDERS //

        // CONTRACTS //

        { path: 'procurement/contract/status', canActivate: [AuthGuard], component: ContractManageComponent, data: { title: '', auth: 'VIEW|S_CONTRACT_STATUS', shouldDetach: true }},
        { path: 'procurement/contract/:id', component: ContractDetailUIComponent, canActivate: [AuthGuard], data: { title: '', auth: 'EDIT|CONTRACT', shouldDetach: false }},
        { path: 'procurement/contract/new', component: ContractDetailUIComponent, canActivate: [AuthGuard], data: { title: '', auth: 'ADD|CONTRACT', shouldDetach: false }},

        // CONTRACTS //

    // PROCUREMENT //

  // ASSET //


  { path: 'asset', component: AssetManageComponent, canActivate: [AuthGuard], data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}},
  { path: 'asset/scrap', canActivate: [AuthGuard], component: AssetScrapManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/sold', canActivate: [AuthGuard], component: AssetSoldManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/cfo', canActivate: [AuthGuard], component: AssetCFOManageComponent, data: { title: '',auth: 'VIEW|ASSET',  shouldDetach: true}, },
  { path: 'asset/wfh', canActivate: [AuthGuard], component: AssetWFHManageComponent, data: { title: '',auth: 'VIEW|ASSET',  shouldDetach: true}, },
  { path: 'asset/service', canActivate: [AuthGuard], component: AssetServiceManageComponent, data: { title: '',auth: 'VIEW|ASSET',  shouldDetach: true}, },
  { path: 'asset/temp', canActivate: [AuthGuard], component: AssetOpTempManageComponent, data: { title: 'Transferuri temporare', auth: 'VIEW|ASSET', shouldDetach: true }},
  { path: 'asset/buy', canActivate: [AuthGuard], component: AssetBuyManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/inuse', canActivate: [AuthGuard], component: AssetManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/error', canActivate: [AuthGuard], component: AssetErrorManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/rejected', canActivate: [AuthGuard], component: AssetRejectedManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/gnfr', canActivate: [AuthGuard], component: AssetGNFRManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/itdeletedata', canActivate: [AuthGuard], component: AssetITDeleteDataManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/extrasetovalidate', canActivate: [AuthGuard], component: AssetExtraseToValidateManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/stockittovalidate', canActivate: [AuthGuard], component: AssetStockITToValidateManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/accountingmf', canActivate: [AuthGuard], component: AssetAccountingMFManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'asset/validate', canActivate: [AuthGuard], component: AssetToValidateManageComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}},
  { path: 'asset/newoperation', canActivate: [AuthGuard],  component: OperationDetail, data: {  title: '', auth: 'ADD_TRANSFER|ASSET', shouldDetach: false }},
  { path: 'asset/:id', canActivate: [AuthGuard], component: AssetDetailUIAddComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'asseterror/:id', canActivate: [AuthGuard], component: AssetDetailErorrUIAddComponent, data: { title: '', auth: 'EDIT_ERR|ASSET', shouldDetach: false }},
  { path: 'assetdetail/:id', canActivate: [AuthGuard], component: AssetDetailUIComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'assetmarketplacedetail/:id', canActivate: [AuthGuard], component: AssetMarketPlaceDetailUIComponent, data: { title: '', auth: 'DETAILS|MARKETPLACE', shouldDetach: false }},
  { path: 'assetvalidate/:id', canActivate: [AuthGuard], component: AssetDetailUIValidateComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'assetextraseview/:id', canActivate: [AuthGuard], component: AssetExtraseViewComponent, data: { title: '', auth: 'DETAILS|BUYHISTORY', shouldDetach: false }},
  { path: 'assetextrasetovalidateview/:id', canActivate: [AuthGuard], component: AssetExtraseToValidateViewDetail, data: { title: '', auth: 'DETAILS|BUYHISTORY', shouldDetach: false }},
  { path: 'assetaccountingmfview/:id', canActivate: [AuthGuard], component: AssetAccountingMFViewComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'assetedit/:id', canActivate: [AuthGuard], component: AssetDetailChangeUIComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'assetvalidateinvplus/:id', canActivate: [AuthGuard], component: AssetDetailUIValidateInvPlusComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'assetoperation/:id', canActivate: [AuthGuard], component: AssetDetailUIOperationTransferComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'assetclone/:id', canActivate: [AuthGuard], component: AssetDetailUIOperationCloneTransferComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'assetretire/:id', canActivate: [AuthGuard], component: AssetDetailUIOperationCassationComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'assetstorno/:id', canActivate: [AuthGuard], component: AssetDetailUIOperationStornoComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'assetacquisitionstorno/:id', canActivate: [AuthGuard], component: AssetDetailUIAcquisitionOperationStornoComponent, data: { title: '', auth: 'EDIT|ASSET', shouldDetach: false }},
  { path: 'asset/new', canActivate: [AuthGuard], component: AssetDetailUIAddComponent, data: { title: '', auth: 'ADD|ASSET', shouldDetach: false }},
  { path: 'newfromstock', canActivate: [AuthGuard], component: AssetStockDetailUIAddComponent, data: { title: '', auth: 'ADD|ASSET', shouldDetach: false }},
  { path: 'marketplace', canActivate: [AuthGuard], component: AssetMarketPlaceManageComponent, data: { title: '', auth: 'VIEW|MARKETPLACE', shouldDetach: false }},
  { path: 'servicehistory', canActivate: [AuthGuard], component: AssetServiceOpHistoryManageComponent, data: { title: 'Istoric service', auth: 'VIEW|SERVICEHISTORY', shouldDetach: true }},

  // AUDIT
  { path: 'audit/assets', canActivate: [AuthGuard], component: AuditAssetListComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'audit/users', canActivate: [AuthGuard], component: AuditemployeeListComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },
  { path: 'audit/sales', canActivate: [AuthGuard], component: AuditSaleListComponent, data: { title: '', auth: 'VIEW|ASSET', shouldDetach: true}, },


  // ASSET //

  // ASSETCOMPONENT //

  { path: 'assetcomponent', canActivate: [AuthGuard], component: AssetComponentManage, data: { title: 'Accesorii', auth: 'VIEW|COMPONENT', shouldDetach: true }},

  // ASSETCOMPONENT //

  // EMPLOYEE //

  { path: 'employee', canActivate: [AuthGuard], component: EmployeeManageComponent, data: { title: 'Angajati', auth: 'VIEW|EMPLOYEES', shouldDetach: true }},
  { path: 'employeeerror', canActivate: [AuthGuard], component: EmployeeErrorManageComponent, data: { title: 'Erori Angajati', auth: 'VIEW|EMPLOYEEERRORS', shouldDetach: true }},
  { path: 'employee/:id', component: EmployeeDetailUIComponent, canActivate: [AuthGuard], data: { title: '', auth: 'OPERATION|EMPLOYEES', shouldDetach: false }},
  { path: 'employeecostcenters', canActivate: [AuthGuard], component: EmployeeCostCenterManage, data: { title: 'Angajati/ CC', auth: 'VIEW|EMPLOYEECOSTCENTERS', shouldDetach: true }},
  { path: 'newassetemployee/:id' , component: AssetEmployeeDetailUIComponent, data: { title: 'Validare angajati' } },

  // EMPLOYEE //

  // WFH //

  { path: 'wfh/assets', canActivate: [AuthGuard], component: AssetEmployeeDetailUIComponent, data: { title: '', auth: 'VIEW|S_WFH_VALIDATE', shouldDetach: true }},
  { path: 'wfh/validate', canActivate: [AuthGuard], component: AssetEmployeePersonalDetailUIComponent, data: { title: '', auth: 'VIEW|S_WFH_VALIDATE', shouldDetach: true }},
  { path: 'wfh/employee', canActivate: [AuthGuard], component: EmployeeManageComponent, data: { title: '', auth: 'VIEW|S_WFH_EMPLOYEE', shouldDetach: true }},
  { path: 'wfh/manager', canActivate: [AuthGuard], component: AssetEmployeeManagerDetailUIComponent, data: { title: '', auth: 'VIEW|S_WFH_MANAGER', shouldDetach: true }},
  { path: 'wfh/allocate', canActivate: [AuthGuard], component: EmployeeAllocateManageComponent, data: { title: '', auth: 'VIEW|EMPLOYEES', shouldDetach: true }},
  { path: 'wfh/tranferfrom', canActivate: [AuthGuard], component: AssetEmployeeTransferFromDetailUIComponent, data: { title: '', auth: 'VIEW|S_WFH_FROM_TRANSFER', shouldDetach: true }},
  { path: 'wfh/allocateapprove', canActivate: [AuthGuard], component: AssetEmployeeAllocateApproveDetailUIComponent, data: { title: '', auth: 'VIEW|S_WFH_ALLOCATE_APPROVE', shouldDetach: true }},
  { path: 'wfh/email', canActivate: [AuthGuard], component: AssetInventoryEmailManage, data: { title: '', auth: 'VIEW|S_WFH_STATUS', shouldDetach: true }},
  { path: 'wfh/dictionaryitems', canActivate: [AuthGuard], component: DictionaryItemManageComponent, data: { title: '', auth: 'VIEW|DICTIONARYITEMS', shouldDetach: true }},
  { path: 'wfh/brands', canActivate: [AuthGuard], component: BrandManage, data: { title: '', auth: 'VIEW|BRANDS', shouldDetach: true }},
  { path: 'wfh/models', canActivate: [AuthGuard], component: ModelManage, data: { title: '', auth: 'VIEW|MODELS', shouldDetach: true }},
  { path: 'wfh/emailsettings', canActivate: [AuthGuard], component: EmailTypeManage, data: { title: '', auth: 'VIEW|EMAIL_SETTING', shouldDetach: true }},
  { path: 'wfh/buyhistory', canActivate: [AuthGuard], component: AssetBuyHistoryManageComponent, data: { title: '', auth: 'VIEW|BUYHISTORY', shouldDetach: true}, },
  { path: 'wfh/operationhistory', canActivate: [AuthGuard], component: AssetOpHistoryManageComponent, data: { title: 'Operatii', auth: 'VIEW|OPERATIONHISTORY', shouldDetach: true }},

  // WFH //

  // INVENTORIES //

  { path: 'inventory/status', canActivate: [AuthGuard], component: AssetInventoryManageComponent, data: { title: 'Status', auth: 'VIEW|S_INVENTORY_STATUS', shouldDetach: true }},
  { path: 'inventory/report', canActivate: [AuthGuard], component: AssetInventoryReportComponent, data: { title: 'Rapoarte', auth: 'VIEW|S_INVENTORY_REPORT', shouldDetach: true }},
  { path: 'inventory/recos', canActivate: [AuthGuard], component: AssetOpRecoManageComponent, data: { title: 'Reconcilieri', auth: 'VIEW|OPERATION', shouldDetach: true }},
  // INVENTORIES //



  // OPRATIONS //

  { path: 'operation', canActivate: [AuthGuard], component: AssetOpManageComponent, data: { title: 'Operatii', auth: 'VIEW|OPERATION', shouldDetach: true }},

  // OPERATIONS //

  // ADMIN //

  { path: 'admin', canActivate: [AuthGuard], component: IdentityManage, data: { title: 'Admin', auth: 'VIEW|ADMIN', shouldDetach: false }},

  // ADMIN //

  // CONFIGS //

  { path: 'config/global', component: ConfigValuesManage, data: { title: 'Global'}},
  { path: 'config/table', component: TableDefinitionManageComponent, data: { title: 'Table' }},
  { path: 'config/column', component: ColumnDefinitionManageComponent, data: { title: 'Coloane'}},
  { path: 'config/permissiontype', component: PermissionTypeManage, data: { title: 'Ecran' }},
  { path: 'config/permission', component: PermissionManage, data: { title: 'Permisiuni' }},
  { path: 'config/permissionrole', component: PermissionRoleManage, data: { title: 'Permisiuni/Roluri' }},

  // CONFIGS //

  // ROUTES //

  { path: 'routes/parent', component: RouteManageComponent, data: { title: 'Parinte', shouldDetach: true}},
  { path: 'routes/children', component: RouteChildManageComponent, data: { title: 'Copil', shouldDetach: true }},
  { path: 'routes/badge', component: BadgeManage, data: { title: 'Badges', shouldDetach: true}},

  // ROUTES //

  // NOMENCLATURES //

  { path: 'nom/levels', canActivate: [AuthGuard], component: LevelManageComponent, data: { title: 'Level', auth: 'VIEW|LEVELS', shouldDetach: true }},
  { path: 'nom/inventories', canActivate: [AuthGuard], component: InventoryManage, data: { title: 'Inventare', auth: 'VIEW|INVENTORIES', shouldDetach: true }},
  { path: 'nom/appstates', canActivate: [AuthGuard], component: AppStateManageComponent, data: { title: 'Stari', auth: 'VIEW|APPSTATES', shouldDetach: true }},
  { path: 'nom/companies', canActivate: [AuthGuard], component: CompanyManageComponent, data: { title: 'Categorii', auth: 'VIEW|COMPANIES', shouldDetach: true }},
  { path: 'nom/assetnatures', canActivate: [AuthGuard], component: AssetNatureManageComponent, data: { title: 'Culori', auth: 'VIEW|ASSETNATURES', shouldDetach: true }},
  { path: 'nom/invstates', canActivate: [AuthGuard], component: InvStateManage, data: { title: 'Stari', auth: 'VIEW|INVSTATES', shouldDetach: true }},
  { path: 'nom/assetstates', canActivate: [AuthGuard], component: AssetStateManageComponent, data: { title: 'Tipuri operatii', auth: 'VIEW|ASSETSTATES', shouldDetach: true }},
  { path: 'nom/counties', canActivate: [AuthGuard], component: CountyManageComponent, data: { title: 'Judete', auth: 'VIEW|COUNTIES', shouldDetach: true}},
  { path: 'nom/cities', canActivate: [AuthGuard], component: CityManageComponent, data: { title: 'Orase', auth: 'VIEW|CITIES', shouldDetach: true }},
  { path: 'nom/cities', canActivate: [AuthGuard], component: CityManageComponent, data: { title: 'Orase', auth: 'VIEW|CITIES', shouldDetach: true }},
  { path: 'nom/locations', canActivate: [AuthGuard], component: LocationManageComponent, data: { title: 'Adrese', auth: 'VIEW|LOCATIONS', shouldDetach: true}},
  { path: 'nom/rooms', canActivate: [AuthGuard], component: RoomManageComponent, data: { title: 'Adrese 2',auth: 'VIEW|ROOMS', shouldDetach: true}},
  { path: 'nom/accounts', canActivate: [AuthGuard], component: AccountManage, data: { title: 'Bal.sh.acct APC', auth: 'VIEW|ACCOUNTS', shouldDetach: true }},
  { path: 'nom/expaccounts', canActivate: [AuthGuard], component: ExpAccountManage, data: { title: 'Asset class', auth: 'VIEW|EXPACCOUNTS', shouldDetach: true }},
  { path: 'nom/assetcategories', canActivate: [AuthGuard], component: AssetCategoryManageComponent, data: { title: 'Asset classification', auth: 'VIEW|ASSETCATEGORIES', shouldDetach: true }},
  { path: 'nom/articles', canActivate: [AuthGuard], component: ArticleManage, data: { title: 'Zone', auth: 'VIEW|ARTICLES', shouldDetach: true }},
  { path: 'nom/costcenters', canActivate: [AuthGuard], component: CostCenterManageComponent, data: { title: 'Centre de cost', auth: 'VIEW|COSTCENTERS', shouldDetach: true }},
  { path: 'nom/admcenters', canActivate: [AuthGuard], component: AdmCenterManageComponent, data: { title: 'Profit centers', auth: 'VIEW|ADMCENTERS', shouldDetach: true }},
  { path: 'nom/regions', canActivate: [AuthGuard], component: RegionManageComponent, data: { title: 'PC detaliu', auth: 'VIEW|REGIONS', shouldDetach: true }},
  { path: 'nom/departments', canActivate: [AuthGuard], component: DepartmentManageComponent, data: { title: 'Departamente', auth: 'VIEW|DEPARTMENTS', shouldDetach: true }},
  { path: 'nom/divisions', canActivate: [AuthGuard], component: DivisionManageComponent, data: { title: 'Divizii', auth: 'VIEW|DIVISIONS', shouldDetach: true }},
  { path: 'nom/administrations', canActivate: [AuthGuard], component: AdministrationManageComponent, data: { title: 'Locatii', auth: 'VIEW|ADMINISTRATIONS', shouldDetach: true}},
  { path: 'nom/countries', canActivate: [AuthGuard], component: CountryManageComponent, data: { title: 'Tari', auth: 'VIEW|COUNTRIES', shouldDetach: true }},
  { path: 'nom/budgetmanagers', canActivate: [AuthGuard], component: BudgetManagerManage, data: { title: 'FY Start', auth: 'VIEW|BUDGETMANAGERS', shouldDetach: true}},
  { path: 'nom/assetnatures', canActivate: [AuthGuard], component: AssetNatureManageComponent, data: { title: 'Cont CM', auth: 'VIEW|ASSETNATURES', shouldDetach: true }},
  // { path: 'nom/mastertypes', canActivate: [AuthGuard], component: MasterTypeManageComponent, data: { title: 'PC', auth: 'VIEW|MASTERTYPES', shouldDetach: true }},
  { path: 'nom/types', canActivate: [AuthGuard], component: TypeManage, data: { title: 'Supracategorii', auth: 'VIEW|TYPES', shouldDetach: true }},
  { path: 'nom/stocks', canActivate: [AuthGuard], component: StockManageComponent, data: { title: 'Materiale', auth: 'VIEW|STOCKS', shouldDetach: true }},
  { path: 'nom/subtypes', canActivate: [AuthGuard], component: SubTypeManage, data: { title: 'Tipuri active', auth: 'VIEW|SUBTYPES', shouldDetach: true }},
  { path: 'nom/assetclasses', canActivate: [AuthGuard], component: AssetClassManageComponent, data: { title: 'Clasificari', auth: 'VIEW|ASSETCLASSES', shouldDetach: true}},
  { path: 'nom/dictionarytypes', canActivate: [AuthGuard], component: DictionaryTypeManageComponent, data: { title: 'Tip dictionar', auth: 'VIEW|DICTIONARYTYPES', shouldDetach: true}},
  { path: 'nom/uoms', canActivate: [AuthGuard], component: UomManageComponent, data: { title: 'Simboluri', auth: 'VIEW|UOMS', shouldDetach: true }},
  { path: 'nom/dimensions', canActivate: [AuthGuard], component: DimensionManageComponent, data: { title: 'Dimensiuni', auth: 'VIEW|DIMENSIONS', shouldDetach: true}},
  { path: 'nom/insurancecategories', canActivate: [AuthGuard], component: InsuranceCategoryManage, data: { title: 'BS', auth: 'VIEW|INSURANCECATEGORIES', shouldDetach: true }},
  { path: 'nom/assettypes', canActivate: [AuthGuard], component: AssetTypeManageComponent, data: { title: 'Tipuri', auth: 'VIEW|ASSETTYPES', shouldDetach: true }},
  { path: 'nom/activities', canActivate: [AuthGuard], component: ActivityManage, data: { title: 'WBS', auth: 'VIEW|ACTIVITIES', shouldDetach: true }},
  { path: 'nom/taxs', canActivate: [AuthGuard], component: TaxManageComponent, data: { title: 'Tax', auth: 'VIEW|TAXS', shouldDetach: true }},
  { path: 'nom/devicetypes', canActivate: [AuthGuard], component: DeviceTypeManage, data: { title: 'OS', auth: 'VIEW|DEVICETYPES', shouldDetach: true }},
  { path: 'nom/devices', canActivate: [AuthGuard], component: DeviceManage, data: { title: 'OS', auth: 'VIEW|DEVICES', shouldDetach: true }},
  { path: 'nom/areas', canActivate: [AuthGuard], component: AreaManageComponent, data: { title: 'Area', auth: 'VIEW|AREAS', shouldDetach: true }},
  // NOMENCLATURES //

  // EMAIL STATUS //
  
  { path: 'dstemployeevalidate/:guid', component: EmailStatusDstEmployeeValidatePageComponent, data: { title: 'Validare angajat' }},
  { path: 'dstemployeereject/:guid', component: EmailStatusDstEmployeeRejectPageComponent, data: { title: 'Refuz angajat' }},

  { path: 'dstemployeeallocateapprove/:guid', component: EmailStatusDstEmployeeAllocateApprovePageComponent, data: { title: 'Acceptare drepturi' }},
  { path: 'dstemployeeallocatereject/:guid', component: EmailStatusDstEmployeeAllocateRejectPageComponent, data: { title: 'Refuz drepturi' }},
  // EMAIL STATUS //


  { path: '**', component: P404Component },

];

@NgModule({
  // imports: [ RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }) ],
  imports: [ RouterModule.forRoot(AppRoutes, { useHash: true, onSameUrlNavigation: 'reload' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
