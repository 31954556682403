import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { DictionaryItemDetailComponent as DictionaryItemDetailUI } from './dictionary-item.detail';
import { DictionaryItemDetail } from '../../../model/api/administration/dictionary-item-detail';
import { DictionaryTypeHttpService } from '../../../services/http/administration/dictionary-type.http.service';
import { DictionaryItemHttpService } from '../../../services/http/administration/dictionary-item.http.service';
import { DictionaryItemDetailHttpService } from '../../../services/http/administration/dictionary-item-detail.http.service';
import { AssetCategoryHttpService } from '../../../services/http/assets/asset-category.http.service';
import { DictionaryItem } from '../../../model/api/administration/dictionary-item';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DictionaryItemListComponent } from './dictionary-item.list';
import { DictionaryTypeListComponent } from '../dictionary-type/dictionary-type.list';
import { AssetCategoryListComponent } from '../../assets/asset-categories/asset-category.list';
import { DictionaryType } from '../../../model/api/administration/dictionary-type';
import { AssetCategory } from '../../../model/api/assets/asset-category';
import { AppUtils } from '../../../common/app.utils';

@Component({
    selector: 'app-dictionary-item-manage',
    templateUrl: 'dictionary-item.manage.html',
    providers: [ DictionaryTypeHttpService, DictionaryItemHttpService, DictionaryItemDetailHttpService, AssetCategoryHttpService ]
})
export class DictionaryItemManageComponent extends GenericManage<DictionaryItem, number> {

    @ViewChild('dictionaryItemDetailModal') dictionaryItemDetailModal: ModalDirective;
    @ViewChild('dictionaryItemList') dictionaryItemList: DictionaryItemListComponent;
    @ViewChild('dictionaryItemDetail') dictionaryItemDetail: DictionaryItemDetailUI;
    @ViewChild('dictionaryTypeListModal') dictionaryTypeListModal: ModalDirective;
    @ViewChild('dictionaryTypeList') dictionaryTypeList: DictionaryTypeListComponent;
    @ViewChild('assetCategoryListModal') assetCategoryListModal: ModalDirective;
    @ViewChild('assetCategoryList') assetCategoryList: AssetCategoryListComponent;

    public filter: string = '';
    public selectedDictionaryType: DictionaryType = null;
    public selectedAssetCategory: AssetCategory = null;
    isCollapsed: boolean = true;
    constructor(public dictionaryTypeHttpService: DictionaryTypeHttpService, public dictionaryItemHttpService: DictionaryItemHttpService,
        public dictionaryItemDetailHttpService: DictionaryItemDetailHttpService, public assetCategoryHttpService: AssetCategoryHttpService, ) {

        super();
    }

    public addNewItem() {
        super.addNewItem();

        this.dictionaryItemDetail.dictionaryType = null;
    }

    public editItem() {
        super.editItem();

        let dictionaryItem: DictionaryItemDetail = this.selectedItem as DictionaryItemDetail;

        this.dictionaryItemDetail.dictionaryType = null;
        this.dictionaryItemDetail.assetCategory = null;

        if (dictionaryItem != null) {
            this.dictionaryTypeHttpService
                .getById(dictionaryItem.dictionaryType.id)
                .subscribe((dictionaryType: DictionaryType) => {
                    this.dictionaryItemDetail.dictionaryType = dictionaryType;
                });
        }

        if (dictionaryItem != null) {
            this.assetCategoryHttpService
                .getById(dictionaryItem.assetCategory.id)
                .subscribe((assetCategory: AssetCategory) => {
                    this.dictionaryItemDetail.assetCategory = assetCategory;
                });
        }
    }

    public detailInitialize() {
        super.detailInitialize();
        this.dictionaryItemDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.dictionaryItemDetailModal.hide();
    }

    public onDictionaryItemDetailDictionaryTypeNeeded() {
        this.dictionaryItemDetailModal.hide();
        this.selectDictionaryType();
    }

    public onDictionaryTypeListCancel() {
        this.dictionaryTypeListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.dictionaryItemDetailModal.show();
        }
    }

    public onAssetCategoryDetailAssetCategoryNeeded() {
        this.dictionaryItemDetailModal.hide();
        this.selectAssetCategory();
    }

    public onAssetCategoryListCancel() {
        this.assetCategoryListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.dictionaryItemDetailModal.show();
        }
    }

    public refresh() {
        let params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        params.push(new Param("dictionaryTypeIds", AppUtils.getIdsList<DictionaryType, number>([ this.selectedDictionaryType ])));

        this.dictionaryItemList.refresh(params);
    }

    public selectDictionaryType() {
        this.dictionaryTypeListModal.show();
        this.dictionaryTypeList.refresh(null);
    }

    public selectAssetCategory() {
        this.assetCategoryListModal.show();
        this.assetCategoryList.refresh(null);
    }

    public setSelectedDictionaryType() {
        switch(this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedDictionaryType = this.dictionaryTypeList.selectedItem;
                this.dictionaryTypeListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.dictionaryItemDetail.dictionaryType = this.dictionaryTypeList.selectedItem;
                this.dictionaryTypeListModal.hide();
                this.dictionaryItemDetailModal.show();
                break;
        }
    }

    public setSelectedAssetCategory() {
        switch(this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedAssetCategory = this.assetCategoryList.selectedItem;
                this.assetCategoryListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.dictionaryItemDetail.assetCategory = this.assetCategoryList.selectedItem;
                this.assetCategoryListModal.hide();
                this.dictionaryItemDetailModal.show();
                break;
        }
    }

    public unselectDictionaryType() {
        this.selectedDictionaryType = null;
        this.refresh();
    }

    public exportToExcel() {

         let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.dictionaryItemDetailHttpService.get(1, 1000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<DictionaryItem>) => {

                let options = {
                    sheetid: 'Dictionar',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // let res = alasql(`SELECT id as [Id],
                //                     code as [Cod],
                //                     name as [Denumire],
                //                     assetCategory->name as [Categorie]
                //                     INTO XLSX("Dictionar.xlsx",?) FROM ?`,[ options, data.items ]);

            });
    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }

}
