import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectType } from '../../../model/api/assets/project-type';
import { GenericHttpService } from '../generic.http.service';

@Injectable()
export class ProjectTypeHttpService extends GenericHttpService<ProjectType, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'projecttypes');
    }
}
