import { Component } from '@angular/core';
import { GenericTableList } from '../../generic/generic.table.list';
import { Employee } from '../../../model/api/administration/employee';
import { AppData } from '../../../app-data';
import { Param } from '../../../model/common/param';
import { GenericPrimeNgDataTableList } from '../../generic/generic.prime-ng-data-table.list';
import { ExportService } from '../../../services/export.service';

@Component({
    selector: 'app-employee-list',
    //templateUrl: '../../generic/generic.table.list.html',
    templateUrl: '../../generic/generic.prime-ng-data-table.list.html',
    inputs: [ 'listTemplate' ],
})
//export class EmployeeListComponent extends GenericTableList<Employee, number> {
export class EmployeeListComponent extends GenericPrimeNgDataTableList<any, number> {
    private listTemplate: string = 'EMPLOYEES';

    constructor(public exportService: ExportService) {
        super('internalCode', 'asc', '', exportService);
        this.columns = AppData.ColumnDefinitions[this.listTemplate];
    }

    public refresh(filters: Array<Param>) {
        this.columns = AppData.ColumnDefinitions[this.listTemplate];
        super.refresh(filters);
    }
}
