import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Area } from '../../../model/api/administration/area';
import { GenericHttpService } from '../generic.http.service';

@Injectable()
export class AreaHttpService extends GenericHttpService<Area, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'areas');
    }
}
