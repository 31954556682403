import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Area } from '../../../model/api/administration/area';
import { GenericDetail } from '../../generic/generic.detail';
import { EmployeeResource } from '../../../model/api/administration/employee-resource';
import { Employee } from '../../../model/api/administration/employee';

@Component({
    selector: 'app-area-detail',
    templateUrl: 'area.detail.html',
    outputs: ['employeeNeeded']
})
export class AreaDetailComponent extends GenericDetail<Area, number> {

    @ViewChild('detailForm') detailForm: FormGroup;
    public selectedEmployee: EmployeeResource;

    date3: Date;
    newItem: boolean = true;

    public employeeNeeded: EventEmitter<void> = new EventEmitter<void>();

    setItemDefaultValues() {
        this.item = new Area(0, '', '', null, null);
    }

    public resetForm() {
        this.detailForm.reset();
    }

    public edit(item: Area) {

      super.edit(item);
      this.date3 = new Date(item.dateEvent);
      // console.log(this.date3);
      this.newItem = false;
  }

    public saveItem() {
      // const date = new Date(this.date3);
      // date.setDate(date.getDate());
      // console.log(this.newItem);
      // if(this.newItem){
      //   console.log('1');
      //   date.setDate(date.getDate() + 1);
      // } else {
      //   console.log('2');
      //   date.setDate(date.getDate() - 1);
      // }

      // this.item.dateEvent = date;
      // console.log(this.item.dateEvent);
        super.saveItem();
  }

    public set employee(employee: Employee) {
      this.setEmployee(employee);
  }

  public setEmployee(employee: Employee) {
      this.selectedEmployee = employee;
      this.item.employee = employee != null ? new EmployeeResource(employee.id, employee.internalCode, employee.firstName, employee.lastName, employee.email) : null;
  }

    public askForEmployee() {
      this.employeeNeeded.emit(null);
  }

  public updateDate(event) {
    // console.log(event);
    const date = new Date(this.date3);
    date.setDate(date.getDate() + 1);
    this.item.dateEvent = date;
  }
}
