import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { CityHttpService } from '../../../services/http/administration/city.http.service';
import { CountyHttpService } from '../../../services/http/administration/county.http.service';
import { City } from '../../../model/api/administration/city';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CityListComponent } from './city.list';
import { CityDetailComponent } from './city.detail';
import { CountyListComponent } from '../counties/county.list';
import { County } from '../../../model/api/administration/county';
import { AppUtils } from '../../../common/app.utils';
// import { saveAs as fileSaveAs } from 'file-saver-es';

@Component({
    selector: 'app-city-manage',
    templateUrl: 'city.manage.html',
    styleUrls: ['city.manage.scss'],
    providers: [ CityHttpService, CountyHttpService ]
})
export class CityManageComponent extends GenericManage<City, number> {

    @ViewChild('cityDetailModal') public cityDetailModal: ModalDirective;
    @ViewChild('cityList') public cityList: CityListComponent;
    @ViewChild('cityDetail') public cityDetail: CityDetailComponent;
    @ViewChild('countyListModal') public countyListModal: ModalDirective;
    @ViewChild('countyList') public countyList: CountyListComponent;

    public filter: string = '';
    public selectedCounty: County = null;
    isCollapsed: boolean = true;
    constructor(
        public cityHttpService: CityHttpService,
        public countyHttpService: CountyHttpService) {
        super();
    }

    public addNewItem() {
        super.addNewItem();

        this.cityDetail.county = null;
    }

    public editItem() {
        super.editItem();

        const city: City = this.selectedItem as City;

        this.cityDetail.county = null;
        if ((city != null) && (city.countyId != null)) {
            this.countyHttpService
                .getById(city.countyId)
                .subscribe((county: County) => {
                    this.cityDetail.county = county;
                });
        }
    }

    public detailInitialize() {
        super.detailInitialize();
        this.cityDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.cityDetailModal.hide();
    }

    public onCityDetailCountyNeeded() {
        this.cityDetailModal.hide();
        this.selectCounty();
    }

    public onCountyListCancel() {
        this.countyListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.cityDetailModal.show();
        }
    }


    public refresh() {
        const params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        params.push(new Param('countyIds', AppUtils.getIdsList<County, number>([ this.selectedCounty ])));
        this.cityList.refresh(params);
    }

    public selectCounty() {
        this.countyListModal.show();
        this.countyList.refresh(null);
    }

    public setSelectedCounty() {
        switch (this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedCounty = this.countyList.selectedItem;
                this.countyListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.cityDetail.county = this.countyList.selectedItem;
                this.countyListModal.hide();
                this.cityDetailModal.show();
                break;
            default:
                break;
        }
    }

    public unselectCounty() {
        this.selectedCounty = null;
        this.refresh();
    }


    public getFilters(): Array<Param> {
        let params: Array<Param> = new Array<Param>();
        params.push(new Param('filter', this.filter));
        params.push(new Param('countyIds', AppUtils.getIdsList<County, number>([this.selectedCounty])));

        return params;
    }

    public exportToExcel() {

        let params: Array<Param> = null;

        // params = this.getFilters();
        // this.cityHttpService
        //     .export(params)
        //     .subscribe((blob) => {
        //         fileSaveAs(blob, 'Cities.xlsx');
        //     });
    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }
}
