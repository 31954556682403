import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import { TransferResult } from '../../model/api/result/transfer-result';
import { DocumentHttpService } from '../../services/http/documents/document.http.service';

@Component({
    selector: 'app-email-status-dst-employee-validate-page',
    templateUrl: 'email-status-dst-employee-validate-page.html'
})
export class EmailStatusDstEmployeeValidatePageComponent implements OnInit {
  position: string;
  reason: string = '';
  guid: string = '';
  success: boolean = false;

    constructor(
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private documentHttpService: DocumentHttpService,
      private route: ActivatedRoute) {
      this.route.params.subscribe((params: Params) => {
        if (params['guid']) {
            this.guid = params['guid'];
            console.log(this.guid);
        }
    });
    }
  ngOnInit(): void {
    this.confirmPosition('bottom');
  }

  confirmPosition(position: string) {
      this.position = position;

      this.confirmationService.confirm({
          message: 'Doriti sa aprobati aceasta comanda?',
          header: 'Confirma',
          icon: 'pi pi-info-circle',
          acceptLabel: 'Da',
          rejectLabel: 'Nu',
          accept: () => {
            this.documentHttpService.validateDstEmployee(this.guid).subscribe( (res: TransferResult)=> {
              if(res.success){
                this.messageService.add({severity:'info', summary:'Aprobare', detail:'Transferul a fost aprobat cu sucess!'});
              } else {
                this.messageService.add({severity:'error', summary:'Aprobare', detail:res.message});
              }
            })

          },
          reject: (type) => {
            this.messageService.add({severity:'warn', summary:'Anulare', detail:'Aprobarea nu a fost efectuata!'});
              // switch(type) {
                  // case ConfirmEventType.REJECT:
                  //     this.documentHttpService.rejectDstEmployee(this.guid).subscribe( (res: TransferResult) => {
                  //       if(res.success){
                  //         this.messageService.add({severity:'error', summary:'Refuz', detail:'Transferul a fost refuzat'});
                  //       } else {
                  //         this.messageService.add({severity:'error', summary:'Refuz', detail:res.message});
                  //       }
                  //     })

                  // break;
                  // case ConfirmEventType.CANCEL:
                  //     this.messageService.add({severity:'warn', summary:'Anulare', detail:'Aprobare anulata'});
                  // break;
              // }
          },
          key: "positionDialog"
      });
  }
}
