import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { Division } from '../../../model/api/administration/division';
import { DivisionHttpService } from '../../../services/http/administration/division.http.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DivisionListComponent } from './division.list';
import { DivisionDetailComponent } from './division.detail';
import { DepartmentListComponent } from '../departments/department.list';
import { DepartmentHttpService } from '../../../services/http/administration/department.http.service';
import { Department } from '../../../model/api/administration/department';

@Component({
    selector: 'division-manage',
    templateUrl: 'division.manage.html',
    providers: [ DivisionHttpService ]
})
export class DivisionManageComponent extends GenericManage<Division, number> {

    @ViewChild('divisionDetailModal') divisionDetailModal: ModalDirective;
    @ViewChild('divisionList') divisionList: DivisionListComponent;
    @ViewChild('divisionDetail') divisionDetail: DivisionDetailComponent;
    @ViewChild('departmentListModal') departmentListModal: ModalDirective;
    @ViewChild('departmentList') departmentList: DepartmentListComponent;

    public filter: string = '';
    isCollapsed: boolean = true;
    public selectedDepartment: Department = null;

    constructor(public divisionHttpService: DivisionHttpService,public departmentHttpService: DepartmentHttpService,) {
        super();
    }

    public addNewItem() {
        super.addNewItem();

        this.divisionDetail.department = null;
    }

    public editItem() {
        super.editItem();

        let division: Division = this.selectedItem as Division;

        this.divisionDetail.department = null;
        
        if (division != null) {
            this.departmentHttpService
                .getById(division.departmentId)
                .subscribe((department: Department) => {
                    this.divisionDetail.department = department;
                });
        }
    }

    public detailInitialize() {
        super.detailInitialize();
        this.divisionDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.divisionDetailModal.hide();
    }

    public onDivisionDetailDepartmentNeeded() {
        this.divisionDetailModal.hide();
        this.selectDepartment();
    }

    public onDepartmentListCancel() {
        this.departmentListModal.hide();
        this.divisionDetailModal.show();
    }


    public selectDepartment() {
        this.departmentListModal.show();
        this.departmentList.refresh(null);
    }

    public setSelectedDepartment() {
        switch(this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedDepartment = this.departmentList.selectedItem;
                this.departmentListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.divisionDetail.department = this.departmentList.selectedItem;
                this.departmentListModal.hide();
                this.divisionDetailModal.show();
                break;
        }
    }

    public unselectDepartment() {
        this.selectedDepartment = null;
        this.refresh();
    }

    public refresh() {
        const params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));

        this.divisionList.refresh(params);
    }

    public exportToExcel(){

         let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.divisionHttpService.get(1, 10000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<Division>) => {

                let options = {
                    sheetid: 'Orase',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // alasql(`SELECT id as [Id], 
                //     code as [Cod], 
                //     name as [Denumire] 
                //     INTO XLSX("Orase.xlsx",?) FROM ?`, [ options, data.items ]);

            });

    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }
}
