import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import { TransferResult } from '../../model/api/result/transfer-result';
import { DocumentHttpService } from '../../services/http/documents/document.http.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-email-status-dst-employee-reject-page',
    templateUrl: 'email-status-dst-employee-reject-page.html'
})
export class EmailStatusDstEmployeeRejectPageComponent implements OnInit {
  @ViewChild('confirmationModal') public confirmationModal: ModalDirective;
  position: string;
  reason: string = '';
  guid: string = '';
  success: boolean = false;
  reasonReject = '';

    constructor(
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private documentHttpService: DocumentHttpService,
      private route: ActivatedRoute) {
      this.route.params.subscribe((params: Params) => {
        if (params['guid']) {
            this.guid = params['guid'];
        }
    });
    }
  ngOnInit(): void {
    this.confirmPosition('bottom');
  }

  confirmPosition(position: string) {
      this.position = position;

      this.confirmationService.confirm({
          message: 'Doriti sa refuzati acest transfer?',
          header: 'Confirma',
          icon: 'pi pi-info-circle',
          acceptLabel: 'Da',
          rejectLabel: 'Nu',
          accept: () => {
            this.confirmationModal.show();
          },
          reject: () => {
            this.messageService.add({severity:'warn', summary:'Anulare', detail:'Refuzul nu a fost efectuat!'});             
          },
          key: "positionDialog"
      });
  }

  public onConfirmationApproved() {
    this.confirmationModal.hide();
    this.documentHttpService.rejectDstEmployee(this.guid, this.reasonReject).subscribe( (res: TransferResult)=> {
      if(res.success){
        this.messageService.add({severity:'info', summary:'Refuz', detail:'Transferul a fost refuzat!'});
      } else {
        this.messageService.add({severity:'error', summary:'Refuz', detail:res.message});
      }
    })
  }

  public onConfirmationCanceled() {
    this.confirmationModal.hide();
    this.messageService.add({severity:'warn', summary:'Anulare', detail:'Refuzul nu a fost efectuat!'});        
  }

  public omit_special_char(e) { 
    try { 
      if ((/^[a-za-z0-9\s]*$/.test(e.key)) || e.keyCode === 8) { 
          return true;
      } else { 
          e.stopPropagation();
          return false;
      }
    } catch (e) {}
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }
}
