
import { Injectable } from '@angular/core';
import { GenericHttpService } from '../generic.http.service';
import { County } from '../../../model/api/administration/county';
import { HttpClient } from '@angular/common/http';
// import { AuthHttp } from 'angular2-jwt';

@Injectable()
export class CountyHttpService extends GenericHttpService<County, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'counties');
    }


    // public export(params: Array<Param>) {
    //     let searchParams: HttpParams = null;
    //     const url = AppConfig.urlPrefix + this.url + '/export';

    //     searchParams = this.getSearchParams(params);
    //     return this.http.get(url, { responseType: ResponseContentType.Blob, search: searchParams })
    //                     .map(res => res.blob());
    // }

    // public getData(): Observable<any> {
    //     return this.http.get(AppConfig.urlPrefix + this.url , { headers: this.headers })
    //     .map((data: Response) => {
    //         return data.json();
    //     });
    // }
}
