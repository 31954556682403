import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tax } from '../../../model/api/assets/tax';
import { GenericHttpService } from '../generic.http.service';

@Injectable()
export class TaxHttpService extends GenericHttpService<Tax, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'taxs');
    }
}
