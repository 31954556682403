import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AssetInvPlusSAP } from '../../../model/api/sap/asset-inv-plus-sap';
import { GenericDetail } from '../../generic/generic.detail';

@Component({
    selector: 'app-asset-inv-plus-sap-detail',
    templateUrl: 'asset-inv-plus-sap.detail.html'
})
export class AssetInvPlusSAPDetailComponent extends GenericDetail<AssetInvPlusSAP, number> {

    @ViewChild('detailForm') detailForm: FormGroup;

    setItemDefaultValues() {
        this.item = new AssetInvPlusSAP();
    }

    public resetForm() {
        this.detailForm.reset();
    }
}
