import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, forwardRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppData } from '../../../app-data';
import { AppUtils } from '../../../common/app.utils';
import { DictionaryItem } from '../../../model/api/administration/dictionary-item';
import { AssetEmployeeEdit } from '../../../model/api/assets/asset-employee-edit';
import { AssetEmployeeSave } from '../../../model/api/assets/asset-employee-save';
import { Brand } from '../../../model/api/assets/brand';
import { Model } from '../../../model/api/assets/model';
import { CodeNameEntity } from '../../../model/api/common/code-name-entity';
import { ConfigValue } from '../../../model/api/common/config-value';
import { EmployeeValidateStatus } from '../../../model/api/common/employee-validate-status';
import { ColumnDefinition } from '../../../model/common/column-definition';
import { PagedResult } from '../../../model/common/paged-result';
import { Param } from '../../../model/common/param';
import { TableDefinition } from '../../../model/common/table-definition';
import { AuthenticationService } from '../../../services/authentication.service';
import { DictionaryItemHttpService } from '../../../services/http/administration/dictionary-item.http.service';
import { EmployeeHttpService } from '../../../services/http/administration/employee.http.service';
import { AssetHttpService } from '../../../services/http/assets/asset.http.service';
import { BrandHttpService } from '../../../services/http/assets/brand.http.service';
import { ModelHttpService } from '../../../services/http/assets/model.http.service';
import { ColumnDefinitionHttpService } from '../../../services/http/common/column-definition.http.service';
import { ConfigValuesHttpService } from '../../../services/http/common/config-values.service';
import { DashboardHttpService } from '../../../services/http/common/dashboard.http.service';
import { TableDefinitionHttpService } from '../../../services/http/common/table-definition.http.service';
import { NotificationService } from '../../../services/notification.service';
import { DictionaryItemDetailComponent } from '../../administrations/dictionary-item/dictionary-item.detail';
import { DictionaryItemListComponent } from '../../administrations/dictionary-item/dictionary-item.list';
import { BrandList } from '../brands/brand.list';
import { ModelList } from '../models/model.list';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FileHttpService } from '../../../services/file.service';
import { saveAs as fileSaveAs } from 'file-saver-es';
import { AppConfig } from '../../../config';
import { AssetEmployeePersonalValidateListComponent } from './asset-employee-personal-validate.list';
import { Operation } from '../../../model/api/documents/operation';
import { DocumentUpload } from '../../../model/api/documents/document-upload';
import { DocumentHttpService } from '../../../services/http/documents/document.http.service';
import { Document } from '../../../model/api/documents/document';
import { AssetSimpleDetail } from '../../../model/api/assets/asset-simple-detail';
import { AssetImage, EntityFile } from '../../../model/api/common/entity-file';
import { EntityFileHttpService } from '../../../services/http/common/entity-file.http.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadBuyModalComponent } from '../../common/upload-buy-modal.component';
import { ValidateAssetBuy } from '../../../model/api/assets/validate-asset-buy';
import { BuyResult } from '../../../model/api/result/buy-result';
import { AssetBuy } from '../../../model/api/assets/asset-buy';
import { EmployeeListComponent } from '../../administrations/employees/employee.list';
import { Employee } from '../../../model/api/administration/employee';
import { TransferResult } from '../../../model/api/result/transfer-result';
import { InfoEmployeeSave } from '../../../model/api/assets/info-employee-save';
import { AssetFilter } from '../../../model/api/assets/asset.filter';
import { ValidateAssetITBuy } from '../../../model/api/assets/validate-asset-IT-buy';
import { RejectAssetBuy } from '../../../model/api/assets/reject-asset-buy';
import { PrintLabel } from '../../../model/common/print-label';
import { EmployeeFilter } from '../../../model/api/administration/employee.filter';
import { AssetEntityListComponent } from './asset-entity.list';
import { AssetEntity } from '../../../model/api/common/asset-entity';
import { UploadMarketModalComponent } from '../../common/upload-market-modal.component';
import { EntityFileResult } from '../../../model/api/result/entity-file-result';
import { MarketPlace } from '../../../model/api/assets/market-place';
import { MarketPlaceResult } from '../../../model/api/result/market-place-result';
import { RejectMarketPlace } from '../../../model/api/assets/reject-asset-market-place';
import { Res } from '../../../resources/resources';

@Component({
    selector: 'app-asset-employee-personal-detail-ui',
    templateUrl: 'asset-employee-personal.detail.ui.html',
    styleUrls: ['asset-employee-personal.detail.ui.scss'],
    providers: [ AssetHttpService ]
})
export class AssetEmployeePersonalDetailUIComponent implements AfterViewInit  {

    @ViewChild('assetInvFullDetailList') public assetInvFullDetailList: AssetEmployeePersonalValidateListComponent;

    @ViewChild('dictionaryItemDetail') public dictionaryItemDetail: DictionaryItemDetailComponent;
    @ViewChild('dictionaryItemList') public dictionaryItemList: DictionaryItemListComponent;

    @ViewChild('dictionaryItemDetailModal') public dictionaryItemDetailModal: ModalDirective;
    @ViewChild('dictionaryItemListModal') public dictionaryItemListModal: ModalDirective;

    @ViewChild('brandList') public brandList: BrandList;
    @ViewChild('brandListModal') public brandListModal: ModalDirective;

    @ViewChild('modelList') public modelList: ModelList;
    @ViewChild('modelListModal') public modelListModal: ModalDirective;

    @ViewChild('employeeList') public employeeList: EmployeeListComponent;
    @ViewChild('employeeListModal') public employeeListModal: ModalDirective;

    @ViewChild('confirmationModal') public confirmationModal: ModalDirective;
    @ViewChild('confirmationInfoEmployeeModal') public infoEmployeeModal: ModalDirective;
    @ViewChild('transferModal') public transferModal: ModalDirective;

    @ViewChild('assetEntityList') public assetEntityList: AssetEntityListComponent;
    @ViewChild('assetEntityListModal') public assetEntityListModal: ModalDirective;

    @ViewChild('buyModal') public buyModal: ModalDirective;
    @ViewChild('marketModal') public marketModal: ModalDirective;

    public equipmentDeliveryBtnInfo = Res.EquipmentDeliveryBtnInfo;
    public temporaryEquipmentDeliveryBtnInfo = Res.TemporaryEquipmentDeliveryBtnInfo;
    public buyBtnInfo = Res.BuyBtnInfo;
    public cancelPurchaseBtnInfo = Res.CancelPurchaseBtnInfo;
    public saleBtnInfo = Res.SaleBtnInfo;
    public saleCancelBtnInfo = Res.SaleCancelBtnInfo;
    public uploadProofOfPaymentBtnInfo = Res.UploadProofOfPaymentBtnInfo;
    public printBtnInfo = Res.PrintBtnInfo;
    public refreshBtnInfo = Res.RefreshBtnInfo;
    public checkBtnInfo = Res.CheckBtnInfo;
    public notesBtnInfo = Res.NotesBtnInfo;
    public exportBtnInfo = Res.ExportBtnInfo;

    public assetEmployee: AssetEmployeeSave = new AssetEmployeeSave();
    // public selectedAsset: AssetSimpleDetail = null;
    public selectedAssets: Array<AssetSimpleDetail> = new Array<AssetSimpleDetail>();
    isSaved = true;
    isClearFilters = false;
    decodedToken: any;
    public token = '';
    roleName = '';
    reasonReject = '';
    infoEmployee = '';
    transferReason = '';
    filter = '';
    public assetRowSelection: string = 'multiple';
    // public pageSize = 50;
    sortColumn = 'asset.invNo';
    sortDirection = 'asc';

    // public itemsInventory = new Array<EmployeeValidateStatus>();

    public confirmationMessage: string = '';
    public confirmationInfoEmployeeMessage: string = '';
    public operationType: OperationType = OperationType.NotSet;

    public imageCount: number = 0;
    public imageIndex: number = 0;
    public imageLoading: boolean = false;
    public assetImagesPdf: Array<AssetImage> = new Array<AssetImage>();
    public assetImages: Array<AssetImage> = new Array<AssetImage>();
    public assetFiles: Array<EntityFile> = new Array<EntityFile>();
    public existingAssetImages: Array<AssetImage> = new Array<AssetImage>();
    public employeeListSelectedItem: Employee = null;
    public selectedEmployee: Employee = null;

    public selectedEmpIds: Array<number> = new Array<number>();
    public assetEntity: AssetEntity = null;
    public initialSortColumn = '';
    public initialSortDirection = 'asc';
    public initialPageSize = 50;

    buyMessage = '';
    transferMessage = '';

    marketMessage = '';
    netAmount: number = 0;
    infoMarket1: string = '';
    infoMarket2: string = '';
    url: string = '';

    interval: number = 3000;
    slides: any[] = [];
    activeSlideIndex: number = 0;
    noWrapSlides: boolean = false;
    itemsPerSlide = 2;
    singleSlideOffset = true;
    showIndicator = true;


    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public assetHttpService: AssetHttpService,
        public dictionaryItemHttpService: DictionaryItemHttpService,
        public brandHttpService: BrandHttpService,
        public modelHttpService: ModelHttpService,
        public notificationService: NotificationService,
        public employeeHttpService: EmployeeHttpService,
        public dashboardHttpService: DashboardHttpService,
        public tableDefinitionHttpService: TableDefinitionHttpService,
        public columnDefinitionHttpService: ColumnDefinitionHttpService,
        public configValuesHttpService: ConfigValuesHttpService,
        public authenticationService: AuthenticationService,
        private entityFileHttpService: EntityFileHttpService,
        private jwtService: JwtHelperService,
        private fileService: FileHttpService,
        public documentHttpService: DocumentHttpService,
        public dialog: MatDialog,
        public http: HttpClient) {

          this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                if (evt.urlAfterRedirects === '/wfh/validate') {
                    // console.log('refreshing asset inv details');
                    // console.log(JSON.stringify(evt));
                    // this.refreshAssets();

                    setTimeout(() => {
                        this.refreshAssets();
                      }, 100);
                }
            }
        });

            // this.itemsInventory.push(new EmployeeValidateStatus());

            this.token = localStorage.getItem('id_token');

            if (this.token === '' || this.token == null) {
                this.router.navigate(['/login']);
            }

            // this.route.params.subscribe((params: Params) => {
            //     if (params['id']) {
            //         this.guid = params['id'];
            //     }
            // });
    }

    ngAfterViewInit() {
        this.initialSortColumn = this.assetInvFullDetailList.sortColumn;
        this.initialSortDirection = this.assetInvFullDetailList.sortDirection;
        this.initialPageSize = this.assetInvFullDetailList.pageSize;
        this.decodedToken = this.jwtService.decodeToken(this.token);
        const index = this.decodedToken.role[1];
        if (index.length > 1) {
          this.decodedToken.role = this.decodedToken.role.slice(0 , -1);
        }

        setTimeout(() => {
            this.loadTableConfigurationData(this.decodedToken.role);
            this.refreshAssets();
            // this.loadData();

       }, 2000);
    }

    public export() {
      this.assetInvFullDetailList.export$('Angajat').subscribe(
          () => {},
          error => {}
      );
    }

    get canExport() {
      return this.assetInvFullDetailList?.canExport;
    }

    public onAssetInvFullDetailSelectionChanged(assets: Array<AssetEmployeeSave>) {

        this.selectedAssets = new Array<any>();
        assets.forEach((asset: any) => {
            this.selectedAssets.push(asset);
        });
        // this.selectedAsset = ((assets != null) && (assets.length === 1)) ? assets[0] : null;
        // this.selectedAsset = this.assetInvFullDetailList.selectedItem;
    }

    onAssetEmployeeValidateListAfterViewInit() {
    }

    public refreshAssets() {
      if (this.filter !== '' && this.filter !== undefined){
        this.assetInvFullDetailList.currentPage = 1;
        this.assetInvFullDetailList.first = 1;
        this.assetInvFullDetailList.resetDt();
      }
      
      const params: Array<Param> = this.getFilters();
      // console.log(this.isClearFilters);
      // if(this.isClearFilters){
      //   console.log('AFTER: ' + this.isClearFilters);
      //   params.push(new Param('sortColumn', 'asset.isInTransfer'));
      //   params.push(new Param('sortDirection', 'desc'));
      //   this.isClearFilters = false;
      // }
        this.assetInvFullDetailList.refresh(params);
    }

    /*end MODEL */

    public deleteAssetOp() {
        this.assetHttpService.deleteAssetValidation(this.assetInvFullDetailList.selectedItem.id).subscribe( (assetId) => {
            if (assetId > 0){
                this.notificationService.showSuccess('Datele au fost salvate!', 'Stergere echipament');
                this.refreshAssets();
                // this.loadData();
            }
        }, error => {
            this.notificationService.showError('Eroare!', 'Stergere echipament');
        });
    }

    public onDeleteAssetOp() {
        this.operationType = OperationType.Delete;
        this.confirmationMessage = 'Refuzati transferul selectat?';
        this.confirmationModal.show();
    }

    public onTransferValidate() {
        this.operationType = OperationType.AssetValidation;
        this.confirmationMessage = 'Validati transferul selectat?';
        this.confirmationModal.show();
    }

    public onConfirmationCanceled() {
        this.operationType = OperationType.NotSet;
        this.confirmationModal.hide();
    }

    public onConfirmationInfoEmployeeCanceled() {
      this.operationType = OperationType.NotSet;
      this.infoEmployeeModal.hide();
  }

    public onConfirmationApproved() {

        switch (this.operationType) {
            case OperationType.Delete:
                this.deleteAssetOp();
                break;
            case OperationType.AssetValidation:
                 this.validateDocument();
                break;
                case OperationType.AssetReject:
                  this.rejectBuy();
                 break;
                 case OperationType.MarketPlaceReject:
                  this.rejectMarketPlace();
                 break;
            default:
                break;
        }

        this.operationType = OperationType.NotSet;
        this.confirmationModal.hide();
    }

    public onConfirmationInfoEmployeeApproved() {

      switch (this.operationType) {
              case OperationType.InfoEmployee:
                this.infoEmployeeSave();
               break;
          default:
              break;
      }

      this.operationType = OperationType.NotSet;
      this.confirmationModal.hide();
  }

    public resetAllFilters(){
      //set first page + refetch data
      this.assetInvFullDetailList.currentPage = 1;
      this.assetInvFullDetailList.pageSize = this.initialPageSize;
      this.assetInvFullDetailList.resetRows(this.initialPageSize);
      
      //reset sorting
      this.assetInvFullDetailList.sortColumn = this.initialSortColumn;
      this.assetInvFullDetailList.sortDirection = this.initialSortDirection;
      this.assetInvFullDetailList.resetSort();
      
      //reset filters
      this.clearFilters();
    }

    public clearFilters() {
        this.assetInvFullDetailList.sortColumn = 'byColor';
        this.isClearFilters = true;
        // this.loadData();
        this.loadTableConfigurationData(this.decodedToken.role);
        // this.assetInvFullDetailList.refresh(null);
        this.isSaved = true;
        this.filter = '';
        this.clearSelection();
        this.refreshAssets();
    }

    // public loadData() {
    //     this.dashboardHttpService.employeesStatus().subscribe( (res: any[]) => {
    //       this.itemsInventory = res;
    //   });
    // }

    public loadTableConfigurationData(userRole: string) {
        this.tableDefinitionHttpService.get(1, 1000000, 'id', 'asc', null, null, 'allowAnonymous')
          .subscribe((tableDefinitions: PagedResult<TableDefinition>) => {

            const params: Array<Param> = new Array<Param>();
            params.push(new Param('roleName', userRole));
            this.columnDefinitionHttpService.get(1, 1000000, 'tableDefinitionId', 'asc', params, null, 'allowAnonymous')
              .subscribe((columnDefinitions: PagedResult<ColumnDefinition>) => {
                AppData.UpdateColumnDefinitions(tableDefinitions.items, columnDefinitions.items);
                //this.refreshAssets();
                // this.configValuesHttpService.get(1, 1000000, 'id', 'asc', params)
                //   .subscribe((configValues: PagedResult<ConfigValue>) => {
                //     AppData.UpdateConfigValues(configValues.items);

                //   });
              });
          });
      }

    public get isAdmin(): boolean {
        return this.decodedToken != null && this.decodedToken.role != null && this.decodedToken.role === 'administrator';
    }

    public getSN(): void {
        const url = `${AppConfig.urlPrefix}documents/manual/serie`;
            window.open(url);
          }

    public getIMEI(): void {
    const url = `${AppConfig.urlPrefix}documents/manual/imei`;
        window.open(url);
        }

    public validateDocument() {
        this.isSaved = false;
        let document: Document = null;
        let operation: Operation = null;
        let operations: Array<Operation> = null;
        let documentUpload: DocumentUpload = null;

        document = new Document(0, 2, '', '', new Date(), new Date(), false, '', '', new Date(), new Date());

        operations = new Array<Operation>();

        if (this.selectedAssets.length > 0) {
            this.selectedAssets.forEach(element => {
                operation = new Operation(0, element.id, false, null, element.costCenterId, null, null, null, null, element.employeeTransferId, null, null, null, null, null, element.employeeId);
                operations.push(operation);
            });
        }

        documentUpload = new DocumentUpload();
        documentUpload.id = document.id;
        documentUpload.documentTypeId = document.documentTypeId;
        documentUpload.docNo1 = document.docNo1;
        documentUpload.docNo2 = document.docNo2;
        documentUpload.documentDate = document.documentDate;
        documentUpload.registerDate = document.registerDate;
        documentUpload.validated = document.validated;
        documentUpload.details = document.details;
        documentUpload.serialNumber = document.serialNumber;
        documentUpload.operations = operations;

        this.documentHttpService.operation(documentUpload).toPromise().then((data) => {
            this.isSaved = false;
            alert('Operatia a fost validata cu success!');
            this.clearSelection();
            this.refreshAssets();
    }, (error) => {
        alert('Eroare server');
    });
    }

    public rejectBuy() {
      this.isSaved = false;
      let validateBuyAsset = new RejectAssetBuy();
          validateBuyAsset.id = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
          validateBuyAsset.buyState = 'REJECTASSET';
          validateBuyAsset.reason = this.reasonReject;
    this.assetHttpService.rejectBuyAsset(validateBuyAsset)
    .subscribe((result: BuyResult) => {
        if (result.success) {
            this.notificationService.showSuccess(result.message, 'Respingere echipament');
            // this.router.navigate(['/asset/stockittovalidate']);
            this.isSaved = true;
            this.clearSelection();
            this.refreshAssets();
        } else if (!result.success) {
            this.notificationService.showError('Motiv: ' + result.message + '!', 'Eroare salvare date');
            this.isSaved = true;
            this.clearSelection();
            this.refreshAssets();
        }
    });
    }

    public infoEmployeeSave() {
      this.isSaved = false;
      let infoEmployee = new InfoEmployeeSave();
          infoEmployee.assetId = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
          infoEmployee.infoEmployee = this.infoEmployee;
    this.assetHttpService.imfoEmployeeAsset(infoEmployee)
    .subscribe((result: BuyResult) => {
        if (result.success) {
            this.notificationService.showSuccess(result.message, 'Notite angajat');
            // this.router.navigate(['/asset/stockittovalidate']);
            this.isSaved = true;
            this.infoEmployeeModal.hide();
            this.clearSelection();
            this.refreshAssets();
        } else if (!result.success) {
            this.notificationService.showError('Motiv: ' + result.message + '!', 'Eroare salvare date');
            this.isSaved = true;
            this.clearSelection();
            this.refreshAssets();
        }
    });
    }

    public saveDocument() {
        if (!this.isSaved)
          return;

        this.isSaved = false;
        let document: Document = null;
        let operation: Operation = null;
        let operations: Array<Operation> = null;
        let documentUpload: DocumentUpload = null;

        document = new Document(0, 2, '', '', new Date(), new Date(), false, this.transferReason, '', new Date(), new Date());

        operations = new Array<Operation>();

        if (this.selectedAssets.length > 0) {
            this.selectedAssets.forEach(element => {
                operation = new Operation(0, element.id, false, null, this.selectedEmployee.costCenter?.id, 0, 0, 0, 0, this.selectedEmployee.id, 0, 0, 0, 0, 0, 0);
                operations.push(operation);
            });

        }

        documentUpload = new DocumentUpload();
        documentUpload.id = document.id;
        documentUpload.documentTypeId = document.documentTypeId;
        documentUpload.docNo1 = document.docNo1;
        documentUpload.docNo2 = document.docNo2;
        documentUpload.documentDate = document.documentDate;
        documentUpload.registerDate = document.registerDate;
        documentUpload.validated = document.validated;
        documentUpload.details = document.details;
        documentUpload.serialNumber = document.serialNumber;
        documentUpload.operations = operations;

        if(this.operationType === 8){
          documentUpload.details = 'Inlocuire temporara bun defect!';
          documentUpload.documentTypeId = 11;
          documentUpload.replaceAssetId = this.assetEntity.id;
          this.documentHttpService.saveFullTempDocument(documentUpload).toPromise().then((data: TransferResult) => {
            if(data.success){
              this.notificationService.showSuccess(data.message, 'Predare echipament');
              this.transferModal.hide();
              this.isSaved = true;
              this.selectedEmployee = null;
              this.clearSelection();
              this.refreshAssets();
            } else {
              this.notificationService.showError(data.message, 'Predare echipament');
            }

      }, (error) => {
          alert('Eroare server');
          this.transferModal.hide();
              this.isSaved = true;
      });
        } else {
          this.documentHttpService.saveFullDocument(documentUpload).toPromise().then((data: TransferResult) => {
            if(data.success){
              this.notificationService.showSuccess(data.message, 'Predare echipament');
              this.transferModal.hide();
              this.isSaved = true;
              this.selectedEmployee = null;
              this.clearSelection();
              this.refreshAssets();
            } else {
              this.notificationService.showError(data.message, 'Predare echipament');
            }

      }, (error) => {
          alert('Eroare server');
          this.transferModal.hide();
          this.isSaved = true;
      });
        }
    }

        public get allowValidate(): boolean {
            return this.selectedAssets.length > 0 && this.selectedAssets[0].state && (this.selectedAssets[0].state.code === 'FINAL_EMPLOYEE_VALIDATE' || this.selectedAssets[0].state.code === 'FINAL_MANAGER_VALIDATE');
        }

        public get allowRejectBuy(): boolean {
          if(this.selectedAssets.length === 1){
           for (let index = 0; index < this.selectedAssets.length; index++) {
             const element = this.selectedAssets[index];
             if(((element.state != null && !element.isInMarketPlace && (
              element.state.code == 'IT_BUY_APPROVE' ||
              element.state.code == 'ACCOUNTING_BUY_APPROVE' ||
              element.state.code == 'BUY_BOOK' ||
              element.state.code == 'ACCOUNTING_BUY_MF_APPROVE' ||
              element.state.code == 'GNFR_BUY_APPROVE' ||
              element.state.code == 'CFO_BUY_APPROVE' ||
              element.state.code == 'EXTRASE_BUY_APPROVE' ||
              element.state.code == 'IT_BUY_DELETE_DATA' ||
              element.state.code == 'BUY_UPLOAD_INVOICE' ||
              element.state.code == 'EXTRASE_BUY_REJECT' || element.isInService)))){
               return true;
             }
           }
           return false;
          } else {
            return false;
          }
     }


      //   public get allowUpload(): boolean {
      //     return this.selectedAssets.length > 0 && this.selectedAssets[0].state != null && (this.selectedAssets[0].state.code === 'BUY_BOOK' || this.selectedAssets[0].state.code === 'EXTRASE_BUY_REJECT');
      // }

      public get allowUpload(): boolean {
        if(this.selectedAssets.length > 0 && this.selectedAssets.length === 1){
         for (let index = 0; index < this.selectedAssets.length; index++) {
           const element = this.selectedAssets[index];
           if(((element.state != null && element.state.code === 'BUY_BOOK' && element.isInBuy) || (element.state != null && element.state.code === 'EXTRASE_BUY_REJECT' && element.isInBuy))){
             return true;
           }
         }
         return false;
        } else {
         return false;
        }
   }

        public get allowTransferStock(): boolean {
             if(this.selectedAssets.length > 0){
              for (let index = 0; index < this.selectedAssets.length; index++) {
                const element = this.selectedAssets[index];
                if(element.state != null || (element.isInService || element.isInTransfer || element.isInTempTransfer || element.isInMarketPlace)){
                  return false;
                }
              }
              return true;
             } else {
              return false;
             }
        }

                public showPhoto(type: string){

                switch (type) {
                    case 'TRANSFER':
                    this.refreshAssetOpEntityFiles(this.assetInvFullDetailList.selectedItem.valueAdd, true);
                        break;
                    default:
                        break;
                }
        }

        public clearSelection() {
            this.selectedAssets = new Array<AssetSimpleDetail>();
            this.assetInvFullDetailList.selectedItems = this.selectedAssets;
        }


        refreshAssetOpEntityFiles(assetId: number, loadAssetImages: boolean) {
            this.entityFileHttpService.getByEntity('TRANSFER', assetId)
            .subscribe((entityFiles: Array<EntityFile>) => {
                this.existingAssetImages.splice(0, this.existingAssetImages.length);
                this.assetImages.forEach((assetImage: AssetImage) => this.existingAssetImages.push(assetImage));
                this.assetImages.splice(0, this.assetImages.length);
                this.assetFiles.splice(0, this.assetFiles.length);
                // this.entityFileMemoryDataSource.clear();
                entityFiles.forEach((entityFile: EntityFile) => {
                    if (entityFile.fileType.startsWith('application/')) {
                        let fileContent: any = null;
                        this.existingAssetImages.forEach((assetImage: AssetImage) => {
                            if (assetImage.entityFile.id === entityFile.id) {
                                fileContent = assetImage.fileContent;
                            }
                        });
                        this.assetImages.push(new AssetImage(entityFile, fileContent));
                    } else {
                        this.assetFiles.push(entityFile);
                        // this.entityFileMemoryDataSource.addItem(entityFile);
                    }
                });
               // this.fileList.refresh(null);
                if (loadAssetImages) { this.loadAssetImages(); }
            });
        }
        private loadAssetImages() {
            if ((this.assetImages !== null) && (this.assetImages.length > 0)) {
                this.imageCount = this.assetImages.length;
                this.imageIndex = 0;
                this.imageLoading = true;
                this.loadAssetImageLoop();
            }
        }

        private loadAssetImageLoop() {
            if (this.assetImages.length > this.imageIndex) {
                const assetImage: AssetImage = this.assetImages[this.imageIndex];
                if (assetImage.fileContent === null) {
                    this.entityFileHttpService.download(assetImage.entityFile.id).subscribe((image) => {
                        this.createImageFromBlob(assetImage, image as any);
                        this.loadNextAssetImage();
                    });
                } else {
                    this.loadNextAssetImage();
                }
            }
        }

        private createImageFromBlob(assetImage: AssetImage, image: Blob) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
               // this.images.push(reader.result);
               assetImage.fileContent = reader.result;
               // console.log(assetImage);
            }, false);
            if (image) {
               reader.readAsDataURL(image);
               //fileSaveAs(image, 'BM - ' + assetImage.entityFile.entityId + '.pdf');
            }
        }

        private loadNextAssetImage() {
            if (this.imageIndex < (this.assetImages.length - 1)) {
                this.imageIndex++;
                this.loadAssetImageLoop();
            } else {
                this.imageLoading = false;
            }
        }

        public onAssetValidate() {
          this.operationType = OperationType.AssetValidation;
          this.confirmationMessage = 'Validati propunerea selectata?';
          this.confirmationModal.show();
      }

      public onAssetReject() {
        this.operationType = OperationType.AssetReject;
        this.confirmationMessage = 'Refuzati propunerea selectata?';
        this.confirmationModal.show();
    }


    public onMarketPlaceReject() {
      this.operationType = OperationType.MarketPlaceReject;
      this.confirmationMessage = 'Refuzati propunerea selectata?';
      this.confirmationModal.show();
  }

    public onInfoEmployee() {
      this.operationType = OperationType.InfoEmployee;
      this.confirmationInfoEmployeeMessage = 'Doriti sa adaugati notite echipamentului selectat?';
      this.infoEmployeeModal.show();
  }

    onAssetFileMarketUpload() {
      this.marketModal.hide();
      const assetId = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
      const dialogRef = this.dialog.open(UploadMarketModalComponent, {
        panelClass: 'centered-middle-modal', height: '80%', maxHeight: '80%', disableClose: true, width: '600px', position: { bottom: '15%', top: 'auto'},
        data: { uploadType: 'MARKETPLACE', uploadFolder: 'MARKETPLACE', assetId: assetId }
      });
      dialogRef.afterClosed().subscribe((res: EntityFileResult) => {
        if(res.success){
          this.notificationService.showSuccess(res.message, 'Incarcare fisier');
          this.marketModal.show();
          let assetId = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
          this.refreshEntityFiles(assetId, true);
        } else {
          this.marketModal.show();
          this.refreshEntityFiles(assetId, true);
          //this.notificationService.showError(res.message, 'Incarcare fisier');
        }
        // this.refreshAssets();

      });
    }

    onAssetFileBuyUpload() {
      this.marketModal.hide();
      const assetId = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
      const dialogRef = this.dialog.open(UploadBuyModalComponent, {
        panelClass: 'centered-middle-modal', height: '80%', maxHeight: '80%', disableClose: true, width: '600px', position: { bottom: '15%', top: 'auto'},
        data: { uploadType: 'BUY_BOOK_EMPLOYEE', uploadFolder: 'BUY_BOOK_EMPLOYEE', assetId: assetId }
      });
      dialogRef.afterClosed().subscribe((res: EntityFileResult) => {
        if(res.success){
          this.notificationService.showSuccess(res.message, 'Incarcare fisier');
          this.refreshAssets();
          this.clearSelection();
          //this.buyModal.show();
          //let assetId = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
          //this.refreshEntityFiles(assetId, true);
        } else {
          //this.buyModal.show();
          //this.refreshEntityFiles(assetId, true);
          //this.notificationService.showError(res.message, 'Incarcare fisier');
        }
        // this.refreshAssets();

      });
    }

    public buy() {
      this.buyMessage = 'Doriti sa achizitionati echipamentul selectat?';
      this.buyModal.show();
  }

  public closeBuyModal() {
    this.buyMessage = '';
    this.buyModal.hide();
}

public onBuyConfirm() {
  this.buyMessage = '';
  this.buyModal.hide();
  this.saveBuyAsset();
}

public saveBuyAsset() {
  this.isSaved = false;
  let buyAsset = new AssetBuy();
      buyAsset.id = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
this.assetHttpService.buyAsset(buyAsset)
.subscribe((result: BuyResult) => {
    if (result.success) {
        this.notificationService.showSuccess(result.message, 'Cumparare echipament');
        this.isSaved = true;
        this.clearSelection();
        this.refreshAssets();
        // this.router.navigate(['/asset/buy']);

    } else if (!result.success) {
        this.notificationService.showError('Motiv: ' + result.message + '!', 'Eroare salvare date');
        this.isSaved = true;
        this.clearSelection();
        this.refreshAssets();
    }
});
}

    public get allowBuy(): boolean {
      if(this.selectedAssets.length > 0){
       for (let index = 0; index < this.selectedAssets.length; index++) {
         const element = this.selectedAssets[index];
         if(element.state != null || (element.isInService || element.isInTempTransfer || element.isInTransfer || element.isInMarketPlace)){
           return false;
         }
       }
       return true;
      } else {
       return false;
      }
 }

    // public get allowUploadFile(): boolean {
    //   return this.selectedAssets.length > 0 && this.selectedAssets[0].state != null && this.selectedAssets[0].state.code === 'BUY_BOOK';
    // }

    public selectEmployee(type: string) {
      // //console.log(JSON.stringify(this.decodedToken));
      // const params: Array<Param> = new Array<Param>();
      // params.push(new Param('onlyActive', "true"));
      // params.push(new Param('exceptUser', "true"));
      // this.employeeList.refresh(params);
      // this.employeeListModal.show();

      if(type == "TEMP"){
        this.operationType = OperationType.TempTransfer;
      } else {
        this.operationType = OperationType.Transfer;
      }

      const empFilter: EmployeeFilter = new EmployeeFilter();
      empFilter.exceptEmpIds = new Array<number>();
      for (let index = 0; index < this.selectedEmpIds.length; index++) {
        const element = this.selectedEmpIds[index];
        empFilter.exceptEmpIds.push(element);
      }
      //console.log(JSON.stringify(this.decodedToken));
      const params: Array<Param> = new Array<Param>();
      params.push(new Param('onlyActive', "true"));
      params.push(new Param('exceptUser', "true"));
      params.push(new Param('exceptEmpIds', JSON.stringify(empFilter)));
      this.employeeList.refresh(params);
      this.employeeListModal.show();
  }

  public setEmployeeListSelectedItem(employees: Array<Employee>) {
    this.employeeListSelectedItem = employees != null && employees.length === 1 ? employees[0] : null;
}

public setSelectedEmployee() {
  this.selectedEmployee = this.employeeListSelectedItem;
  this.employeeListModal.hide();

  if(this.operationType == 8){
    // this.tempTransfer();
    this.selectAssetEntity();
  } else {
    this.transfer();
  }
}

   /*begin ASSETENTITY */
   public selectAssetEntity() {
    const params: Array<Param> = this.getTempFilters();
    this.assetEntityList.refresh(params);
    this.assetEntityListModal.show();
}

public setSelectedAssetEntity() {
    const items: Array<AssetEntity> = this.assetEntityList.selectedItems;
    this.assetEntity = ((items != null) && (items.length === 1)) ? items[0] : null;
    this.assetEntityListModal.hide();
    if(this.assetEntity !=null){
      this.tempTransfer();
    }
}

public closeAssetEntity() {
  this.assetEntityListModal.hide();
  this.employeeListModal.show();
}

/*end ASSETENTITY */

public transfer() {
  if(this.selectedEmployee != null){
    this.operationType = OperationType.Transfer;
    this.transferMessage = 'Doriti sa transferati echipamentul selectat catre ' + this.selectedEmployee.email + ' ?';
    this.transferModal.show();
  } else {
    this.notificationService.showInfo('Nu a fost selectat niciun angajat!', 'Transfer echipament');
  }
}

public tempTransfer() {
  if(this.selectedEmployee != null){
    this.transferMessage = 'Doriti sa transferati temporar echipamentul selectat catre ' + this.selectedEmployee.email + ' ?';
    this.transferModal.show();
  } else {
    this.notificationService.showInfo('Nu a fost selectat niciun angajat!', 'Transfer temporar echipament');
  }
}

public closeTransferModal() {
  this.transferMessage = '';
  this.selectedEmployee = null;
  this.transferModal.hide();
}

public get allowTransfer(): boolean {
  return this.transferReason != null && this.transferReason != '' && this.transferReason.length > 4;
}

public getTempFilters(): Array<Param> {
  const params = new Array<Param>();
  const assetFilter: AssetFilter = new AssetFilter();

  if (this.selectedEmployee != null) {
    assetFilter.employeeIds = new Array<number>();
    assetFilter.employeeIds.push(this.selectedEmployee.id);
}

  //assetFilter.filter = this.filter;
  //params.push(new Param('pageSize', this.pageSize.toString()));
  params.push(new Param('jsonFilter', JSON.stringify(assetFilter)));
  //params.push(new Param('pageSize', this.pageSize.toString()));
  return params;
}

public changeRowSelection() {
  if (this.assetRowSelection === 'single') {
      this.assetRowSelection = 'multiple';
  } else {
      this.selectedAssets = new Array<AssetSimpleDetail>();
      // this.selectedAssetId = 0;
      this.assetRowSelection = 'single';
  }
}

public getFilters(): Array<Param> {
  const params = new Array<Param>();
  const assetFilter: AssetFilter = new AssetFilter();

  assetFilter.filter = this.filter;
  //params.push(new Param('pageSize', this.pageSize.toString()));
  params.push(new Param('jsonFilter', JSON.stringify(assetFilter)));
  params.push(new Param('reset', 'true'));
  //params.push(new Param('pageSize', this.pageSize.toString()));
  return params;
}

public printLabel () {
  let goToprint =  true;
  const label = new Array<PrintLabel>();

  this.selectedAssets.forEach(asset => {
      if (asset.name.length < 1) {
          this.notificationService.showWarning('Pentru numarul de inventar ' + asset.invNo + ' nu a fost selectata denumirea!', '');
          goToprint = false;
      } else {
        const labelPush = new PrintLabel();
        labelPush.invNo = asset.invNo;
        labelPush.description = asset.name;
        labelPush.purchaseDate = asset.purchaseDate;
        labelPush.sapCode = asset.sapCode;
        labelPush.serialNumber = asset.serialNumber;
        ////labelPush.company = asset.company.code;
        label.push(labelPush);
      }
  });

  if (goToprint) {
      this.assetHttpService.printLocalLabel(label).subscribe( (res: boolean) => {
          if (res) {
              this.markPrintData();
              // this.refreshAssets();
          }
      });
  }
}

public markPrintData () {
  const printLabels: Array<number> = new Array<number>();
  let notifyPrint = false;
  let printedAssetCount = 0; 

  this.selectedAssets.forEach(asset => {
      printLabels.push(asset.id);
      if(printLabels.length > 0) printedAssetCount++
  });

  if (printedAssetCount > 0 && !notifyPrint) {
    this.notificationService.showInfo('' + printedAssetCount + ' etichete au fost printate!', '');
    notifyPrint = true;
  }

  this.assetHttpService
  .updatePrintDateLabel(printLabels)
  .subscribe((res) => {
      if (res.statusCode === 200) {
          this.clearSelection();
          this.refreshAssets();
      } else {
          this.notificationService.showError('Datele nu au fost actualizate cu succes!', '');
      }

  }, (error) => {
      this.notificationService.showError('Eroare server!', '');
  });
}

// public onPageUpdate(number: number) {
//   this.pageSize = number;
//   this.refreshAssets();
// }

public get allowTempTransferStock(): boolean {
  if(this.selectedAssets.length > 0 && this.selectedAssets.length === 1){
   for (let index = 0; index < this.selectedAssets.length; index++) {
     const element = this.selectedAssets[index];
     if(element.state != null || (element.isInService || element.isInTransfer || element.isInTempTransfer || element.isInMarketPlace)){
       return false;
     }
   }
   return true;
  } else {
   return false;
  }
}

public get allowMarketPlace(): boolean {
  if(this.selectedAssets.length > 0 && this.selectedAssets.length === 1){
   for (let index = 0; index < this.selectedAssets.length; index++) {
     const element = this.selectedAssets[index];
     if(element.state != null || (element.isInService || element.isInTransfer || element.isInTempTransfer || element.isInMarketPlace)){
       return false;
     }
   }
   return true;
  } else {
   return false;
  }
}

public get allowRejectMarketPlace(): boolean {
  if(this.selectedAssets.length > 0 && this.selectedAssets.length === 1){
   for (let index = 0; index < this.selectedAssets.length; index++) {
     const element = this.selectedAssets[index];
     if(element.isInMarketPlace){
       return true;
     }
   }
   return false;
  } else {
   return false;
  }
}

public market() {
  this.infoMarket1 = '';
  this.infoMarket2 = '';
  this.url= '';
  this.netAmount = 0;
  this.marketMessage = 'Doriti sa propuneti scoaterea la vanzare a echipamentului selectat?';
  this.marketModal.show();
  let assetId = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
  this.refreshEntityFiles(assetId, true);
}

public closeMarketModal() {
  this.marketMessage = '';
  this.marketModal.hide();
}

public onMarketConfirm() {
  this.marketMessage = '';
  this.marketModal.hide();
  this.saveMarketAsset();
}

public saveMarketAsset() {
  this.isSaved = false;
  let marketPlace = new MarketPlace();
      marketPlace.id = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
      marketPlace.netAmount = this.netAmount;
      marketPlace.infoMarket1 = this.infoMarket1;
      marketPlace.infoMarket2 = this.infoMarket2;
      marketPlace.url = this.url;

this.assetHttpService.marketPlace(marketPlace)
.subscribe((result: MarketPlaceResult) => {
    if (result.success) {
        this.notificationService.showSuccess(result.message, 'Vanzare echipament');
        this.isSaved = true;
        this.clearSelection();
        this.refreshAssets();
        // this.router.navigate(['/asset/buy']);

    } else if (!result.success) {
        this.notificationService.showError('Motiv: ' + result.message + '!', 'Eroare salvare date');
        this.isSaved = true;
        this.clearSelection();
        this.refreshAssets();
    }
});
}

public refreshEntityFiles(assetId: number, loadAssetImages: boolean) {
  let inventoryId: number = 15;
   this.entityFileHttpService.getByEntity('MARKETPLACE', assetId)
   .subscribe((entityFiles: Array<EntityFile>) => {
       this.existingAssetImages.splice(0, this.existingAssetImages.length);
       this.assetImages.forEach((assetImage: AssetImage) => this.existingAssetImages.push(assetImage));
       this.assetImages.splice(0, this.assetImages.length);
       this.assetFiles.splice(0, this.assetFiles.length);
       // this.entityFileMemoryDataSource.clear();
       entityFiles.forEach((entityFile: EntityFile) => {
           if (entityFile.fileType.startsWith('image/')) {
               let fileContent: any = null;
               this.existingAssetImages.forEach((assetImage: AssetImage) => {
                   if (assetImage.entityFile.id === entityFile.id) {
                       fileContent = assetImage.fileContent;
                   }
               });
               this.assetImages.push(new AssetImage(entityFile, fileContent));
           }
           else {
               this.assetFiles.push(entityFile);
               // this.entityFileMemoryDataSource.addItem(entityFile);
           }
       });
      // this.fileList.refresh(null);
       if (loadAssetImages) this.loadAssetImages();
   });
}

public rejectMarketPlace() {
  this.isSaved = false;
  let validateBuyAsset = new RejectMarketPlace();
      validateBuyAsset.id = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
      validateBuyAsset.marketState = 'REJECT_MARKETPLACE';
      validateBuyAsset.currentState = this.selectedAssets[0].state.code;
      validateBuyAsset.reason = this.reasonReject;
this.assetHttpService.rejectMarketPlace(validateBuyAsset)
.subscribe((result: BuyResult) => {
    if (result.success) {
        this.notificationService.showSuccess(result.message, 'Retragere marketplace');
        // this.router.navigate(['/asset/stockittovalidate']);
        this.isSaved = true;
        this.clearSelection();
        this.refreshAssets();
    } else if (!result.success) {
        this.notificationService.showError('Motiv: ' + result.message + '!', 'Eroare salvare date');
        this.isSaved = true;
        this.clearSelection();
        this.refreshAssets();
    }
});
}

public deletePhoto(id: number){
  if (confirm('Esti sigur ca vrei sa stergi acesta poza?')){
      let assetId = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
      this.entityFileHttpService.deleteMarketPlacePhoto(id).subscribe((res) => {
        if(res.success){
          this.notificationService.showSuccess(res.message, 'Stergere foto marketplace');
          this.refreshEntityFiles(assetId, true);
        } else {
          this.notificationService.showError(res.message, 'Stergere foto marketplace');
        }

      });
  }

}

}

enum OperationType {
    NotSet = 1,
    AssetValidation = 2,
    AssetReject = 3,
    Delete = 4,
    ProcessAssetOp = 5,
    Transfer = 6,
    InfoEmployee = 7,
    TempTransfer = 8,
    MarketPlaceReject = 9,
}
