export class AddAssetInvPlus {
    id: number;
    // name: string;
    // serialNumber: string;
    // // sapCode: string;
    // // erpCode: string;
    // documentTypeId: number;
    // docNo1: string;
    // // assetCategoryId: number;
    // dictionaryItemId: number;
    // // quantity: number;
    // // validated: boolean;
    // costCenterId: number;
    // materialId: number;
    // partnerId: number;
    // companyId: number;
    // orderId: number;
    // employeeId: number;
    // // expAccountId: number;
    // name2: string;
    // subCategoryId: number;
    // // offerMaterialId: number;
    // orderMaterialId: number;
    // stockId: number;
    // // inventoryNumber: string;
    // // lastInventoryDate: string;
    // // lastInventoryDoc: string;
    // plateNo: string;
    // receptionPrice: number;
    // guid: string;
    assetId: number;
}
