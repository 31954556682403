import { Component } from '@angular/core';
// import { GenericTableList } from '../../generic/generic.table.list';
// import { InvState } from '../../../model/api/inventory/inv-state';
// import { AppData } from '../../../app-data';
// import { Param } from '../../../model/common/param';
// @Component({
//     selector: 'inv-state-list',
//     templateUrl: '../../generic/generic.table.list.html'
// })
// export class InvStateList extends GenericTableList<InvState, number> {

//     public listName: string = 'INVSTATES';

//     constructor() {
//         super('code', 'asc', '');
//         this.columns = AppData.ColumnDefinitions[this.listName];
//     }

//     public refresh(filters: Array<Param>) {
//         this.columns = AppData.ColumnDefinitions[this.listName];
//         super.refresh(filters);
//     }
// }


import { InvState } from '../../../model/api/inventory/inv-state';
import { GenericAgGridList } from '../../generic/generic.ag-grid.list';


@Component({
    selector: 'inv-state-list',
    templateUrl: '../../../forms/generic/generic.ag-grid.list.html'
})
export class InvStateList extends GenericAgGridList<InvState, number> {

    constructor() {
      super('code', 'asc', '');

        this.columnDefs = [
            { field: 'code', headerName: 'Cod', width: 100, sortable: true, filter: true, checkboxSelection: true },
            { field: 'name', headerName: 'Denumire', width: 100, sortable: true, filter: true, checkboxSelection: false },
        ];
    }
}
