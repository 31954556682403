import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig } from "../../../config";
import { EmployeeCostCenter } from "../../../model/api/administration/employee-cost-center";
import { EmployeeCostCenterAdd } from "../../../model/api/assets/employee-cost-center-add";
import { GenericHttpService } from "../generic.http.service";
import { EmployeeManager } from '../../../model/api/administration/employee-manager';
import { EmployeeManagerAdd } from "../../../model/api/assets/employee-manager-add";
import { ValidateEmployeeManager } from "../../../model/api/administration/validate-employee-manager";
import { EmployeeManagerResult } from "../../../model/api/result/employee-manager-result";
import { DeleteEmployeeManager } from "../../../model/api/administration/delete-employee-manager";


@Injectable()
export class EmployeeManagerHttpService extends GenericHttpService<EmployeeManager, number> {
    constructor(public http: HttpClient) {
        super(http, "", "employeemanagers");
    }

    public addManagerByEmployee(reco: EmployeeManagerAdd): Observable<any> {
        // return super.create(reco, 'reco')
        return this.http.post(AppConfig.urlPrefix + this.url + '/add', reco, { headers: this.headers }).pipe(
        map((data: Response) => {
            return data;
        }));
    }

    public deleteManagerCostCenter(id: number): Observable<any> {
        return this.http.delete(AppConfig.urlPrefix + this.url + '/remove' + `/${id}`).pipe(
        map((data: Response) => {
            return data;
        }));
    }

    acceptAllocate(item: ValidateEmployeeManager): Observable<EmployeeManagerResult> {
      const url: string = AppConfig.urlPrefix + this.url + '/acceptAllocate';
      return this.http.post(url, item, { headers: this.headers }).pipe(
          map((data: EmployeeManagerResult) => {
              return data;
          }));
    }

    rejectAllocate(item: ValidateEmployeeManager): Observable<EmployeeManagerResult> {
      const url: string = AppConfig.urlPrefix + this.url + '/rejectAllocate';
      return this.http.post(url, item, { headers: this.headers }).pipe(
          map((data: EmployeeManagerResult) => {
              return data;
          }));
    }

    rejectMultipleAllocate(item: ValidateEmployeeManager[]): Observable<EmployeeManagerResult> {
      const url: string = AppConfig.urlPrefix + this.url + '/rejectMultipleAllocate';
      return this.http.post(url, item, { headers: this.headers }).pipe(
          map((data: EmployeeManagerResult) => {
              return data;
          }));
    }

    deleteMultipleAllocate(item: DeleteEmployeeManager[]): Observable<EmployeeManagerResult> {
      const url: string = AppConfig.urlPrefix + this.url + '/deleteMultipleAllocate';
      return this.http.post(url, item, { headers: this.headers }).pipe(
          map((data: EmployeeManagerResult) => {
              return data;
          }));
    }

    allocateApprove(guid: string): Observable<EmployeeManagerResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/allocateApprove/' + guid;;
        return this.http.post(url, { headers: this.headers }).pipe(
            map((data: EmployeeManagerResult) => {
                return data;
            }));
    }
  
    allocateReject(guid: string, reason: string): Observable<EmployeeManagerResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/allocateReject/' + guid + '/' + reason;;
        return this.http.post(url, { headers: this.headers }).pipe(
            map((data: EmployeeManagerResult) => {
                return data;
            }));
    }
}
