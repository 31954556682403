import { Injectable } from '@angular/core';
import { Employee } from '../../../model/api/administration/employee';
import { GenericHttpService } from '../generic.http.service';
import { AppConfig } from '../../../config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Param } from '../../../model/common/param';
import { EmployeeImport } from '../../../model/common/import/employee-import';
import { EmployeeImportSuperUser } from '../../../model/common/import/employee-import-superuser';
import { CreateAssetSAPResult } from '../../../model/api/result/create-asset-SAP-result';

@Injectable()
export class EmployeeHttpService extends GenericHttpService<Employee, number> {
    constructor(public http: HttpClient) {
        super(http, "", "employees");
    }

    // public import(file: any) {
    //     let input = new FormData();
    //     input.append('file', file);

    //     return this.http
    //         .post(AppConfig.urlPrefix + this.url + '/import', input).pipe(
    //         map((data: Response) => {
    //             return data;
    //         }));
    // }

    public import(item: EmployeeImport): Observable<any> {
      console.log(JSON.stringify(item));
      return this.http.post(AppConfig.urlPrefix + this.url + '/import',
          item, { headers: this.headers }).pipe(
          map((data: Response) => {
              return data;
          }));
    }

    getDetailById(id: number): Observable<any> {
        return this.http.get(AppConfig.urlPrefix + this.url + `/detail/${id}`).pipe(
            map((data: Response) => {
                return data;
            }));
    }

    public sendEmail(employeeId: number): Observable<any> {
        // console.log('ITEMS: ', item);
        return this.http.post(AppConfig.urlPrefix + this.url + `/sendEmail/ ${employeeId}`,
            { headers: this.headers }).pipe(
            map((data: Response) => {
                return data;
            }));
    }

    public sendBookEmail(employeeId: number): Observable<any> {
        // console.log('ITEMS: ', item);
        return this.http.post(AppConfig.urlPrefix + this.url + `/sendBookEmail/ ${employeeId}`,
            { headers: this.headers }).pipe(
            map((data: Response) => {
                return data;
            }));
    }

    public sendITBookEmail(employeeId: number): Observable<any> {
        // console.log('ITEMS: ', item);
        return this.http.post(AppConfig.urlPrefix + this.url + `/sendITBookEmail/${employeeId}`,
            { headers: this.headers }).pipe(
            map((data: Response) => {
                return data;
            }));
    }

    public sendBookEmailPreview(employeeId: number): Observable<any> {
        return this.http.get(AppConfig.urlPrefix + this.url + `/sendBookEmailPreview/ ${employeeId}`, { responseType: 'text' }).pipe(
            map((data: any) => {
                return data;
            }));
    }

    public export(params: Array<Param>) {
        let searchParams: HttpParams = null;
        const url = AppConfig.urlPrefix + this.url + '/export';
        searchParams = this.getSearchParams(params);
        return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                        map(res => res));
    }

    public updateAllEmp(): Observable<any> {
        return this.http
            .post(AppConfig.urlPrefix + this.url + '/updateAllEmployees', []).pipe(
            map((data: Response) => {
                return data;
            }));
    }

    public checkEditUniqueIC(employeeId: number, internalCode: string) {
      return this.http.get(AppConfig.urlPrefix + this.url + '/checkUniqueEditIC' + `/${employeeId}` + `/${internalCode}`, { headers: this.headers }).pipe(
      map((data: number) => {
          return data;
      }));
    }

    public checkEditUniquePID(employeeId: number, personID: string) {
      return this.http.get(AppConfig.urlPrefix + this.url + '/checkUniqueEditPID'+ `/${employeeId}` + `/${personID}`, { headers: this.headers }).pipe(
      map((data: number) => {
          return data;
      }));
    }

    public checkEmployeeHasAssetsAllocated(employeeId: number) {
        return this.http.get(AppConfig.urlPrefix + this.url + '/checkEmployeeHasAssetsAllocated'+ `/${employeeId}`, { headers: this.headers }).pipe(
        map((data: number) => {
            return data;
        }));
      }

    public exportTemplateSuperUser() {
      let searchParams: HttpParams = null;
      let url = AppConfig.urlPrefix + this.url + '/exportTemplateSuperUser';
      return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                      map(res => res));
    }

    public exportTemplateCorrectionSuperUser() {
      let searchParams: HttpParams = null;
      let url = AppConfig.urlPrefix + this.url + '/exportTemplateCorrectionSuperUser';
      return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                      map(res => res));
    }

    public uploadV1(item: EmployeeImportSuperUser): Observable<CreateAssetSAPResult> {
      //console.log(JSON.stringify(item));
      return this.http.post(AppConfig.urlPrefix + this.url + '/importv1superuser',
          item, { headers: this.headers }).pipe(
          map((data: CreateAssetSAPResult) => {
              return data;
          }));
  }

  public importCorrection(item: EmployeeImport): Observable<any> {
    console.log(JSON.stringify(item));
    return this.http.post(AppConfig.urlPrefix + this.url + '/import',
        item, { headers: this.headers }).pipe(
        map((data: Response) => {
            return data;
        }));
  }


public deleteEmployees(ids: number[]): Observable<CreateAssetSAPResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/deleteEmployee';

  return this.http.post(url, ids, { headers: this.headers }).pipe(
      map((data: CreateAssetSAPResult) => {
          return data;
      }));
}

}
