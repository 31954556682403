import { AccMonthHttpService } from './../../../services/http/accounting/acc-month.http.service';
import { AccMonth } from './../../../model/api/accounting/acc-month';
import { CostCenterHttpService } from './../../../services/http/administration/cost-center.http.service';
import { AppConfig } from './../../../config';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { Param } from '../../../model/common/param';
import { Department } from '../../../model/api/administration/department';
import { Employee } from '../../../model/api/administration/employee';
import { Location } from '../../../model/api/administration/location';
import { Room } from '../../../model/api/administration/room';
import { RegionHttpService } from '../../../services/http/administration/region.http.service';
import { LocationHttpService } from '../../../services/http/administration/location.http.service';
import { DepartmentHttpService } from '../../../services/http/administration/department.http.service';
import { RoomDetailHttpService } from '../../../services/http/administration/room-detail.http.service';
import { AdmCenterHttpService } from '../../../services/http/administration/adm-center.http.service';
import { AssetHttpService } from '../../../services/http/assets/asset.http.service';
import { InventoryHttpService } from '../../../services/http/inventory/inventory.http.service';
import { DivisionHttpService } from '../../../services/http/administration/division.http.service';
import { EmployeeHttpService } from '../../../services/http/administration/employee.http.service';
import { AssetInvFullDetailListComponent } from './asset-inv-full-detail.list';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DepartmentListComponent } from '../../administrations/departments/department.list';
import { EmployeeListComponent } from '../../administrations/employees/employee.list';
import { AdmCenterListComponent } from '../../administrations/adm-centers/adm-center.list';
import { RegionListComponent } from '../../administrations/regions/region.list';
import { LocationListComponent } from '../../administrations/locations/location.list';
import { RoomListComponent } from '../../administrations/rooms/room.list';
import { CostCenterListComponent } from '../../administrations/cost-centers/cost-center.list';
import { DivisionListComponent } from '../../administrations/divisions/division.list';
import { Inventory } from '../../../model/api/inventory/inventory';
import { CostCenter } from '../../../model/api/administration/cost-center';
import { AdmCenter } from '../../../model/api/administration/adm-center';
import { Division } from '../../../model/api/administration/division';
import { Region } from '../../../model/api/administration/region';
import { AppUtils } from '../../../common/app.utils';
import { InventoryList } from '../../inventory/inventory.list';

@Component({
    selector: 'app-asset-inventory-report',
    templateUrl: 'asset-inventory-report.html',
    styleUrls: ['asset-inventory-report.scss'],
    providers: [ AdmCenterHttpService, AssetHttpService, DepartmentHttpService, InventoryHttpService, DivisionHttpService,
        LocationHttpService, RegionHttpService, RoomDetailHttpService, EmployeeHttpService, CostCenterHttpService, AccMonthHttpService ]
})
export class AssetInventoryReportComponent {

    @ViewChild('assetInvFullDetailList') public assetInvFullDetailList: AssetInvFullDetailListComponent;
    @ViewChild('departmentsModal') public departmentsModal: ModalDirective;
    @ViewChild('confirmationModal') public confirmationModal: ModalDirective;

    @ViewChild('inventoryList') public inventoryList: InventoryList;
    @ViewChild('inventoryListModal') public inventoryListModal: ModalDirective;

    @ViewChild('departmentList') public departmentList: DepartmentListComponent;
    @ViewChild('departmentListModal') public departmentListModal: ModalDirective;

    @ViewChild('employeeList') public employeeList: EmployeeListComponent;
    @ViewChild('employeeListModal') public employeeListModal: ModalDirective;

    @ViewChild('admCenterList') public admCenterList: AdmCenterListComponent;
    @ViewChild('admCenterListModal') public admCenterListModal: ModalDirective;

    @ViewChild('regionList') public regionList: RegionListComponent;
    @ViewChild('regionListModal') public regionListModal: ModalDirective;

    @ViewChild('locationList') public locationList: LocationListComponent;
    @ViewChild('locationListModal') public locationListModal: ModalDirective;

    @ViewChild('roomList') public roomList: RoomListComponent;
    @ViewChild('roomListModal') public roomListModal: ModalDirective;

    @ViewChild('costCenterList') public costCenterList: CostCenterListComponent;
    @ViewChild('costCenterListModal') public costCenterListModal: ModalDirective;

    @ViewChild('divisionList') public divisionList: DivisionListComponent;
    @ViewChild('divisionListModal') public divisionListModal: ModalDirective;

    public selectedEmployee: Employee = null;
    public selectedLocation: Location = null;
    public selectedRoom: Room = null;

    public confirmationMessage: string = "";
    public operationType: number = OperationType.NotSet;

    public filter: string = "";
    public smallPageSize: number = 5;
    public largePageSize: number = 10;

    public notIdentifiedFilter: string = "";
    public conditionType: string = "AND";
    public wordCount: number = 0;
    public wordMinLength: number = 3;
    public letterCount: number = 0;
    public reportTypeCode: string = 'ALL';
    public reportTypeName: string = 'Toate';
    public custody: string = '-';
    public showFilters: boolean = true;
    public showSearchButtoIconClass: string = 'fa fa-search-minus';
    public transferStartDate: Date;
    public transferEndDate: Date;
    public filterPurchaseDate: string;
    public isLoading: boolean = false;

    public pageSizeUpdatedEvent: EventEmitter<number> = new EventEmitter<number>();
    public requestInvCompOpRefreshEvent: EventEmitter<Array<Param>> = new EventEmitter<Array<Param>>();
    public requestInvCompDetailRefreshEvent: EventEmitter<Array<Param>> = new EventEmitter<Array<Param>>();


    public selectedInventory: Inventory = null;
    public selectedDepartments: Array<Department> = new Array<Department>();
    public selectedEmployees: Array<Employee> = new Array<Employee>();
    public selectedCostCenters: Array<CostCenter> = new Array<CostCenter>();
    public selectedAdmCenters: Array<AdmCenter> = new Array<AdmCenter>();
    public selectedDivisions: Array<Division> = new Array<Division>();
    public selectedRegions: Array<Region> = new Array<Region>();
    public selectedLocations: Array<Location> = new Array<Location>();
    public selectedRooms: Array<Room> = new Array<Room>();
    public months: Array<string> = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'];
    public selectedMonth: number = 0;
    public selectedYear: number = 0;
    public selectedAccMonth: AccMonth = null;

    // public get notScannedViewMode(): boolean { return (this.reportType === 'NOT_SCANNED'); }
    public get notScannedViewMode(): boolean { return false; }

    // public showDepartmentDetails: boolean = AppConfig.SHOW_DEPARTMENT_DETAILS;
    public get useAdmCenter(): boolean { return AppConfig.USE_ADM_CENTER; }
    public get useCostCenter(): boolean { return AppConfig.USE_COST_CENTER; }
    public get useEmployee(): boolean { return AppConfig.USE_EMPLOYEE; }
    public get useDepartment(): boolean { return AppConfig.USE_DEPARTMENT; }
    public get useRegion(): boolean { return AppConfig.USE_REGION; }


    constructor(public assetHttpService: AssetHttpService, public admCenterHttpService: AdmCenterHttpService,
        public departmentHttpService: DepartmentHttpService, public inventoryHttpService: InventoryHttpService, public divisionHttpService: DivisionHttpService,
        public locationHttpService: LocationHttpService, public regionHttpService: RegionHttpService, public roomDetailHttpService: RoomDetailHttpService, 
        public employeeHttpService: EmployeeHttpService, public costCenterHttpService: CostCenterHttpService,  public accMonthHttpService: AccMonthHttpService) {
    }

    ngAfterViewInit() {

        let currentDate: Date = new Date();
        this.selectedMonth = currentDate.getMonth();
        this.selectedYear = currentDate.getFullYear();

        this.updateSelectedAccMonth();
        this.refreshAssets();
    }

    public onReportTypeUpdate(reportTypeCode: string) {
        this.reportTypeCode = reportTypeCode;

        switch(this.reportTypeCode) {
            case 'ALL':
                this.reportTypeName = 'Toate';
                break;
            case 'PLUS':
                this.reportTypeName = 'Plusuri';
                break;
            case 'MINUS':
                this.reportTypeName = 'Minusuri';
                break;
            case 'NOT_IDENTIFIED':
                this.reportTypeName = 'Plusuri etichete temporare';
                break;
            case 'TRANSFER_ROOM_SAME_LOCATION':
                this.reportTypeName = 'Transferuri in Cladire';
                break;
            case 'TRANSFER_LOCATION_SAME_ADMCENTER':
                this.reportTypeName = 'Transferuri in Regiune';
                break;
            case 'TRANSFER_LOCATION_DIFF_ADMCENTER':
                this.reportTypeName = 'Transferuri intre Regiuni';
                break;
        }

        this.checkForRefresh();
    }

   /* begin inventory */
    public selectInventory() {
        this.inventoryListModal.show();
        this.inventoryList.selectedItems = new Array<Inventory>();
        this.inventoryList.refresh(null);
    }

    public setSelectedInventory() {
        this.selectedInventory = this.inventoryList.selectedItems != null && this.inventoryList.selectedItems.length > 0 ? this.inventoryList.selectedItems[0] : null;
        this.inventoryListModal.hide();
        this.checkForRefresh();
    }
    /* end inventory */

     /*begin costcenter*/
    public selectCostCenters() {

        let selectedAdmCenters: Array<AdmCenter> = null;
        let selectedCostCenters: Array<CostCenter> = null;

        selectedCostCenters = this.selectedCostCenters;
        selectedAdmCenters = this.selectedAdmCenters;

        let params = new Array<Param>();
        params.push(new Param("admCenterIds", AppUtils.getIdsList<AdmCenter, number>(selectedAdmCenters)));

        this.costCenterListModal.show();
        this.costCenterList.selectedItems = selectedCostCenters;
        this.costCenterList.refresh(params);

    }

    public removeFromCostCenterSelection(costCenter: CostCenter) {
        let list: Array<CostCenter> = this.selectedCostCenters;
        let index: number = list.indexOf(costCenter);
        list.splice(index, 1);
        this.checkForRefresh();
    }

    public clearCostCenterSelection(filtersType: string) {

        this.selectedCostCenters = new Array<CostCenter>();
        this.checkForRefresh();
    }

    public setSelectedCostCenters() {
        this.selectedCostCenters = this.costCenterList.selectedItems;
        this.costCenterListModal.hide();
        this.checkForRefresh();
    }
    /*end costcenter*/

     /*begin asset type*/
     public selectDivisions() {
        this.divisionListModal.show();
        this.divisionList.selectedItems = this.selectedDivisions;
        // console.log('ASSETCLASS: ', this.assetTypeList.selectedItems);
        this.divisionList.refresh(null);
    }

    public removeFromDivisionSelection(division: Division) {
        let index: number = this.selectedDivisions.indexOf(division);
        this.selectedDivisions.splice(index, 1);
        this.checkForRefresh();
    }

    public clearDivisionSelection() {
        this.selectedDivisions = new Array<Division>();
        this.checkForRefresh();
    }

    public setSelectedDivisions() {
        this.selectedDivisions = this.divisionList.selectedItems;
        this.divisionListModal.hide();
        this.checkForRefresh();
    }

    /* end ASSET TYPE */

     /* begin employee */
    public selectEmployees() {

        let selectedCostCenters: Array<CostCenter> = null;
        let selectedEmployees: Array<Employee> = null;

        selectedCostCenters = this.selectedCostCenters;
        selectedEmployees = this.selectedEmployees;

        let params = new Array<Param>();
        params.push(new Param("costCenterIds", AppUtils.getIdsList<CostCenter, number>(selectedCostCenters)));

        this.employeeListModal.show();
        this.employeeList.selectedItems = this.selectedEmployees;
        this.employeeList.refresh(params);
    }

    public removeFromEmployeeSelection(employee: Employee) {
        let list: Array<Employee> = this.selectedEmployees;
        let index: number = list.indexOf(employee);
        list.splice(index, 1);
        this.checkForRefresh();
    }

    public clearEmployeeSelection(filtersType: string) {
        this.selectedEmployees = new Array<Employee>();
        this.checkForRefresh();
    }

    public setSelectedEmployees() {

        this.selectedEmployees = this.employeeList.selectedItems;
        this.employeeListModal.hide();
        this.checkForRefresh();
    }

    /*end employee*/

   /* begin admCenter */
    public selectAdmCenters() {

        this.admCenterListModal.show();
        this.admCenterList.selectedItems = this.selectedAdmCenters;
        this.admCenterList.refresh(null);
    }

    public removeFromAdmCenterSelection(admCenter: AdmCenter) {
        let list: Array<AdmCenter> = this.selectedAdmCenters;
        let index: number = list.indexOf(admCenter);
        list.splice(index, 1);
        this.checkForRefresh();
    }

    public clearAdmCenterSelection() {

        this.selectedAdmCenters = new Array<AdmCenter>();
        this.checkForRefresh();
    }

    public setSelectedAdmCenters() {

        this.selectedAdmCenters = this.admCenterList.selectedItems;
        this.admCenterListModal.hide();
        this.checkForRefresh();
    }
    /* end admCenter */

   /* begin region */
    public selectRegions() {
        this.regionListModal.show();
        this.regionList.selectedItems = this.selectedRegions;
        this.regionList.refresh(null);
    }

    public removeFromRegionSelection(region: Region) {
        let list: Array<Region> = this.selectedRegions;
        var index: number = list.indexOf(region);
        list.splice(index, 1);
        this.checkForRefresh();
    }

    public clearRegionSelection() {
        this.selectedRegions = new Array<Region>();
        this.checkForRefresh();
    }

    public setSelectedRegions() {

        this.selectedRegions = this.regionList.selectedItems;
        this.regionListModal.hide();
        this.checkForRefresh();
    }
    /* end region */

    /* begin location */
    public selectLocations() {

        let selectedRegions: Array<Region> = null;
        let selectedLocations: Array<Location> = null;
        selectedLocations = this.selectedLocations;
        selectedRegions = this.selectedRegions;
        let params = new Array<Param>();
        params.push(new Param("regionIds", AppUtils.getIdsList<Region, number>(selectedRegions)));

        this.locationListModal.show();
        this.locationList.selectedItems = selectedLocations;
        this.locationList.refresh(params);
    }

    public removeFromLocationSelection(location: Location) {
        let list: Array<Location> = this.selectedLocations;
        var index: number = list.indexOf(location);
        list.splice(index, 1);
        this.checkForRefresh();
    }

    public clearLocationSelection() {

        this.selectedLocations = new Array<Location>();
        this.checkForRefresh();
    }

    public setSelectedLocations() {

        this.selectedLocations = this.locationList.selectedItems;
        this.locationListModal.hide();
        this.checkForRefresh();
    }
    /* end location */

    /* begin room */
    public selectRooms() {
        let selectedRegions: Array<Region> = null;
        let selectedLocations: Array<Location> = null;
        let selectedRooms: Array<Room> = null;
        selectedLocations = this.selectedLocations;
        selectedRegions = this.selectedRegions;
        selectedRooms = this.selectedRooms;

        let params = new Array<Param>();
        params.push(new Param("regionIds", AppUtils.getIdsList<Region, number>(selectedRegions)));
        params.push(new Param("locationIds", AppUtils.getIdsList<Location, number>(selectedLocations)));

        this.roomListModal.show();
        this.roomList.selectedItems = selectedRooms;
        this.roomList.refresh(params);
    }

    public removeFromRoomSelection(room: Room) {
        let list: Array<Room> = this.selectedRooms;
        var index: number = list.indexOf(room);
        list.splice(index, 1);
        this.checkForRefresh();
    }

    public clearRoomSelection() {
        this.selectedRooms = new Array<Room>();
        this.checkForRefresh();
    }

    public setSelectedRooms() {
        this.selectedRooms = this.roomList.selectedItems;
        this.roomListModal.hide();
        this.checkForRefresh();
    }
    /* end room */

    public onCustodyUpdate(custody: string) {
        this.custody = custody;
        this.checkForRefresh();
    }

    public onToolbarButtonClicked(button: string) {
        this.showFilters = !this.showFilters;
        this.showSearchButtoIconClass = this.showFilters ? 'fa fa-search-minus' : 'fa fa-search-plus';
    }

    public checkForRefresh() {
        this.refreshAssets();
    }

    public refreshAssets() {
        let params: Array<Param> = this.getFilters();
    }

    public getFilters(): Array<Param> {
        let params = new Array<Param>();

        params.push(new Param('inventoryId', this.selectedInventory != null ? this.selectedInventory.id.toString() : '0'));

        params.push(new Param("regionIdsFin", AppUtils.getIdsList<Region, number>(this.selectedRegions)));
        params.push(new Param("costCenterIdsFin", AppUtils.getIdsList<CostCenter, number>(this.selectedCostCenters)));
        params.push(new Param("departmentIdsFin", AppUtils.getIdsList<Department, number>(this.selectedDepartments)));
	    params.push(new Param("employeeIdsFin", AppUtils.getIdsList<Employee, number>(this.selectedEmployees)));
        params.push(new Param("admCenterIdsFin", AppUtils.getIdsList<AdmCenter, number>(this.selectedAdmCenters)));
        params.push(new Param("locationIdsFin", AppUtils.getIdsList<Location, number>(this.selectedLocations)));
        params.push(new Param("divisionIdsFin", AppUtils.getIdsList<Division, number>(this.selectedDivisions)));
        params.push(new Param("roomIdsFin", AppUtils.getIdsList<Room, number>(this.selectedRooms)));

        params.push(new Param("filter", this.filter));
        params.push(new Param("reportType", this.reportTypeCode));
        params.push(new Param("custody", ((this.custody === '-') ? 'null' : (this.custody === 'YES' ? 'true' : 'false'))));
        params.push(new Param("filterPurchaseDate", this.filterPurchaseDate ? this.filterPurchaseDate : 'false' ));

        return params;
    }

    public refreshNotIdentified() {
        let params: Array<Param> = this.getNotIdentifieFilters();
        this.requestInvCompDetailRefreshEvent.emit(params);
    }

    public clearFilters() {
        this.selectedRegions = new Array<Region>();
        this.selectedAdmCenters= new Array<AdmCenter>();
        this.selectedCostCenters = new Array<CostCenter>();
        this.selectedDepartments = new Array<Department>();
        this.selectedEmployees = new Array<Employee>();
        this.selectedLocations = new Array<Location>();
        this.selectedRooms = new Array<Room>();
        this.selectedDivisions = new Array<Division>();
        this.filter = '';

        this.checkForRefresh();
    }

    public parseStartDate(dateString: string): Date {
        if (dateString) {
            this.transferStartDate = new Date(dateString);
            this.transferEndDate = new Date();
            this.checkForRefresh();
            return new Date(dateString);
        } else {
            return null;
        }
    }
    public parseEndDate(dateString: string): Date {
        if (dateString) {
            this.transferEndDate = new Date(dateString);
            this.checkForRefresh();
            return new Date(dateString);
        } else {
            return null;
        }
    }

    public exportToExcelApaNova() {

        let inventoryId: number = this.selectedInventory.id;
        let location: Location = this.selectedLocations[0];

        if (confirm(`Descarcati datele pentru Gestiune ${location.code}?`)) {
            this.isLoading = true;

            // this.assetHttpService
            //     .exportApaNova(inventoryId , location.id)
            //     .subscribe((blob) => {
            //         fileSaveAs(blob, this.selectedLocations[0].code + '.zip');
            //         this.isLoading = false;
            //     });
        }
    }

    public getSearchFilters(filter: string, wordCount: number, letterCount: number, wordMinLength: number): string[] {
        let result: Array<string> = new Array<string>();
        let filters: string[] = null;

        filter = filter.replace("-", " ").replace("+", " ").replace(".", " ").replace(",", " ").replace("/", " ").replace("\\", " ");
        while (filter.includes("  ")) filter = filter.replace("  ", " ");
        filters = filter.split(" ");

        //console.log('filters: ' + JSON.stringify(filters));

        filters.forEach((f) => {
            if ((f.length >= wordMinLength) && ((wordCount <= 0) || (result.length < wordCount))) {
                result.push(letterCount > 0 ? (f.length <= letterCount ? f : f.substring(0, letterCount)) : f);
            }
        });

        return result;
    }

    public getNotIdentifieFilters(): Array<Param> {
        let params = new Array<Param>();
        let filters: string[] = this.getSearchFilters(this.notIdentifiedFilter, this.wordCount, this.letterCount, this.wordMinLength);
        //console.log(JSON.stringify(filters));

        params.push(new Param("inventoryId", JSON.stringify(1)));
        params.push(new Param("filters", JSON.stringify(filters)));
	    params.push(new Param("conditionType", this.conditionType));

        return params;
    }

    
    public setSelectedMonth(month: number) {
        this.selectedMonth = month;

        this.updateSelectedAccMonth();
    }

    public setSelectedYear(year: number) {
        this.selectedYear = year;

        this.updateSelectedAccMonth();
    }

    public nextMonth() {
        if (this.selectedMonth == 12) {
            this.selectedMonth = 1;
            this.selectedYear = this.selectedYear + 1;
        }
        else
        {
            this.selectedMonth = this.selectedMonth + 1;
        }

        this.updateSelectedAccMonth();
    }

    public previousMonth() {
        if (this.selectedMonth == 1) {
            this.selectedMonth = 12;
            this.selectedYear = this.selectedYear - 1;
        }
        else {
            this.selectedMonth = this.selectedMonth - 1;
        }

        this.updateSelectedAccMonth();
    }

    public updateSelectedAccMonth() {
        this.accMonthHttpService.getAccMonth(this.selectedMonth, this.selectedYear).subscribe((accMonth: AccMonth) => {
            this.selectedAccMonth = accMonth;
            this.checkForRefresh();
        });
    }

    public reportInventoryList(reportTypeCode: string) {
            let reportType: string = "";
            let inventoryId: number = this.selectedInventory.id;
            let url: string = '';

            reportType = "inventorylistthales";

            url = `${AppConfig.reportingServer}Report.aspx/?report=${reportType}&inventoryId=${inventoryId}&reportType=${reportTypeCode}`;

            if (this.selectedLocations != null && (this.selectedLocations.length > 0)){
                let locationId: number = this.selectedLocations[0].id;
                url += `&locationId=${locationId}`;
            }
             window.open(url);
    }

    public reportTransferIn(reportTypeCode: string) {
        //if ((this.selectedInventory != null) && (this.selectedRegions != null) && (this.selectedRegions.length > 0)) {
            let reportType: string = "transferinv1";
            let inventoryId: number = this.selectedInventory.id;
            let url: string = '';

            url = `${AppConfig.reportingServer}Report.aspx/?report=${reportType}&inventoryId=${inventoryId}&reportType=${reportTypeCode}`

            if (this.selectedAdmCenters != null && (this.selectedAdmCenters.length > 0)){
                let admCenterId: number = this.selectedAdmCenters[0].id;
                url += `&admCenterId=${admCenterId}`;
            }

            if (this.selectedCostCenters != null && (this.selectedCostCenters.length > 0)){
                let costCenterId: number = this.selectedCostCenters[0].id;
                url += `&costCenterId=${costCenterId}`;
            }

            if (this.selectedRegions != null && (this.selectedRegions.length > 0)){
                let regionId: number = this.selectedRegions[0].id;
                url += `&regionId=${regionId}`;
            }

            if (this.selectedLocations != null && (this.selectedLocations.length > 0)){
                let locationId: number = this.selectedLocations[0].id;
                url += `&locationId=${locationId}`;
            }

            let custody: boolean = ((this.custody === '-') ? null : (this.custody === 'YES' ? true : false));
            if (custody != null)
            {
                url += `&custody=${custody}`;
            }

            window.open(url);
        // }
        // else {
        //     alert('Verificati data ati selectat cel putin un inventar si o regiune!');
        // }
    }

    public reportGeneral(reportTypeCode: string) {
        //if ((this.selectedInventory != null) && (this.selectedRegions != null) && (this.selectedRegions.length > 0)) {
            let reportType: string = "general";
            let inventoryId: number = this.selectedInventory.id;
            let accMonthId: number = this.selectedAccMonth.id;
            let url: string = '';

            url = `${AppConfig.reportingServer}Report.aspx/?report=${reportType}&accMonthId=${accMonthId}&inventoryId=${inventoryId}&reportType=${reportTypeCode}`

            if (this.selectedAdmCenters != null && (this.selectedAdmCenters.length > 0)){
                let admCenterId: number = this.selectedAdmCenters[0].id;
                url += `&admCenterId=${admCenterId}`;
            }

            if (this.selectedCostCenters != null && (this.selectedCostCenters.length > 0)){
                let costCenterId: number = this.selectedCostCenters[0].id;
                url += `&costCenterId=${costCenterId}`;
            }

            if (this.selectedRegions != null && (this.selectedRegions.length > 0)){
                let regionId: number = this.selectedRegions[0].id;
                url += `&regionId=${regionId}`;
            }

            if (this.selectedLocations != null && (this.selectedLocations.length > 0)){
                let locationId: number = this.selectedLocations[0].id;
                url += `&locationId=${locationId}`;
            }

            let custody: boolean = ((this.custody === '-') ? null : (this.custody === 'YES' ? true : false));
            if (custody != null)
            {
                url += `&custody=${custody}`;
            }

            window.open(url);
        // }
        // else {
        //     alert('Verificati data ati selectat cel putin un inventar si o regiune!');
        // }
    }

    public reportTransferOut() {
        //if ((this.selectedInventory != null) && (this.selectedRegions != null) && (this.selectedRegions.length > 0)) {
            let reportType: string = "transferoutv1";
            let inventoryId: number = this.selectedInventory.id;
            let url: string = '';

            url = `${AppConfig.reportingServer}Report.aspx/?report=${reportType}&inventoryId=${inventoryId}`;

            if (this.selectedAdmCenters != null && (this.selectedAdmCenters.length > 0)){
                let admCenterId: number = this.selectedAdmCenters[0].id;
                url += `&admCenterId=${admCenterId}`;
            }

            if (this.selectedCostCenters != null && (this.selectedCostCenters.length > 0)){
                let costCenterId: number = this.selectedCostCenters[0].id;
                url += `&costCenterId=${costCenterId}`;
            }

            if (this.selectedRegions != null && (this.selectedRegions.length > 0)){
                let regionId: number = this.selectedRegions[0].id;
                url += `&regionId=${regionId}`;
            }

            if (this.selectedLocations != null && (this.selectedLocations.length > 0)){
                let locationId: number = this.selectedLocations[0].id;
                url += `&locationId=${locationId}`;
            }

            let custody: boolean = ((this.custody === '-') ? null : (this.custody === 'YES' ? true : false));
            if (custody != null)
            {
                url += `&custody=${custody}`;
            }

            window.open(url);
        // }
        // else {
        //     alert('Verificati data ati selectat un inventar si o regiune!');
        // }
    }

    public reportInventoryListEmag() {


        let inventoryId: number = this.selectedInventory.id;
        let url: string = '';
            if (this.selectInventory.length > 0 || this.selectedCostCenters.length !== 1){
                return;
            }

            let reportType = 'INVENTORYLISTEMAG';

            url = `${AppConfig.reportingServer}Report.aspx/?report=${reportType}&inventoryId=${inventoryId}`;

        if (this.selectedAdmCenters != null && (this.selectedAdmCenters.length > 0)){
            let admCenterId: number = this.selectedAdmCenters[0].id;
            url += `&admCenterId=${admCenterId}`;
        }
        
        // if (this.selectedCompanies != null && (this.selectedCompanies.length > 0)){
        //     let companyId: number = this.selectedCompanies[0].id;
        //     url += `&companyId=${companyId}`;
        // }

        // if (this.selectedAdministrations != null && (this.selectedAdministrations.length > 0)){
        //     let administrationId: number = this.selectedAdministrations[0].id;
        //     url += `&administrationId=${administrationId}`;
        // }

        if (this.selectedCostCenters != null && (this.selectedCostCenters.length > 0)){
            let costCenterId: number = this.selectedCostCenters[0].id;
            url += `&costCenterId=${costCenterId}`;
        }

        if (this.selectedRegions != null && (this.selectedRegions.length > 0)){
            let regionId: number = this.selectedRegions[0].id;
            url += `&regionId=${regionId}`;
        }

        if (this.selectedLocations != null && (this.selectedLocations.length > 0)){
            let locationId: number = this.selectedLocations[0].id;
            url += `&locationId=${locationId}`;
        }

        if (this.selectedRooms != null && (this.selectedRooms.length > 0)){
            let roomId: number = this.selectedRooms[0].id;
            url += `&roomId=${roomId}`;
        }

        // if (this.selectedAccAccounts != null && (this.selectedAccAccounts.length > 0)){
        //     let accAccountId: number = this.selectedAccAccounts[0].id;
        //     url += `&accAccountId=${accAccountId}`;
        // }

        // if (this.reportFilter === 'Imobilizari in curs') {
        //     let param = 'A';
        //     url += `&reportFilter=${param}`;
        // } else if (this.reportFilter === 'Imobilizari corporale') {
        //     let param = 'B';
        //     url += `&reportFilter=${param}`;
        // } else if (this.reportFilter === 'Imobilizari necorporale') {
        //     let param = 'C';
        //     url += `&reportFilter=${param}`;
        // } else {
        //     let param = 'D';
        //     url += `&reportFilter=${param}`;
        // }
        
        window.open(url);

    return;
}

public reportInventoryListEmpPetrom() {


    let inventoryId: number = this.selectedInventory.id;
    let url: string = '';
        if (this.selectInventory.length > 0 || this.selectedCostCenters.length !== 1){
            return;
        }

        let reportType = 'INVENTORYLISTEMPEMAG';

        url = `${AppConfig.reportingServer}Report.aspx/?report=${reportType}&inventoryId=${inventoryId}`;

    if (this.selectedAdmCenters != null && (this.selectedAdmCenters.length > 0)){
        let admCenterId: number = this.selectedAdmCenters[0].id;
        url += `&admCenterId=${admCenterId}`;
    }
    
    // if (this.selectedCompanies != null && (this.selectedCompanies.length > 0)){
    //     let companyId: number = this.selectedCompanies[0].id;
    //     url += `&companyId=${companyId}`;
    // }

    // if (this.selectedAdministrations != null && (this.selectedAdministrations.length > 0)){
    //     let administrationId: number = this.selectedAdministrations[0].id;
    //     url += `&administrationId=${administrationId}`;
    // }

    if (this.selectedCostCenters != null && (this.selectedCostCenters.length > 0)){
        let costCenterId: number = this.selectedCostCenters[0].id;
        url += `&costCenterId=${costCenterId}`;
    }

    if (this.selectedRegions != null && (this.selectedRegions.length > 0)){
        let regionId: number = this.selectedRegions[0].id;
        url += `&regionId=${regionId}`;
    }

    if (this.selectedLocations != null && (this.selectedLocations.length > 0)){
        let locationId: number = this.selectedLocations[0].id;
        url += `&locationId=${locationId}`;
    }

    if (this.selectedRooms != null && (this.selectedRooms.length > 0)){
        let roomId: number = this.selectedRooms[0].id;
        url += `&roomId=${roomId}`;
    }

    // if (this.selectedAccAccounts != null && (this.selectedAccAccounts.length > 0)){
    //     let accAccountId: number = this.selectedAccAccounts[0].id;
    //     url += `&accAccountId=${accAccountId}`;
    // }
    window.open(url);

return;
}

public reportInventoryListEmpFinalPetrom() {


    let inventoryId: number = this.selectedInventory.id;
    let url: string = '';
        if (this.selectInventory.length > 0 || this.selectedCostCenters.length !== 1){
            return;
        }

        let reportType = 'INVENTORYLISTEMPFINALEMAG';

        url = `${AppConfig.reportingServer}Report.aspx/?report=${reportType}&inventoryId=${inventoryId}`;

    if (this.selectedAdmCenters != null && (this.selectedAdmCenters.length > 0)){
        let admCenterId: number = this.selectedAdmCenters[0].id;
        url += `&admCenterId=${admCenterId}`;
    }
    
    // if (this.selectedCompanies != null && (this.selectedCompanies.length > 0)){
    //     let companyId: number = this.selectedCompanies[0].id;
    //     url += `&companyId=${companyId}`;
    // }

    // if (this.selectedAdministrations != null && (this.selectedAdministrations.length > 0)){
    //     let administrationId: number = this.selectedAdministrations[0].id;
    //     url += `&administrationId=${administrationId}`;
    // }

    if (this.selectedCostCenters != null && (this.selectedCostCenters.length > 0)){
        let costCenterId: number = this.selectedCostCenters[0].id;
        url += `&costCenterId=${costCenterId}`;
    }

    if (this.selectedRegions != null && (this.selectedRegions.length > 0)){
        let regionId: number = this.selectedRegions[0].id;
        url += `&regionId=${regionId}`;
    }

    if (this.selectedLocations != null && (this.selectedLocations.length > 0)){
        let locationId: number = this.selectedLocations[0].id;
        url += `&locationId=${locationId}`;
    }

    if (this.selectedRooms != null && (this.selectedRooms.length > 0)){
        let roomId: number = this.selectedRooms[0].id;
        url += `&roomId=${roomId}`;
    }

    // if (this.selectedAccAccounts != null && (this.selectedAccAccounts.length > 0)){
    //     let accAccountId: number = this.selectedAccAccounts[0].id;
    //     url += `&accAccountId=${accAccountId}`;
    // }
    window.open(url);

return;
}

public reportInventoryListResultEmag() {


    let inventoryId: number = this.selectedInventory.id;
    let url: string = '';
        if (this.selectInventory.length > 0 || this.selectedCostCenters.length !== 1){
            return;
        }

        let reportType = 'INVENTORYRESULT';

        url = `${AppConfig.reportingServer}Report.aspx/?report=${reportType}&inventoryId=${inventoryId}`;

    if (this.selectedAdmCenters != null && (this.selectedAdmCenters.length > 0)){
        let admCenterId: number = this.selectedAdmCenters[0].id;
        url += `&admCenterId=${admCenterId}`;
    }
    
    // if (this.selectedCompanies != null && (this.selectedCompanies.length > 0)){
    //     let companyId: number = this.selectedCompanies[0].id;
    //     url += `&companyId=${companyId}`;
    // }

    // if (this.selectedAdministrations != null && (this.selectedAdministrations.length > 0)){
    //     let administrationId: number = this.selectedAdministrations[0].id;
    //     url += `&administrationId=${administrationId}`;
    // }

    if (this.selectedCostCenters != null && (this.selectedCostCenters.length > 0)){
        let costCenterId: number = this.selectedCostCenters[0].id;
        url += `&costCenterId=${costCenterId}`;
    }

    if (this.selectedRegions != null && (this.selectedRegions.length > 0)){
        let regionId: number = this.selectedRegions[0].id;
        url += `&regionId=${regionId}`;
    }

    if (this.selectedLocations != null && (this.selectedLocations.length > 0)){
        let locationId: number = this.selectedLocations[0].id;
        url += `&locationId=${locationId}`;
    }

    if (this.selectedRooms != null && (this.selectedRooms.length > 0)){
        let roomId: number = this.selectedRooms[0].id;
        url += `&roomId=${roomId}`;
    }

    // if (this.selectedAccAccounts != null && (this.selectedAccAccounts.length > 0)){
    //     let accAccountId: number = this.selectedAccAccounts[0].id;
    //     url += `&accAccountId=${accAccountId}`;
    // }

    window.open(url);

return;
}

public inventoryLabel() {
    let reportType: string = 'inventorylabel';
    let inventoryId: number = this.selectedInventory.id;
    let url: string = '';

    url = `${AppConfig.reportingServer}Report.aspx/?report=${reportType}&inventoryId=${inventoryId}`;

    if (this.selectedCostCenters != null && (this.selectedCostCenters.length > 0)){
        let costCenterId: number = this.selectedCostCenters[0].id;
        url += `&costCenterId=${costCenterId}`;
    }

    // if (this.selectedAssetTypes != null && (this.selectedAssetTypes.length > 0)){
    //     let roomId: number = this.selectedAssetTypes[0].id;
    //     url += `&assetTypeId=${roomId}`;
    // }

    window.open(url);
// }
// else {
//     alert('Verificati data ati selectat cel putin un inventar si o regiune!');
// }
}

}

enum OperationType {
    NotSet = 1,
    Reconciliation = 2
}