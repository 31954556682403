import { Component } from '@angular/core';
import { AppData } from '../../../app-data';
import { RetireAssetSAP } from '../../../model/api/sap/retire-asset-sap';
import { Param } from '../../../model/common/param';
import { GenericTableList } from '../../generic/generic.table.list';
@Component({
    selector: 'app-retire-asset-sap-list',
    templateUrl: '../../generic/generic.table.list.html'
})
export class RetireAssetSAPListComponent extends GenericTableList<RetireAssetSAP, number> {
    private listName: string = 'RETIREASSETSAPS';

    constructor() {
        super('id', 'desc', '');
        this.columns = AppData.ColumnDefinitions[this.listName];
    }

    public refresh(filters: Array<Param>) {
        this.columns = AppData.ColumnDefinitions[this.listName];
        super.refresh(filters);
    }
}
