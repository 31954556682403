import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericHttpService } from "../generic.http.service";
import { Stock } from "../../../model/api/administration/stock";

@Injectable()
export class StockHttpService extends GenericHttpService<Stock, number> {
    constructor(public http: HttpClient) {
        super(http, "", "stocks");
    }
}