import { AccMonthHttpService } from './../../services/http/accounting/acc-month.http.service';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage } from '../generic/generic.manage';
import { AccMonth } from '../../model/api/accounting/acc-month';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-acc-month-manage',
    templateUrl: 'acc-month.manage.html',
    providers: [ AccMonthHttpService ]
})
export class AccMonthManageComponent extends GenericManage<AccMonth, number> {

    @ViewChild('itemDetailModal') modal: ModalDirective;

    public filter: string = '';
    isCollapsed: boolean = true;

    constructor(public accMonthHttpService: AccMonthHttpService) {
        super();
    }

    public detailInitialize() {
        this.modal.show();
    }

    public detailTerminate() {
        this.modal.hide();
    }

    public saveAccMonth(){
    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }

      export() {
          
      }
}
